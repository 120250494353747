import { FC, useCallback, useMemo } from "react";
import { ButtonProps, Drawer, DrawerProps, Create, FormProps, Grid, Form, Input } from "@pankod/refine-antd";
import { useCreateMany, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { IBranch, IContainer, IDelivery, IOrder, IPackage } from "interfaces";
import { calcInvoicePrice } from "documents/utils";
import { useUploadFile } from "hooks";
import { prepareOrdersForInvoice } from "helpers/commercialInvoice";

type FormKey = string | "mrn_nr";

type CreateInvoiceForm = {
  [key in FormKey]: { invoice_nr: string };
};

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  close: () => void;
  invoiceData: {
    isAdmin: boolean;
    tenantId: number;
    delivery: IDelivery;
    invoiceNr?: string;
    mrn?: string;
  };
}

export const CreateInvoice: FC<Props> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  close,
  invoiceData: { isAdmin, tenantId, delivery, invoiceNr },
}: Props) => {
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();
  const { upload } = useUploadFile();
  const { mutate: createInvoices, isLoading: isInvoiceCreationInProgress } = useCreateMany({});
  const { mutate: updateDelivery, isLoading: isDeliveryUpdateInProgress } = useUpdate();

  const { data: branchesQueryResult } = useList<IBranch>({
    resource: "branches",
    queryOptions: {
      enabled: !!isAdmin && !!tenantId,
    },
    config: {
      filters: [{ field: "tenant_id", operator: "eq", value: tenantId }],
      hasPagination: false,
    },
  });

  const packages = useMemo(
    () => (delivery.containers ?? []).flatMap((container: IContainer) => container.packages) || [],
    [delivery.containers]
  );

  const orders = useMemo(() => {
    if (packages) {
      return packages.flatMap((pkg: IPackage | undefined) => pkg?.orders!);
    }
    return [];
  }, [packages]);

  const uniqueBranches: (IBranch | undefined)[] = useMemo(() => {
    const uniqueOrders = Array.from(
      new Map((orders || []).map((order: IOrder) => [order?.branch_id, order])).values()
    );

    return (
      uniqueOrders.map((curr: any) =>
        branchesQueryResult?.data.find(branch => branch.id === curr.branch_id)
      ) ?? []
    );
  }, [branchesQueryResult?.data, orders]);

  const dataToGenerateInvoices = useMemo(() => prepareOrdersForInvoice(orders), [orders]);

  const createInvoice = useCallback(
    (values: CreateInvoiceForm) => {
      createInvoices(
        {
          resource: "invoices",
          values: Object.entries(dataToGenerateInvoices).map(([branch_id, orders]: any) => {
            const branch = branchesQueryResult?.data.find(branch => branch.id === branch_id);
            const amount = calcInvoicePrice(orders, branch!, delivery.containers!);

            return {
              invoice_nr: values[branch_id].invoice_nr,
              delivery_id: delivery.id,
              branch_id: branch_id,
              amount,
            };
          }),
        },
        {
          onSuccess: data => {
            close();
            formProps.form?.resetFields();

            // data.data.forEach(async invoice => {
            //   const { invoice_nr, branch_id } = invoice;
            //   const branch = branchesQueryResult?.data.find(branch => branch.id === branch_id);

            //   const blob = await pdf(
            //     <CommercialInvoice
            //       delivery={delivery}
            //       invoiceNr={invoice_nr}
            //       orders={dataToGenerateInvoices[branch_id]}
            //       branch={branch!}
            //       mrn_nr={mrn_nr}
            //     />
            //   ).toBlob();

            //   close();
            //   formProps.form?.resetFields();

            //   await upload(invoicePath(tenantId, branch_id, invoice_nr, new Date()), blob, true);
            // });
          },
        }
      );
    },
    [
      branchesQueryResult?.data,
      close,
      createInvoices,
      dataToGenerateInvoices,
      delivery.containers,
      delivery.id,
      formProps.form,
    ]
  );

  const handleOnFinish = useCallback(
    (values: CreateInvoiceForm) => {
      if (values?.mrn_nr) {
        updateDelivery(
          { resource: "deliveries", id: delivery.id, values: { mrn_nr: values.mrn_nr } },
          {
            onSuccess: data => {
              // To be sure that mrn_nr exist.
              createInvoice(values);
            },
          }
        );
      } else {
        createInvoice(values);
      }
    },
    [createInvoice, delivery.id, updateDelivery]
  );

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t("deliveries.action.generateInvoice")}
      destroyOnClose
      forceRender
    >
      <Create
        resource="invoices"
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        saveButtonProps={saveButtonProps}
        title={false}
        isLoading={isDeliveryUpdateInProgress || isInvoiceCreationInProgress}
      >
        <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
          {(uniqueBranches ?? []).map(
            (branch: IBranch | undefined) =>
              branch?.id && (
                <div key={branch?.id}>
                  <Form.Item
                    label={t("invoices.fields.invoiceNumberFor", { value: branch?.name })}
                    name={[branch?.id, "invoice_nr"]}
                    rules={[
                      {
                        required: true,
                        message: t("invoices.required"),
                      },
                      {
                        pattern: /^[\w,\s-]+$/g,
                        message: t("invoices.wrongFormat"),
                      },
                    ]}
                    initialValue={invoiceNr}
                  >
                    <Input
                      placeholder={t("invoices.fields.invoiceNumber")}
                      type="text"
                      disabled={!!invoiceNr}
                    />
                  </Form.Item>
                </div>
              )
          )}

          <Form.Item label={t("deliveries.fields.mrn.label")} name="mrn_nr" initialValue={delivery.mrn_nr}>
            <Input placeholder={t("deliveries.fields.mrn.placeholder")} type="text" />
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
