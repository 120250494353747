import { IOrder } from "interfaces";

export const prepareOrdersForInvoice = (orders: IOrder[]) =>
  orders.reduce((total: { [key: string]: IOrder[] }, current: any) => {
    if (current) {
      const { branch_id } = current;

      if (total[branch_id]) {
        return { ...total, [branch_id]: [...total[branch_id], current] };
      } else {
        return { ...total, [branch_id]: [current] };
      }
    }

    return total;
  }, {});
