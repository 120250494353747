import { FC, useState, useMemo, useCallback } from "react";
import { CSVLink } from "react-csv";
import {
  IResourceComponentsProps,
  useList,
  useModal,
  useNotification,
  useShow,
  useTranslate,
  useUpdate,
  useUpdateMany,
} from "@pankod/refine-core";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Grid,
  MenuProps,
  Modal,
  PageHeader,
  Row,
  Show,
  Skeleton,
  Space,
  StepProps,
  Steps,
  Typography,
  useDrawerForm,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import {
  IDelivery,
  IDeliveryStatus,
  IDeliveryStatusHistory,
  IInvoice,
  IPackage,
  IContainer,
} from "interfaces";
import { ContainerList } from "components/containers";
import { DocumentIcon, SuccessIcon } from "assets";
import { DeliveryStatus, OrderStatus, PdfType } from "../../constants";
import { Colors } from "theme";
import { useCurrentUser } from "hooks";
import { CommercialInvoice, DeliveryPdfLayout } from "documents/delivery";
import { CreateInvoice } from "components/invoices";
import { prepareInvoiceDataForExcel } from "helpers";
import { CommercialInvoiceProps } from "documents/delivery/commercialInvoice";

dayjs.extend(relativeTime);
const { useBreakpoint } = Grid;

export const DeliveryShow: FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { mutate: updateDeliveryStatus, isLoading: isDeliveryUpdateInProgress } = useUpdate();
  const { mutate: updateOrderStatuses, isLoading: isOrdersUpdateInProgress } = useUpdateMany();
  const { isAdmin, tenantId } = useCurrentUser();
  const { open } = useNotification();

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
    close: createClose,
  } = useDrawerForm<IInvoice>({
    action: "create",
    resource: "invoices",
    redirect: false,
  });

  const [pdfTypeForPrint, setPdfTypeForPrint] = useState<PdfType>();
  const { show: showPdfModal, visible: visiblePdfModal, close: closePdfModal } = useModal();

  const [commercialInvoiceData, setCommercialInvoiceData] = useState<CommercialInvoiceProps>();
  const {
    show: showCommercialInvoiceModal,
    visible: visibleCommercialInvoiceModal,
    close: closeCommercialInvoiceModal,
  } = useModal();

  const {
    queryResult: { data, refetch, isFetching: isDeliveryFetching },
  } = useShow<IDelivery>({
    metaData: {
      select:
        "*, delivery_status_history!inner(new_delivery_status_id, created_at), tenants!inner(*), containers(*, packages(*, branches!inner(*), clients!inner(*), orders(*, shops!inner(id, branch_shops(*))))), postal_operators!inner(*)",
    },
  });

  const { data: deliveryStatusQueryResult } = useList<IDeliveryStatus>({
    resource: "delivery_statuses",
    config: {
      sort: [{ field: "id", order: "asc" }],
      hasPagination: false,
    },
  });

  /** Current delivery to overview. */
  const delivery = data?.data;

  const { data: invoicesQueryResult } = useList<IInvoice>({
    resource: "invoices",
    queryOptions: {
      enabled: !!delivery?.id,
    },
    config: {
      filters: [
        { field: "delivery_id", operator: "eq", value: delivery?.id },
        { field: "cancelled", operator: "eq", value: false },
      ],
      hasPagination: false,
    },
    metaData: {
      select: "*, branches(*)",
    },
  });

  /** All available statuses to display in Steps. */
  const deliveryStatuses = deliveryStatusQueryResult?.data;
  /** Invoices (if available) created for this delivery */
  const invoices = invoicesQueryResult?.data;

  const packages = delivery?.containers?.flatMap(container => container.packages);
  const { csvData, headers } = prepareInvoiceDataForExcel(delivery, packages);

  const screens = useBreakpoint();
  const currentBreakPoints = Object.entries(screens)
    .filter(screen => !!screen[1])
    .map(screen => screen[0]);

  const hasAdditionalDocuments = useMemo(() => {
    if (delivery?.postal_operators) {
      const { has_cn38, has_cp87, has_cargo_manifest } = delivery.postal_operators;

      return has_cn38 || has_cp87 || has_cargo_manifest;
    }

    return false;
  }, [delivery]);

  const refetchDelivery = useCallback(() => refetch(), [refetch]);

  if (!delivery || !deliveryStatuses) {
    return <Skeleton active paragraph={{ width: "100%" }} />;
  }

  const defineActionTitle = () => {
    if (delivery?.delivery_status_id === DeliveryStatus.Packaging) {
      return t("deliveries.action.readyForShipping");
    }
    if (delivery?.delivery_status_id === DeliveryStatus.ReadyForShipping) {
      return t("deliveries.action.send");
    }
    if (delivery?.delivery_status_id === DeliveryStatus.Shipped) {
      return t("deliveries.action.confirmDelivery");
    }
  };

  const canUpdateDeliveryStatus = () => {
    const { containers } = delivery;

    return (
      !!containers?.length &&
      containers.every(
        container => !!container.packages?.length && container.packages.every(pkg => !!pkg.orders?.length)
      )
    );
  };

  const handleDeliveryStatusChange = () => {
    if (canUpdateDeliveryStatus()) {
      updateDeliveryStatus(
        {
          resource: "deliveries",
          values: {
            delivery_status_id: delivery.delivery_status_id + 1,
          },
          id: delivery.id,
          successNotification: false,
        },
        {
          onSuccess: data => {
            const orders = mapOrdersFromDelivery();

            updateOrderStatuses({
              resource: "orders",
              ids: orders.map(order => order!.id),
              values: {
                order_status_id:
                  data.data.delivery_status_id === 2
                    ? OrderStatus.BeingPacked
                    : data.data.delivery_status_id === 3
                    ? OrderStatus.Shipped
                    : OrderStatus.Delivered,
              },
              successNotification: false,
            });
          },
        }
      );
    } else {
      open?.({
        type: "error",
        message: t("deliveries.notification.message"),
        description: t("deliveries.notification.description"),
      });
    }
  };

  const defineStepStatus = (deliveryStatus: IDeliveryStatus, currentStatusId: number) => {
    if (deliveryStatus.id <= currentStatusId) {
      return "finish";
    }
    if (deliveryStatus.id > currentStatusId) {
      return "wait";
    }

    return "error";
  };

  const defineStepIcon = (status: "finish" | "process" | "wait" | "error") => {
    if (status === "finish") {
      return <SuccessIcon />;
    }

    return undefined;
  };

  const getStepDescription = (currentStatusId: number, history: IDeliveryStatusHistory[]) => {
    const currentHistory = history.find(history => history.new_delivery_status_id === currentStatusId);

    if (currentHistory) {
      return dayjs(currentHistory.created_at).format("L LT");
    }

    return undefined;
  };

  const createStepItems = (): StepProps[] => {
    if (deliveryStatuses) {
      return deliveryStatuses!.reduce((total: StepProps[], current) => {
        const status = defineStepStatus(current, delivery.delivery_status_id);
        const description = getStepDescription(current.id, delivery.delivery_status_history);

        return [
          ...total,
          {
            title: <span style={{ fontSize: "20px" }}>{t(`deliveries.statuses.${current.value}`)}</span>,
            status,
            icon: defineStepIcon(status),
            description,
          },
        ];
      }, []);
    }

    return [];
  };

  const renderDeliverySteps = () => (
    <PageHeader
      className="pageHeader"
      ghost={false}
      onBack={() => window.history.back()}
      style={{ marginBottom: 20 }}
    >
      <Steps
        direction={currentBreakPoints.includes("lg") ? "horizontal" : "vertical"}
        items={createStepItems()}
      />
    </PageHeader>
  );

  const canGenerateInvoice = () =>
    delivery?.delivery_status_id === DeliveryStatus.ReadyForShipping && !invoices?.length;

  const mapOrdersFromDelivery = () => {
    if (delivery.containers) {
      const packages = delivery.containers.flatMap(container => container.packages);
      return packages.flatMap(pkg => pkg?.orders!);
    }
    return [];
  };

  const hasAccessToDelveryStatusUpdate =
    delivery?.delivery_status_id !== DeliveryStatus.Delivered &&
    (isAdmin || delivery?.delivery_status_id === DeliveryStatus.Shipped);

  const items: MenuProps["items"] = invoices?.map(invoice => {
    const { id, invoice_nr, branch_id, branches } = invoice;

    return {
      key: id,
      label: `${branches.name} #${invoice_nr}`,
      onClick: () => {
        const { invoice_nr, branches } = invoice;
        const packages =
          (delivery.containers ?? []).flatMap((container: IContainer) => container.packages) || [];
        const orders = packages.flatMap((pkg: IPackage | undefined) => pkg?.orders!);

        const invoiceData: CommercialInvoiceProps = {
          delivery: delivery!,
          invoiceNr: invoice_nr,
          orders,
          branch: branches,
        };

        setCommercialInvoiceData(invoiceData);
        showCommercialInvoiceModal();
      },
    };
  });

  return (
    <Show
      breadcrumb={false}
      title={false}
      goBack={null}
      headerProps={{ extra: null }}
      isLoading={isDeliveryFetching || isDeliveryUpdateInProgress || isOrdersUpdateInProgress}
      contentProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      {renderDeliverySteps()}
      <Row gutter={[16, 16]}>
        <Col flex={1} xl={7} lg={24} xs={24}>
          <Card style={styles.card}>
            <span style={styles.cardTitle}>{t("deliveries.info")}</span>

            <Space direction="vertical" style={styles.cardContent}>
              <Typography.Text>{`${t("deliveries.fields.deliveryID")}: ${delivery?.id}`}</Typography.Text>
              <Typography.Text>{`${t("deliveries.fields.name")}: ${delivery?.delivery_nr}`}</Typography.Text>
              <Typography.Text>{`${t("deliveries.fields.createdAt")}: ${dayjs(delivery?.created_at).format(
                "L LT"
              )}`}</Typography.Text>
              {delivery?.shipment_date && (
                <Typography.Text>{`${t("deliveries.fields.shipmentDate.label")}: ${dayjs(
                  delivery?.shipment_date
                ).format("L LT")}`}</Typography.Text>
              )}
            </Space>

            <Space direction="vertical" style={styles.cardContent}>
              <Typography.Text>{`${t("deliveries.fields.postalOperator.label")}: ${
                delivery?.postal_operators?.name
              }`}</Typography.Text>
              {delivery?.airdepartment_nr && (
                <Typography.Text>{`${t("deliveries.fields.airdepartmentNr.label")}: ${
                  delivery?.airdepartment_nr
                }`}</Typography.Text>
              )}
              {delivery?.departure_nr && (
                <Typography.Text>{`${t("deliveries.fields.departureNr.label")}: ${
                  delivery?.departure_nr
                }`}</Typography.Text>
              )}
              {delivery?.flight_nr && (
                <Typography.Text>{`${t("deliveries.fields.flightNr.label")}: ${
                  delivery?.flight_nr
                }`}</Typography.Text>
              )}
            </Space>

            {isAdmin && hasAdditionalDocuments && (
              <Space direction="horizontal" wrap style={styles.cardContent}>
                {delivery.postal_operators?.has_cn38 && (
                  <Button
                    icon={<DocumentIcon style={{ marginRight: 4 }} />}
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={event => {
                      event.stopPropagation();
                      setPdfTypeForPrint(PdfType.CN38);
                      showPdfModal();
                    }}
                  >
                    CN38
                  </Button>
                )}
                {delivery.postal_operators?.has_cp87 && (
                  <Button
                    icon={<DocumentIcon style={{ marginRight: 4 }} />}
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={event => {
                      event.stopPropagation();
                      setPdfTypeForPrint(PdfType.CP87);
                      showPdfModal();
                    }}
                  >
                    CP87
                  </Button>
                )}
                {delivery.postal_operators?.has_cargo_manifest && (
                  <Button
                    icon={<DocumentIcon style={{ marginRight: 4 }} />}
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={event => {
                      event.stopPropagation();
                      setPdfTypeForPrint(PdfType.CargoManifest);
                      showPdfModal();
                    }}
                  >
                    Cargo Manifest
                  </Button>
                )}
                {delivery.tenants?.has_ru_invoice && (
                  <Button
                    icon={<DocumentIcon style={{ marginRight: 4 }} />}
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={event => {
                      event.stopPropagation();
                      setPdfTypeForPrint(PdfType.RussianInvoice);
                      showPdfModal();
                    }}
                  >
                    {t("deliveries.invoiceRu")}
                  </Button>
                )}
                {delivery.tenants?.has_ru_invoice && (
                  <Button icon={<DocumentIcon style={{ marginRight: 4 }} />} className="csvButton">
                    <CSVLink
                      separator=";"
                      data={csvData}
                      headers={headers}
                      aria-rowspan={2}
                      filename={`INVOICE № ${delivery?.delivery_nr} (СЧЁТ-ПРОФОРМА) ИНВОЙС - ${delivery?.tenants?.name}`}
                    >
                      {`${t("deliveries.invoiceRu")} (exc.)`}
                    </CSVLink>
                  </Button>
                )}
                {delivery.tenants?.has_en_invoice && (
                  <Button
                    icon={<DocumentIcon style={{ marginRight: 4 }} />}
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={event => {
                      event.stopPropagation();
                      setPdfTypeForPrint(PdfType.EnglishInvoice);
                      showPdfModal();
                    }}
                  >
                    {t("deliveries.invoiceEn")}
                  </Button>
                )}
                {delivery.tenants?.has_packing_list && (
                  <Button
                    icon={<DocumentIcon style={{ marginRight: 4 }} />}
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={event => {
                      event.stopPropagation();
                      setPdfTypeForPrint(PdfType.PackingList);
                      showPdfModal();
                    }}
                  >
                    Packing List
                  </Button>
                )}
              </Space>
            )}

            {hasAccessToDelveryStatusUpdate && (
              <Button block type="primary" onClick={handleDeliveryStatusChange}>
                {defineActionTitle()}
              </Button>
            )}

            {isAdmin && canGenerateInvoice() && (
              <Button block type="primary" style={styles.invoiceButton} onClick={() => createShow()}>
                {t("deliveries.action.generateInvoice")}
              </Button>
            )}

            {isAdmin && !!invoices?.length && (
              <Dropdown menu={{ items }}>
                <Button block type="primary" style={styles.invoiceButton}>
                  {t("deliveries.action.openInvoice")}
                </Button>
              </Dropdown>
            )}
          </Card>
        </Col>
        <Col flex={1} xl={17} xs={24}>
          <ContainerList
            delivery={delivery!}
            refetchDelivery={refetchDelivery}
            containers={delivery.containers}
          />
        </Col>
      </Row>
      {visiblePdfModal && (
        <Modal open={visiblePdfModal} onCancel={closePdfModal} width="80%" footer={null}>
          <DeliveryPdfLayout pdfType={pdfTypeForPrint} delivery={delivery!} invoices={invoices} />
        </Modal>
      )}

      {visibleCommercialInvoiceModal && !!commercialInvoiceData && (
        <Modal
          open={visibleCommercialInvoiceModal}
          onCancel={closeCommercialInvoiceModal}
          width="80%"
          footer={null}
        >
          <CommercialInvoice {...commercialInvoiceData} />
        </Modal>
      )}
      <CreateInvoice
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        close={createClose}
        invoiceData={{ isAdmin, tenantId, delivery }}
      />
    </Show>
  );
};

const styles = {
  card: { minHeight: "70vh" },
  cardTitle: { fontSize: "20px", fontWeight: "bold" },
  cardContent: {
    width: "100%",
    border: `1px solid ${Colors.Emerald}`,
    borderRadius: "10px",
    backgroundColor: Colors.MintDefault,
    padding: "20px",
    marginBottom: "20px",
    marginTop: "20px",
  },
  invoiceButton: {
    marginTop: "20px",
  },
};
