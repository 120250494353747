import { ResourceProps } from "@pankod/refine-core";

import { BranchList, BranchShow } from "pages/branches";
import { BranchShopList } from "pages/branchShops";
import { ClientsList } from "pages/clients";
import { DeliveryList, DeliveryShow } from "pages/deliveries";
import { TransactionsList } from "pages/transactions";
import { InvoiceList } from "pages/invoices";
import { OrderList, OrderShow } from "pages/orders";
import { ShopList } from "pages/shops";
import { ArrivalList } from "pages/arrival";
import { Profile } from "pages/profile";
import { StockList } from "pages/stock";
import {
  BranchesIcon,
  DeliveriesIcon,
  OrdersIcon,
  ShopsIcon,
  BalanceIcon,
  ClientsIcon,
  InvoiceIcon,
  ArrivalIcon,
  StockIcon,
  UserIcon,
} from "assets";

const resources: ResourceProps[] = [
  {
    name: "orders",
    list: OrderList,
    show: OrderShow,
    canDelete: false,
    icon: <OrdersIcon />,
    options: {
      label: "orders.orders",
    },
  },
  {
    name: "arrival",
    list: OrderList,
    create: ArrivalList,
    canDelete: false,
    icon: <ArrivalIcon />,
    options: {
      label: "arrival.arrival",
    },
  },
  {
    name: "stock",
    list: StockList,
    canDelete: false,
    icon: <StockIcon />,
    options: {
      label: "stock.stock",
    },
  },
  {
    name: "deliveries",
    list: DeliveryList,
    show: DeliveryShow,
    icon: <DeliveriesIcon />,
    options: {
      label: "deliveries.deliveries",
    },
  },
  {
    name: "shops",
    list: ShopList,
    icon: <ShopsIcon />,
    options: {
      label: "shops.shops",
    },
  },
  {
    name: "branch_shops",
    list: BranchShopList,
    icon: <ShopsIcon />,
    options: {
      label: "shops.shops",
    },
  },
  {
    name: "branches",
    list: BranchList,
    show: BranchShow,
    icon: <BranchesIcon />,
    options: {
      label: "branches.branches",
    },
  },
  {
    name: "clients",
    list: ClientsList,
    icon: <ClientsIcon />,
    options: {
      label: "clients.clients",
    },
  },
  {
    name: "financial_transactions",
    list: TransactionsList,
    icon: <BalanceIcon />,
    options: {
      label: "branches.balance",
    },
  },
  {
    name: "invoices",
    list: InvoiceList,
    icon: <InvoiceIcon />,
    options: {
      label: "invoices.invoices",
    },
  },

  {
    name: "profile",
    list: Profile,
    canDelete: false,
    icon: <UserIcon />,
    options: {
      label: "profile.title",
    },
  },
];

export default resources;
