import { FC } from "react";
import { PDFDownloadLink, PDFViewer, pdf } from "@react-pdf/renderer";

import { CN38 } from "./cn38";
import { CP87 } from "./cp87";
import { CargoManifest } from "./cargoManifest";
import { PdfType } from "../../constants";
import { IDelivery, IInvoice } from "interfaces";
import { RussianInvoice } from "./russianInvoice";
import { EnglishInvoice } from "./englishInvoice";
import { PackingList } from "./packingList";
import { Button } from "@pankod/refine-antd";
import { DocumentIcon } from "assets";
import { useTranslate } from "@pankod/refine-core";

type Props = {
  pdfType?: PdfType;
  delivery: IDelivery;
  invoices?: IInvoice[];
};

export const DeliveryPdfLayout: FC<Props> = props => {
  const { pdfType, delivery, invoices } = props;
  const t = useTranslate();

  let doc: any;

  switch (pdfType) {
    case PdfType.CN38:
      doc = <CN38 delivery={delivery!} />;
      break;
    case PdfType.CP87:
      doc = <CP87 delivery={delivery!} />;
      break;
    case PdfType.CargoManifest:
      doc = <CargoManifest delivery={delivery!} invoices={invoices} />;
      break;
    case PdfType.RussianInvoice:
      doc = <RussianInvoice delivery={delivery!} />;
      break;
    case PdfType.EnglishInvoice:
      doc = <EnglishInvoice delivery={delivery!} />;
      break;
    case PdfType.PackingList:
      doc = <PackingList delivery={delivery!} />;
      break;
    default:
      break;
  }

  return (
    <div>
      <PDFViewer
        style={{
          paddingTop: 32,
          width: "100%",
          height: "80vh",
          border: "none",
        }}
      >
        {doc}
      </PDFViewer>

      <div
        style={{
          width: "100px",
        }}
      >
        <PDFDownloadLink document={doc!} fileName={`${PdfType[pdfType!]}_#${delivery.id}.pdf`}>
          <Button
            icon={<DocumentIcon style={{ marginRight: 4 }} />}
            style={{ display: "flex", alignItems: "center" }}
          >
            {t("buttons.save")}
          </Button>
        </PDFDownloadLink>
      </div>
    </div>
  );
};
