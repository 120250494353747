import { FC, useEffect, useState } from "react";
import { useDelete, useTranslate } from "@pankod/refine-core";
import {
  Avatar,
  BooleanField,
  Button,
  DateField,
  EditButton,
  Icons,
  Popconfirm,
  ShowButton,
  Table,
  TableProps,
  TextField,
} from "@pankod/refine-antd";

import { IBranch } from "interfaces";
import { DeleteIcon, ShowIcon } from "assets";
import { useAvatars, useCurrentUser } from "hooks";
import { AppEmpty } from "components/appEmpty";

interface Props {
  tableProps: TableProps<IBranch>;
}

export const BranchesTable: FC<Props> = props => {
  const { tableProps } = props;
  const t = useTranslate();

  const [branchIds, setBranchIds] = useState<string[]>([]);
  const { avatars } = useAvatars(branchIds);
  const { userId } = useCurrentUser();
  const { mutate: deleteBranch } = useDelete();

  const handleDeleteBranch = (id: string) => {
    deleteBranch({ resource: "branches", id });
  };

  useEffect(() => {
    if (tableProps?.dataSource) {
      setBranchIds(tableProps.dataSource?.map(b => b.id) || []);
    }
  }, [tableProps.dataSource]);

  return (
    <Table
      {...tableProps}
      rowKey="id"
      size="small"
      rowClassName="app-table-row"
      locale={{
        emptyText: (
          <AppEmpty
            description={
              <span style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>{t("table.empty")}</span>
            }
          />
        ),
      }}
    >
      <Table.Column
        key="avatar"
        dataIndex="id"
        title={t("branches.fields.avatar")}
        render={value => <Avatar size={30} src={avatars[value]} icon={<Icons.UserOutlined />} />}
        sorter
      />
      <Table.Column
        key="name"
        dataIndex="name"
        title={t("branches.fields.name")}
        render={value => <TextField value={value} />}
        sorter
      />
      <Table.Column
        key="email"
        dataIndex="email"
        title={t("branches.fields.email")}
        render={value => <TextField value={value} />}
        sorter
      />
      <Table.Column
        key="address"
        dataIndex="address"
        title={t("branches.fields.address")}
        render={value => <TextField value={value} />}
        sorter
      />
      <Table.Column
        key="city"
        dataIndex="city"
        title={t("branches.fields.city")}
        render={value => <TextField value={value} />}
      />
      <Table.Column
        key="zipcode"
        dataIndex="zipcode"
        title={t("branches.fields.zipcode")}
        render={value => <TextField value={value} />}
      />
      <Table.Column
        key="balance"
        dataIndex="balance"
        title={t("branches.fields.balance")}
        render={value => <TextField value={value} />}
      />
      {/* // TODO: TBD in US #1139 */}
      {/* <Table.Column
        key="is_active"
        dataIndex="is_active"
        title={t("shops.fields.isActive")}
        render={value => <BooleanField value={value} />}
      /> */}
      <Table.Column
        key="created_at"
        dataIndex="created_at"
        title={t("orders.fields.createdAt")}
        render={value => <DateField value={value} format="DD.MM.YYYY HH:mm" />}
        sorter
      />
      <Table.Column<IBranch>
        fixed="right"
        title={t("table.actions")}
        dataIndex="actions"
        key="actions"
        align="center"
        render={(_, record) => (
          <>
            <ShowButton
              hideText
              recordItemId={record.id}
              style={{ borderColor: "transparent", background: "transparent" }}
              icon={<ShowIcon />}
            />
            {/* // TODO: Will be enable with US #1145. */}
            {/* <Popconfirm
              key="delete"
              okText={t("buttons.delete")}
              cancelText={t("buttons.cancel")}
              okType="danger"
              title={t("buttons.confirm")}
              disabled={record.id === userId}
              onConfirm={() => handleDeleteBranch(record.id)}
            >
              <Button
                icon={<DeleteIcon />}
                style={{ borderColor: "transparent", background: "transparent" }}
                disabled={record.id === userId}
              />
            </Popconfirm> */}
          </>
        )}
      />
    </Table>
  );
};
