import { useEffect, useMemo, useState } from "react";
import {
  Form,
  Input,
  useSelect,
  Select,
  DatePicker,
  FormProps,
  Icons,
  Typography,
} from "@pankod/refine-antd";
import dayjs from "dayjs";

import { IDeliveryStatus, IPostalOperator } from "interfaces";
import { DeliveryStatus } from "../../constants";

export const CreateEditForm = (
  formProps: FormProps,
  t: {
    (key: string, options?: any, defaultMessage?: string | undefined): string;
    (key: string, defaultMessage?: string | undefined): string;
  },
  isEdit: boolean = false,
  isOpen: boolean
) => {
  const [isPostalOperatorSelected, setIsPostalOperatorSelected] = useState<boolean>(false);
  const [shouldShowWarning, setShouldShowWarning] = useState<boolean>(false);

  const isEditingWithPostalOperator = useMemo(
    () => formProps.initialValues?.postal_operator_id,
    [formProps.initialValues]
  );

  const { selectProps: deliveryStatusesSelectProps } = useSelect<IDeliveryStatus>({
    resource: "delivery_statuses",
    optionLabel: "value",
    optionValue: "id",
  });

  const { selectProps: postalOperatorSelectProps } = useSelect<IPostalOperator>({
    resource: "postal_operators",
    optionLabel: "name",
    optionValue: "id",
  });

  const handleOnFieldsChange = (changedFields: any) => {
    changedFields.forEach((field: any) => {
      if (field.name.includes("postal_operator_id")) {
        setIsPostalOperatorSelected(!!field.value);
      }
      if (field.name.includes("delivery_status_id") && isEdit) {
        const isStatusChanged = field.value !== formProps.initialValues!.delivery_status_id;
        setShouldShowWarning(isStatusChanged);
      }
    });
  };

  const handleSubmit = (values: any) => {
    formProps.onFinish!(values);
  };

  useEffect(() => {
    if (isEditingWithPostalOperator) {
      setIsPostalOperatorSelected(true);
    }
  }, [isEditingWithPostalOperator]);

  useEffect(() => {
    if (!isOpen) {
      setShouldShowWarning(false);
    }
  }, [isOpen]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={{
        isActive: true,
        ...formProps.initialValues,
        delivery_status_id: isEdit ? formProps.initialValues?.delivery_status_id : DeliveryStatus.Packaging,
        shipment_date: formProps.initialValues?.shipment_date
          ? dayjs(formProps.initialValues.shipment_date)
          : undefined,
      }}
      onFieldsChange={changedFields => handleOnFieldsChange(changedFields)}
      onFinish={handleSubmit}
    >
      <Form.Item
        label={t("deliveries.fields.postalOperator.label")}
        name="postal_operator_id"
        rules={[{ required: true }]}
      >
        <Select
          {...postalOperatorSelectProps}
          placeholder={t("deliveries.fields.postalOperator.placeholder")}
          allowClear
        />
      </Form.Item>
      {isPostalOperatorSelected && (
        <>
          <Form.Item label={t("deliveries.fields.airdepartmentNr.label")} name="airdepartment_nr">
            <Input type="number" placeholder={t("deliveries.fields.airdepartmentNr.placeholder")} />
          </Form.Item>
          <Form.Item label={t("deliveries.fields.departureNr.label")} name="departure_nr">
            <Input placeholder={t("deliveries.fields.departureNr.placeholder")} />
          </Form.Item>
          <Form.Item label={t("deliveries.fields.flightNr.label")} name="flight_nr">
            <Input placeholder={t("deliveries.fields.flightNr.placeholder")} />
          </Form.Item>
          <Form.Item label={t("deliveries.fields.mrn.label")} name="mrn_nr">
            <Input placeholder={t("deliveries.fields.mrn.placeholder")} />
          </Form.Item>
          <Form.Item label={t("deliveries.fields.shipmentDate.label")} name="shipment_date">
            <DatePicker
              placeholder={t("deliveries.fields.shipmentDate.placeholder")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </>
      )}
      {isEdit ? (
        <Form.Item
          label={t("deliveries.fields.status")}
          name="delivery_status_id"
          rules={[{ required: true }]}
        >
          <Select
            {...deliveryStatusesSelectProps}
            options={deliveryStatusesSelectProps.options?.map(({ value, label }) => ({
              value,
              label: t(`deliveries.statuses.${label}`),
            }))}
            placeholder={t("deliveries.fields.status")}
          />
        </Form.Item>
      ) : (
        <Form.Item hidden name="delivery_status_id">
          <Input />
        </Form.Item>
      )}
      {shouldShowWarning && (
        <div>
          <Icons.ExclamationCircleOutlined style={{ color: "#D39230", marginRight: "5px" }} />
          <Typography.Text>{t("deliveries.statusChangeWarning")}</Typography.Text>
        </div>
      )}
    </Form>
  );
};
