import { FC, useEffect, useState } from "react";
import { useTranslate, useUpdate } from "@pankod/refine-core";
import { Avatar, Button, Col, Form, FormProps, Input, Row, Upload, UploadProps } from "@pankod/refine-antd";
import dayjs from "dayjs";
import type { UploadChangeParam } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { toBase64 } from "helpers";

import { useCurrentUser } from "hooks";
import { DeleteIcon, UploadIcon } from "assets";
import { Colors } from "theme";
import { AppPhoneInput } from "components/appPhoneInput";

interface Props {
  formProps: FormProps<{}>;
  isReadOnly: boolean;
  shouldShowFormAction?: boolean;
}

export const TenantForm: FC<Props> = props => {
  const {
    formProps: { initialValues, ...formProps },
    isReadOnly,
    shouldShowFormAction,
  } = props;
  const t = useTranslate();
  const { tenantId } = useCurrentUser();
  const [avatar, setAvatar] = useState<string>();
  const { mutate } = useUpdate();

  const handleAvatarChange: UploadProps["onChange"] = async (info: UploadChangeParam<UploadFile>) => {
    mutate({
      resource: "tenants",
      values: {
        logo: await toBase64(info?.file),
      },
      id: tenantId,
      successNotification: false,
    });
    setAvatar(URL.createObjectURL(info.file as any));
  };

  const handleDeleteClick = () => {
    mutate({
      resource: "tenants",
      id: tenantId,
      values: {
        logo: "",
      },
      successNotification: false,
    });
    setAvatar(undefined);
  };

  useEffect(() => {
    if (initialValues?.logo) {
      setAvatar(initialValues?.logo);
    }
  }, [initialValues?.logo]);

  return (
    <div style={{ marginInline: 100 }}>
      <Row>
        <Form
          {...formProps}
          initialValues={{
            isActive: true,
            ...initialValues,
            birthday: initialValues?.birthday ? dayjs(initialValues.birthday) : undefined,
          }}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Row
            gutter={[16, 16]}
            wrap
            style={{ borderBottom: `1px solid ${Colors.MintHover}`, paddingBottom: 20 }}
          >
            {avatar && (
              <Col xxl={2} xl={3} lg={3} md={2} sm={2} xs={2}>
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={avatar}
                  alt={initialValues?.name}
                />
              </Col>
            )}
            <Col
              xxl={3}
              xl={6}
              lg={avatar ? 8 : 6}
              md={avatar ? 9 : 8}
              sm={13}
              xs={14}
              style={{ alignSelf: "center" }}
            >
              <Upload
                listType="picture"
                maxCount={1}
                accept="image/*"
                beforeUpload={() => false}
                onChange={handleAvatarChange}
                showUploadList={false}
              >
                <Button
                  type="primary"
                  icon={<UploadIcon style={{ marginRight: "7px" }} />}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {t("profile.upload")}
                </Button>
              </Upload>
            </Col>
            <Col
              xxl={1}
              xl={5}
              lg={avatar ? 8 : 6}
              md={avatar ? 7 : 5}
              sm={9}
              xs={8}
              style={{ alignSelf: "center" }}
            >
              <Button
                type="default"
                icon={<DeleteIcon style={{ marginRight: "7px" }} />}
                style={{ display: "flex", alignItems: "center" }}
                onClick={handleDeleteClick}
              >
                {t("buttons.delete")}
              </Button>
            </Col>
          </Row>
          <Row gutter={[32, 32]} wrap style={{ paddingTop: 20 }}>
            <Col flex={1} lg={12} xl={12}>
              <Form.Item label={t("branches.fields.name")} name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.email")} name="email" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label={t("clients.phone")} name="phone" rules={[{ required: true }]}>
                <AppPhoneInput />
              </Form.Item>
              {!isReadOnly && (
                <Form.Item
                  label={t("branches.fields.balanceVerificationPeriod")}
                  name="balance_verification_period_in_weeks"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item label={t("branches.fields.bankName")} name="bank_name">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.jurisdiction")} name="jurisdiction">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.bic")} name="bic">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.iban")} name="iban">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.taxNumber")} name="tax_number">
                <Input />
              </Form.Item>
            </Col>
            <Col flex={1} lg={12} xl={12}>
              <Form.Item label={t("branches.fields.address")} name="address">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.country")} name="country">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.departureCountry")} name="departure_country">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.city")} name="city">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.zipcode")} name="zipcode">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.localCourt")} name="local_court">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.vatId")} name="vat_id">
                <Input />
              </Form.Item>
              {shouldShowFormAction && (
                <Form.Item noStyle>
                  <Button htmlType="submit" type="primary" style={{ justifySelf: "end" }}>
                    {t("buttons.save")}
                  </Button>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Row>
    </div>
  );
};
