import { FC, useState } from "react";
import { useTranslate, useUpdateMany } from "@pankod/refine-core";
import { Col, Form, FormProps, Input, Modal, ModalProps, Row, Typography, Icons } from "@pankod/refine-antd";
import dayjs from "dayjs";

import { IOrder } from "interfaces";
import { OrderStatus } from "../../constants";

interface Props {
  modalProps: ModalProps;
  formProps: FormProps;
  initialValues: Partial<IOrder>;
  orders: IOrder[];
  close: () => void;
  clearSelectedRows: () => void;
}

export const ConfirmMultipleArrivalsModal: FC<Props> = props => {
  const { modalProps, formProps, initialValues, orders, close, clearSelectedRows } = props;
  const t = useTranslate();
  const [hasPriceDifference, setHasPriceDifference] = useState<boolean>(false);
  const { mutate: updateOrders } = useUpdateMany();
  const firstOrder = orders[0];

  const handleOnFieldsChange = (changedFields: any) => {
    changedFields.forEach((field: any) => {
      if (field.name.includes("arrival_price")) {
        const hasDifference = Number(field.value) > firstOrder.ordered_price;
        setHasPriceDifference(hasDifference);
      }
    });
  };

  const handleOnFinish = (values: any) => {
    const { order_status_id, ...rest } = values;
    let request = values;

    if (hasPriceDifference) {
      request = { ...rest, order_status_id: OrderStatus.HeldInStock };
    }

    updateOrders(
      {
        resource: "orders",
        ids: orders.map(order => order!.id),
        values: request,
      },
      {
        onSuccess: () => {
          close();
          clearSelectedRows();
          formProps?.form?.resetFields();
        },
      }
    );
  };

  return (
    <Modal {...modalProps} width="50%" title={<h2>{t("arrival.receiveOrders")}</h2>} destroyOnClose>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
          ...initialValues,
          shop_invoice_date: dayjs(initialValues.shop_invoice_date).toISOString(),
          arrival_price: firstOrder.ordered_price,
          customs_price: firstOrder.customs_price ?? firstOrder.arrival_price ?? firstOrder.ordered_price,
        }}
        onFieldsChange={changedFields => handleOnFieldsChange(changedFields)}
        onFinish={handleOnFinish}
      >
        <Row gutter={[16, 16]}>
          <Col xl={24} lg={24} xs={24}>
            <Form.Item label={t("orders.fields.shortName")} name="short_name">
              <Input />
            </Form.Item>
            <Form.Item label={t("invoices.fields.price")} name="arrival_price" rules={[{ required: true }]}>
              <Input type="number" addonBefore="€" min={0} />
            </Form.Item>
            {hasPriceDifference && (
              <div style={styles.hint}>
                <Icons.ExclamationCircleOutlined style={styles.icon} />
                <Typography.Text>{t("arrival.priceDifference")}</Typography.Text>
              </div>
            )}
            <Form.Item
              label={t("orders.fields.customsPrice")}
              name="customs_price"
              rules={[{ required: true }]}
            >
              <Input type="number" addonBefore="€" min={0} />
            </Form.Item>
            <Form.Item
              label={t("arrival.additionalInfo.weight.label")}
              name="weight"
              rules={[{ required: true }]}
            >
              <Input
                type="number"
                min={0}
                addonBefore={t("arrival.additionalInfo.weight.suffix")}
                placeholder={t("arrival.additionalInfo.weight.placeholder")}
              />
            </Form.Item>
            <Form.Item label={t("arrival.additionalInfo.storagePlace.label")} name="storage_place">
              <Input placeholder={t("arrival.additionalInfo.storagePlace.placeholder")} />
            </Form.Item>
            <Form.Item name="order_status_id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="shop_invoice_nr" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="shop_invoice_date" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const styles = {
  hint: {
    paddingRight: "10px",
    marginTop: "-20px",
  },
  icon: {
    color: "#D39230",
    marginRight: "5px",
  },
};
