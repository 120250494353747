import { useCallback } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  useTable,
  useDrawerForm,
  Space,
  Empty,
  Table,
  TextField,
  DateField,
} from "@pankod/refine-antd";
import { HttpError, useNavigation } from "@pankod/refine-core";
import { useTranslation } from "react-i18next";

import { AppEmpty } from "components/appEmpty";
import { BalanceLineChart } from "components/charts";
import { CreateOrder, OrderTable } from "components/orders";
import { DeliveriesTable } from "components/deliveries";
import { IUpcomingBirthday, IDelivery, IOrder } from "interfaces";
import { Colors } from "theme";
import { useCurrentUser } from "hooks";
import { AppCreateButton } from "components/appCreateButton";
import { isUnableToCreateOrder } from "helpers";

export const DashboardPage = () => {
  const { t } = useTranslation();
  const { list } = useNavigation();
  const { isAdmin, user } = useCurrentUser();

  const { tableProps: birthdaysTableProps } = useTable<IUpcomingBirthday, HttpError>({
    resource: "upcoming_birthdays",
    hasPagination: true,
    initialPageSize: 3,
    permanentFilter: [
      {
        field: "days_until_next",
        operator: "lte",
        value: 7,
      },
    ],
  });

  const { tableProps: deliveriesTableProps } = useTable<IDelivery, HttpError>({
    resource: "deliveries",
    hasPagination: true,
    initialPageSize: 6,
  });

  const { tableProps, sorter } = useTable<IOrder, HttpError>({
    resource: "orders",
    hasPagination: true,
    initialPageSize: 6,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
    close: createClose,
  } = useDrawerForm<IOrder[], HttpError, IOrder[]>({
    action: "create",
    resource: "orders",
    redirect: false,
    successNotification: false,
  });

  const disableToCreateOrder = isUnableToCreateOrder(user, isAdmin);

  const renderBirsdaysCard = useCallback(
    () => (
      <Card title={t("dashboard.branchBirthdays")} headStyle={{ fontSize: "24px", fontWeight: "700" }}>
        <Table
          {...birthdaysTableProps}
          rowKey="id"
          size="small"
          rowClassName="app-table-row"
          id="mintTable"
          showHeader={false}
          locale={{
            emptyText: (
              <AppEmpty
                image={Empty.PRESENTED_IMAGE_DEFAULT}
                description={<span>{t("dashboard.noBirthdays")}</span>}
              />
            ),
          }}
        >
          <Table.Column ellipsis key="name" dataIndex="name" render={value => <TextField value={value} />} />
          <Table.Column
            key="next_birthday"
            dataIndex="next_birthday"
            align="left"
            render={value => <DateField value={value} format="DD.MM.YYYY" />}
          />
          <Table.Column
            key="days_until_next"
            dataIndex="days_until_next"
            align="left"
            render={value => <TextField value={`(${t("dashboard.in")} ${value} ${t("dashboard.days")})`} />}
          />
        </Table>
      </Card>
    ),
    [t, birthdaysTableProps]
  );

  const renderBalanceCard = useCallback(
    () => (
      <Card style={{ backgroundColor: Colors.EmeraldFocus }}>
        <div style={{ position: "relative", width: "100%", minHeight: "20vh" }}>
          <BalanceLineChart />
        </div>
      </Card>
    ),
    []
  );

  return (
    <Row gutter={[16, 16]}>
      <Col flex={1} xl={14} lg={14}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          {/* // TODO: Waiting for a data to display promo. */}
          {/* <Card>Create promo images with Figma and store it in DB to fetch and show it on Dashboard?</Card> */}
          <Card
            title={t("orders.orders")}
            extra={<AppCreateButton onClick={() => createShow()}>{t("buttons.create")}</AppCreateButton>}
            headStyle={{ fontSize: "24px", fontWeight: "700" }}
          >
            <OrderTable
              tableProps={tableProps}
              sorter={sorter}
              selectorId="mintTable"
              columnConfig={{
                hiddenBranch: true,
                hiddenClient: true,
                hiddenShop: true,
                hiddenOrderControl: true,
                hiddenCreatedAt: true,
                hiddenStoragePlace: true,
                hiddenArrivalPrice: true,
                hiddenCustomsPrice: true,
                hiddenWeight: true,
                hiddenCountry: true,
                hiddenOrderedPrice: true,
              }}
            />
          </Card>
          <CreateOrder
            drawerProps={createDrawerProps}
            formProps={createFormProps}
            saveButtonProps={createSaveButtonProps}
            close={createClose}
            isUnableToCreateOrder={disableToCreateOrder}
          />
        </Space>
      </Col>
      <Col flex={1} xl={10} lg={10}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Card
            title={t("deliveries.deliveries")}
            extra={
              <Button type="link" onClick={() => list("deliveries")} style={{ color: Colors.Emerald }}>
                {t("buttons.viewAll")}
              </Button>
            }
            headStyle={{ fontSize: "24px", fontWeight: "700" }}
          >
            <DeliveriesTable
              tableProps={deliveriesTableProps}
              selectorId="mintTable"
              enableActions={false}
              columnConfig={{ hiddenCreatedAt: true }}
            />
          </Card>
          {isAdmin && renderBirsdaysCard()}
          {!isAdmin && renderBalanceCard()}
        </Space>
      </Col>
    </Row>
  );
};
