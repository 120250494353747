import {
  calcOrdersTotalPriceWithDiscount,
  calcOrdersTotalWeight,
  calcTotalWeightOfEmptyContainers,
  groupSimilarOrders,
  removeTrailingZeroes,
} from "documents/utils";
import { localizeCurrency } from "helpers";
import { IContainer, IDelivery, IOrder, IPackage } from "interfaces";

export const prepareInvoiceDataForExcel = (
  delivery: IDelivery | undefined,
  packages: (IPackage | undefined)[] | undefined
): { csvData: any[]; headers: { label: string; key: string }[] } => {
  const allOrders = packages?.flatMap(pkg => pkg!.orders!);

  const containerWeight = (orders: IOrder[] | undefined, containerWeight: number | undefined) => {
    const ordersWeight = calcOrdersTotalWeight(orders ?? []);
    const total = ordersWeight + (containerWeight ?? 0) / 1000;

    return total > 0 ? removeTrailingZeroes(total.toFixed(3)) : "";
  };

  const calcInvoiceTotalPrice = () =>
    packages
      ? localizeCurrency(
          packages.reduce((total, current) => {
            if (current) {
              const { orders, branch_id } = current;
              return (total += calcOrdersTotalPriceWithDiscount(orders ?? [], branch_id));
            }
            return total;
          }, 0)
        )
      : "";

  const totalWeight = () => {
    const ordersWeight = calcOrdersTotalWeight(allOrders ?? []) ?? 0;
    const emptyContainersWeight = calcTotalWeightOfEmptyContainers(delivery?.containers ?? []) ?? 0;

    const total = ordersWeight + emptyContainersWeight;

    return total > 0 ? removeTrailingZeroes(total.toFixed(3)) : "";
  };

  const preparedData = (delivery?.containers ?? []).reduce(
    (acc: any, { weight, packages, container_nr }: IContainer) => [
      ...acc,
      {
        container_nr,
        article_nr:
          packages?.length &&
          `${packages[0].clients?.name}, ${packages[0].clients?.city}, ${packages[0].clients?.address}, ${packages[0].clients?.zipcode}`,
        weight: packages?.length && containerWeight(packages[0].orders, weight),
        sum:
          packages?.length &&
          localizeCurrency(calcOrdersTotalPriceWithDiscount(packages[0].orders ?? [], packages[0].branch_id)),
        passport_nr: packages?.length && packages[0].clients?.passport_nr,
      },
      ...Object.values({
        ...(packages?.length &&
          packages[0].orders &&
          groupSimilarOrders(packages[0].orders).map(({ article_number, items, name, short_name }) => ({
            article_nr: article_number ?? "",
            amount: items.length ?? "",
            name: short_name ?? name,
            price: localizeCurrency(+calcOrdersTotalPriceWithDiscount(items, packages[0].branch_id)) ?? "",
          }))),
      }),
    ],
    []
  );

  return {
    csvData: [
      ...preparedData,
      {
        article_nr: `Посылок всего: ${packages?.length ?? ""}`,
        weight: `Всего кг: ${totalWeight()}`,
        sum: `Общая сумма: ${calcInvoiceTotalPrice()}`,
      },
    ],
    headers: [
      {
        label: "№ посылки",
        key: "container_nr",
      },
      {
        label: "Номер Артикула по каталогу",
        key: "article_nr",
      },
      {
        label: "Кол-во (шт.)",
        key: "amount",
      },
      {
        label: "Наименование товара",
        key: "name",
      },
      {
        label: "Вес (брутто) кг",
        key: "weight",
      },
      {
        label: "Цена по каталогу",
        key: "price",
      },
      {
        label: "Сумма €",
        key: "sum",
      },
      {
        label: "Номер паспорта",
        key: "passport_nr",
      },
    ],
  };
};
