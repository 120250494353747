import { FC, useEffect } from "react";
import {
  ButtonProps,
  Drawer,
  DrawerProps,
  Create,
  FormProps,
  Grid,
  Form,
  DatePicker,
  Select,
  Input,
  useSelect,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";

import { TransactionTypes } from "../../constants";
import { IPaymentMethod } from "interfaces";

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  initialValues: FormInitialValues;
  transactionType: TransactionTypes;
}

interface FormInitialValues {
  branch_id: string;
  transaction_type: TransactionTypes;
  transaction_date: dayjs.Dayjs;
}

export const CreateTransaction: FC<Props> = props => {
  const { drawerProps, formProps, saveButtonProps, initialValues, transactionType } = props;
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();

  const { selectProps: paymentMethodProps } = useSelect<IPaymentMethod>({
    resource: "payment_methods",
    optionLabel: "value",
    optionValue: "id",
  });

  useEffect(() => {
    if (Object.values(initialValues).length) {
      const { branch_id, transaction_type, transaction_date } = initialValues;
      formProps.form?.setFieldsValue({ branch_id, transaction_type, transaction_date });
    }
  }, [formProps.form, initialValues]);

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t(
        transactionType === TransactionTypes.Incoming
          ? "branch_balance.titles.addIncoming"
          : "branch_balance.titles.addCorrection"
      )}
      destroyOnClose
      forceRender
    >
      <Create
        resource="clients"
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        title={false}
        saveButtonProps={saveButtonProps}
      >
        <Form {...formProps} layout="vertical" initialValues={initialValues}>
          <Form.Item
            label={t("branch_balance.fields.transactionDate")}
            name="transaction_date"
            rules={[{ required: true }]}
          >
            <DatePicker
              placeholder={t("branch_balance.fields.transactionDate")}
              showTime
              style={{ width: "100%" }}
            />
          </Form.Item>
          {transactionType === TransactionTypes.Incoming && (
            <Form.Item
              label={t("branch_balance.fields.paymentMethod")}
              name="payment_method"
              rules={[{ required: true }]}
            >
              <Select
                {...paymentMethodProps}
                options={paymentMethodProps.options?.map(({ value, label }) => ({
                  value,
                  label: t(`branch_balance.paymentMethods.${label}`),
                }))}
                allowClear
                placeholder={t("branch_balance.filter.payment_method.placeholder")}
              />
            </Form.Item>
          )}
          <Form.Item label={t("branch_balance.fields.amount")} name="amount" rules={[{ required: true }]}>
            <Input type="number" addonBefore="€" />
          </Form.Item>
          <Form.Item
            label={t("branch_balance.fields.description")}
            name="description"
            rules={[{ required: transactionType === TransactionTypes.Correction }]}
          >
            <Input placeholder={t("branch_balance.filter.description.placeholder")} allowClear />
          </Form.Item>
          <Form.Item label={t("branch_balance.fields.note")} name="note">
            <Input.TextArea placeholder={t("branch_balance.filter.note.placeholder")} rows={5} />
          </Form.Item>
          <Form.Item name="branch_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="transaction_type" hidden>
            <Input />
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
