import { FC, ReactNode, useMemo, useState } from "react";
import { Show, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useList, useTranslate, useUpdate } from "@pankod/refine-core";

import { UserForm } from "components/user";
import { useCurrentUser } from "hooks";
import { IBranch, IClient, ITenant } from "interfaces";
import { TenantForm } from "components/tenant";

export const Profile: FC<IResourceComponentsProps> = () => {
  const { userId, isAdmin, tenantId } = useCurrentUser();
  const t = useTranslate();
  const { mutate: updateClient } = useUpdate();

  const [activeTabKey, setActiveTabKey] = useState<string>("personalData");

  const { data: clients } = useList<IClient>({
    resource: "clients",
    queryOptions: { enabled: !!userId },
    config: {
      filters: [
        {
          field: "branch_id",
          operator: "eq",
          value: userId,
        },
        {
          field: "is_branch",
          operator: "eq",
          value: true,
        },
      ],
    },
  });

  const { formProps: profileProps } = useForm<IBranch>({
    resource: "branches",
    id: userId,
    action: "edit",
    redirect: false,
    successNotification: { message: t("profile.profileChangesSaved"), type: "success" },
    warnWhenUnsavedChanges: true,
    onMutationSuccess: (_, variables) => {
      if (clients?.data.length) {
        const { birthday, credit, current_balance, shipping_cost_per_kg, ...client } = variables as IBranch;

        updateClient({
          resource: "clients",
          id: clients.data[0].id,
          values: client,
          successNotification: false,
        });
      }
    },
  });

  const { formProps: tenantProps } = useForm<ITenant>({
    resource: "tenants",
    id: tenantId,
    action: "edit",
    redirect: false,
    successNotification: { message: t("profile.tenantChangesSaved"), type: "success" },
    warnWhenUnsavedChanges: true,
  });

  const tabList = [
    {
      key: "personalData",
      tab: t("branches.personalData"),
    },
    {
      key: "tenant",
      tab: t("profile.tenant"),
    },
  ];

  const contentList: Record<string, ReactNode> = useMemo(
    () => ({
      personalData: <UserForm formProps={profileProps} isReadOnly={true} shouldShowFormAction={true} />,
      ...(isAdmin && {
        tenant: <TenantForm formProps={tenantProps} isReadOnly={true} shouldShowFormAction={true} />,
      }),
    }),
    [isAdmin, profileProps, tenantProps]
  );

  return (
    <Show
      title=""
      breadcrumb={false}
      headerProps={{ extra: null }}
      contentProps={{
        tabList: !isAdmin ? tabList.slice(0, 1) : tabList,
        onTabChange: (key: string) => setActiveTabKey(key),
      }}
    >
      {contentList[activeTabKey]}
    </Show>
  );
};
