import "@pankod/refine-antd/dist/reset.css";
import "./index.css";
import { ReactNode, useEffect } from "react";
import { Refine } from "@pankod/refine-core";
import {
  AuthPage,
  notificationProvider,
  ConfigProvider,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import { dataProvider, liveProvider } from "@pankod/refine-supabase";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { RefineKbarProvider } from "@pankod/refine-kbar";
import { Font } from "@react-pdf/renderer";

import { supabaseClient, authProvider, resources } from "config";
import { Title, Header, Sider, Footer, Layout, OffLayoutArea, Register } from "components/layout";
import { Profile } from "pages/profile";
import { DashboardPage } from "pages/dashboard";
import { appTheme } from "theme";
import { ArrivalList } from "pages/arrival";

const authWrapperProps = {
  style: {
    background: `radial-gradient(50% 50% at 50% 50%, #002140 0%, #001529 100%)`,
    backgroundSize: "cover",
  },
};

function App() {
  const { t, i18n } = useTranslation();
  const isAdmin = () => {
    const session = localStorage.getItem("supabase.auth.token");

    if (session) {
      const metadata = JSON.parse(session).currentSession.user.user_metadata;
      return !!metadata?.isAdmin;
    }

    return false;
  };

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const renderAuthContent = (content: ReactNode) => (
    <>
      <div
        style={{
          maxWidth: 460,
          borderBottom: "1px solid white",
          margin: "auto",
          marginBottom: 60,
          paddingInline: 30,
        }}
      >
        <img src={"/images/logo-white.svg"} alt="flexibuy logo" style={{ marginBottom: 20 }} width="100%" />
      </div>
      {content}
    </>
  );

  /**
   * Setup fonts for @react-pdf to display Russian language.
   */
  useEffect(() => {
    Font.register({
      family: "Roboto",
      fonts: [
        { src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf" },
        {
          src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
          fontWeight: "bold",
        },
      ],
    });
  }, []);

  return (
    <ConfigProvider theme={appTheme}>
      <RefineKbarProvider>
        <Refine
          dataProvider={dataProvider(supabaseClient)}
          liveProvider={liveProvider(supabaseClient)}
          authProvider={authProvider}
          accessControlProvider={{
            can: async ({ resource, action }) => {
              if (
                resource === "branches" ||
                resource === "shops" ||
                resource === "arrival" ||
                resource === "stock"
              ) {
                return Promise.resolve({
                  can: isAdmin(),
                  reason: "Admin only",
                });
              }
              if (
                resource === "branch_shops" ||
                resource === "financial_transactions" ||
                resource === "clients" ||
                resource === "invoices"
              ) {
                return Promise.resolve({
                  can: !isAdmin(),
                  reason: "Pages for branch only",
                });
              }
              if (resource === "deliveries" && action === "edit") {
                return Promise.resolve({
                  can: isAdmin(),
                  reason: "Admin only",
                });
              }

              return Promise.resolve({ can: true });
            },
          }}
          routerProvider={{
            ...routerProvider,
            routes: [
              {
                path: "/register",
                element: <Register />,
              },
              {
                path: "/forgot-password",
                element: (
                  <AuthPage
                    type="forgotPassword"
                    wrapperProps={authWrapperProps}
                    renderContent={(content: ReactNode) => renderAuthContent(content)}
                  />
                ),
              },
              {
                path: "/update-password",
                element: (
                  <AuthPage
                    type="updatePassword"
                    wrapperProps={authWrapperProps}
                    renderContent={(content: ReactNode) => renderAuthContent(content)}
                  />
                ),
              },
              {
                path: "/profile",
                element: <Profile />,
                layout: true,
              },
              {
                path: "/arrival",
                element: <ArrivalList />,
                layout: true,
              },
            ],
          }}
          LoginPage={() => (
            <AuthPage
              type="login"
              providers={[]}
              registerLink={false}
              formProps={{
                initialValues: {},
              }}
              wrapperProps={authWrapperProps}
              renderContent={(content: ReactNode) => renderAuthContent(content)}
            />
          )}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
          DashboardPage={DashboardPage}
          i18nProvider={i18nProvider}
          resources={resources}
        />
      </RefineKbarProvider>
    </ConfigProvider>
  );
}

export default App;
