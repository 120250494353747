import { FC, useMemo } from "react";
import {
  IResourceComponentsProps,
  useCreate,
  useList,
  useModal,
  useOne,
  useShow,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Show,
  Space,
  Tag,
  Timeline,
  Tooltip,
  Typography,
  useSimpleList,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { IOrder, IOrderStatus, IOrderStatusHistory, ITenant } from "interfaces";
import { OrderPdfLayout } from "documents/order";
import { isOrderHasStorageInfo, localizeCurrency } from "helpers";
import { OrderStatus } from "../../constants";
import { useCurrentUser } from "hooks";
import { Colors } from "theme";
import { AppTag } from "components/appTag";
import { CancelOrderModal } from "components/orders";
import { convertGramsToKilo } from "helpers/weight";

dayjs.extend(relativeTime);

export const OrderShow: FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { mutate: mutateCreate } = useCreate();
  const { mutate: mutateUpdate } = useUpdate();
  const { isAdmin, tenantId } = useCurrentUser();

  const { queryResult } = useShow<IOrder>({
    metaData: { select: "*, shops!inner(name)" },
  });
  const order = queryResult.data?.data;

  const { listProps: historyListProps, queryResult: historyQueryResult } = useSimpleList<IOrderStatusHistory>(
    {
      resource: "order_history",
      permanentFilter: [
        {
          field: "order_id",
          operator: "eq",
          value: order?.id,
        },
      ],
      initialSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      pagination: {
        pageSize: 6,
        simple: true,
      },
      syncWithLocation: false,
    }
  );

  const { data: tenant } = useOne<ITenant>({
    resource: "tenants",
    id: tenantId,
    queryOptions: { enabled: !!tenantId },
  });

  const { dataSource: historyDataSource } = historyListProps;

  const arrivalDate = useMemo(() => {
    if (isOrderHasStorageInfo(order) && historyDataSource) {
      return historyDataSource?.find(history => history.new_order_status_id === OrderStatus.InStock)
        ?.created_at;
    }

    return undefined;
  }, [historyDataSource, order]);

  const { data: orderStatusData, isFetching: isFetchingOrderStatusData } = useList<IOrderStatus>({
    resource: "order_statuses",
    config: {
      pagination: { pageSize: 15 },
    },
  });

  const { show: showPdfModal, visible: visiblePdfModal, close: closePdfModal } = useModal();
  const {
    show: showCancelOrderModal,
    visible: visibleCancelOrderModal,
    close: closeCancelOrderModal,
  } = useModal();

  const orderStatus = (id: number) => orderStatusData?.data.find(o => o.id === id);

  const handleCompleteOrderControl = () => {
    mutateUpdate(
      {
        resource: "orders",
        id: order!.id,
        values: {
          under_control: false,
        },
      },
      {
        onSuccess: () => {
          mutateCreate({
            resource: "order_history",
            values: {
              order_id: order!.id,
              label: "controlWasFinished",
              new_order_status_id: order!.order_status_id,
              created_at: new Date(),
              branch_id: order!.branch_id,
            },
            successNotification: false,
          });
        },
      }
    );
  };

  return (
    <Show
      isLoading={historyQueryResult?.isFetching || isFetchingOrderStatusData}
      contentProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
      breadcrumb={false}
      headerButtons={
        order?.under_control && isAdmin ? (
          <Button type="primary" onClick={handleCompleteOrderControl}>
            {t("buttons.completeOrderControl")}
          </Button>
        ) : (
          <></>
        )
      }
      title={`${t("orders.order")} #${order?.id}`}
    >
      <Row gutter={[16, 16]}>
        <Col flex={1} xl={14} lg={14}>
          <Card title={t("orders.orderDescription")} headStyle={{ fontSize: "24px", fontWeight: "700" }}>
            <Space size="small" direction="vertical" style={styles.cardContent}>
              <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
                <div style={styles.flexRow}>
                  <Typography.Title level={5}>{`${t("orders.fields.name")}:`}</Typography.Title>
                  <Typography.Text style={{ marginLeft: "5px" }}>{order?.name}</Typography.Text>
                </div>
                <AppTag
                  queryResult={orderStatusData}
                  translationPrefix="orders.statuses"
                  value={order?.order_status_id}
                />
              </div>
              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.shortName")}:`}</Typography.Title>
                <Typography.Text style={{ marginLeft: "5px" }}>{order?.short_name}</Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.articleNumber")}:`}</Typography.Title>
                <Typography.Text style={{ marginLeft: "5px" }}>{order?.article_number}</Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.price")}:`}</Typography.Title>
                <Typography.Text style={{ marginLeft: "5px" }}>
                  {localizeCurrency(order?.arrival_price ?? order?.ordered_price!)}
                </Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.customsPrice")}:`}</Typography.Title>
                <Typography.Text style={{ marginLeft: "5px" }}>
                  {localizeCurrency(order?.customs_price ?? order?.arrival_price ?? order?.ordered_price!)}
                </Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.shop")}:`}</Typography.Title>
                <Typography.Text style={{ marginLeft: "5px" }}>{order?.shops?.name}</Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.size")}:`}</Typography.Title>
                <Typography.Text style={{ marginLeft: "5px" }}>{order?.size}</Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.color")}:`}</Typography.Title>
                <Typography.Text style={{ marginLeft: "5px" }}>{order?.color}</Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.description")}:`}</Typography.Title>
                <Typography.Text style={{ marginLeft: "5px" }}>{order?.description}</Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.link")}:`}</Typography.Title>
                <Typography.Link href={order?.link} style={{ marginLeft: "5px" }} target="_blank">
                  {order?.link}
                </Typography.Link>
              </div>

              <Divider style={{ margin: "0px" }} />

              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.createdAt")}:`}</Typography.Title>
                <Typography.Text style={{ marginLeft: "5px" }}>
                  {dayjs(order?.created_at).format("L LT")}
                </Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Title level={5}>{`${t("orders.fields.updatedAt")}:`}</Typography.Title>
                <Typography.Text style={{ marginLeft: "5px" }}>
                  {order?.updated_at ? dayjs(order.updated_at).format("L LT") : ""}
                </Typography.Text>
              </div>
            </Space>
            <Button
              block
              type="primary"
              style={{ marginTop: "24px" }}
              onClick={() => showCancelOrderModal()}
              disabled={order && order.order_status_id > OrderStatus.Ordered} // TODO: Discuss the statuses to cancel order.
            >
              {t("buttons.cancelOrder")}
            </Button>
          </Card>
          {isOrderHasStorageInfo(order) && (
            <Card
              title={t("orders.arrivalInfo")}
              headStyle={{ fontSize: "24px", fontWeight: "700" }}
              style={{ marginTop: "16px" }}
            >
              <Space direction="vertical" style={styles.cardContent}>
                {isAdmin && (
                  <div style={styles.flexRow}>
                    <Typography.Title level={5}>{`${t(
                      "arrival.additionalInfo.storagePlace.label"
                    )}:`}</Typography.Title>
                    <Typography.Text style={{ marginLeft: "5px" }}>
                      {order?.storage_place ?? "-"}
                    </Typography.Text>
                  </div>
                )}
                <div style={styles.flexRow}>
                  <Typography.Title level={5}>{`${t("orders.arrivalDate")}:`}</Typography.Title>
                  <Typography.Text style={{ marginLeft: "5px" }}>
                    {dayjs(arrivalDate).format("L LT")}
                  </Typography.Text>
                </div>
                <div style={styles.flexRow}>
                  <Typography.Title level={5}>{`${t("weight.title")}:`}</Typography.Title>
                  <Typography.Text style={{ marginLeft: "5px" }}>{`${convertGramsToKilo(order?.weight)} ${t(
                    "weight.kilogram"
                  )}`}</Typography.Text>
                </div>
              </Space>
              {isAdmin && tenant?.data.has_storage_label && (
                <Button block type="primary" onClick={() => showPdfModal()} style={{ marginTop: "24px" }}>
                  {t("buttons.orderLabel")}
                </Button>
              )}
            </Card>
          )}
        </Col>
        <Col flex={1} xl={10} lg={10}>
          <Card
            bordered={false}
            title={t("orders.history")}
            headStyle={{ fontSize: "24px", fontWeight: "700" }}
          >
            <Timeline>
              {historyDataSource?.map(({ created_at, new_order_status_id, id, label }) => {
                const status = orderStatus(new_order_status_id);
                return (
                  <Timeline.Item key={id}>
                    <Tag
                      color={status?.color}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 12,
                        borderRadius: 6,
                      }}
                    >
                      <Tooltip
                        overlayInnerStyle={{ color: "#626262" }}
                        color="rgba(255, 255, 255, 0.3)"
                        placement="topLeft"
                        title={dayjs(created_at).format("lll")}
                      >
                        <Typography.Text italic style={{ fontSize: 12, cursor: "default", color: "white" }}>
                          {dayjs(created_at).fromNow()}
                        </Typography.Text>
                      </Tooltip>
                      {label ? (
                        <Typography.Text style={{ color: "white" }}>
                          {t(`orders.historyLabel.${label}`)}
                        </Typography.Text>
                      ) : (
                        <Typography.Text style={{ color: "white" }}>
                          {t(`orders.statuses.${status?.value}`)}
                        </Typography.Text>
                      )}
                    </Tag>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Card>
        </Col>
      </Row>
      {visiblePdfModal && (
        <Modal open={visiblePdfModal} onCancel={closePdfModal} width="80%" footer={null}>
          <OrderPdfLayout orderId={order!.id} />
        </Modal>
      )}
      {visibleCancelOrderModal && (
        <CancelOrderModal
          open={visibleCancelOrderModal}
          onClose={closeCancelOrderModal}
          order={order!}
          refreshOrderHistory={historyQueryResult.refetch}
        />
      )}
    </Show>
  );
};

const styles = {
  cardContent: {
    width: "100%",
    border: `1px solid ${Colors.Emerald}`,
    borderRadius: "10px",
    backgroundColor: Colors.MintDefault,
    padding: "20px",
  },
  flexRow: {
    display: "flex",
    alignItems: "baseline",
    flexWrap: "wrap" as any,
  },
};
