import { FC } from "react";
import { Page, Image, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";

import { createBarcode, localizeCurrency } from "helpers";
import { IOrder, IOrderStatusHistory } from "interfaces";

interface Props {
  order: IOrder;
  orderHistory: IOrderStatusHistory;
}

export const OrderStorageLabel: FC<Props> = props => {
  const {
    order: { id, article_number, client_id, arrival_price, shops, storage_place, weight, branches },
    orderHistory,
  } = props;
  const barcodeUrl = createBarcode(id.toString());

  return (
    <Document title="Order storage label">
      <Page size="A8" orientation="landscape" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.flexRow}>
            <View style={styles.bordered}>
              <Text style={[styles.boldText, { padding: 5 }]}>{storage_place}</Text>
            </View>
            <View>
              <View style={styles.bordered}>
                <Text>{article_number}</Text>
              </View>
              <Text>{shops?.name}</Text>
            </View>
            <View style={styles.bordered}>
              <Text style={styles.boldText}>{String(branches?.branch_nr).padStart(5, "0")}</Text>
              <Text>{client_id}</Text>
            </View>
          </View>

          <View style={[styles.flexRow, styles.barcodeContainer]}>
            <Image src={barcodeUrl} />
          </View>
          <View style={styles.flexRow}></View>

          <View style={styles.flexRow}>
            <View style={styles.bordered}>
              <Text style={[styles.boldText, styles.TODO, { padding: 5 }]}>1</Text>
            </View>
            <View>
              <Text style={styles.TODO}>Z2521499</Text>
              <Text style={styles.TODO}>10010</Text>
            </View>
            <View>
              <Text>{`${weight} g`}</Text>
              <Text>{dayjs(orderHistory.created_at).format("L")}</Text>
            </View>
            <View>
              <Text>{localizeCurrency(arrival_price)}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 7,
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    width: "55mm",
    height: "30mm",
    border: "0.5 solid red",
    borderRadius: 10,
    padding: 3,
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bordered: {
    border: "0.5 solid black",
  },
  boldText: {
    fontWeight: "bold",
  },
  barcodeContainer: {
    flex: 1,
    justifyContent: "center",
  },
  TODO: {
    color: "red",
  },
});
