import { FC } from "react";
import { Modal, Typography } from "@pankod/refine-antd";
import { useTranslate, useUpdate } from "@pankod/refine-core";
import { IOrder } from "interfaces";
import { OrderStatus } from "../../constants";

interface Props {
  open: boolean;
  onClose: () => void;
  order: IOrder;
  refreshOrderHistory: () => void;
}

export const CancelOrderModal: FC<Props> = props => {
  const { open, onClose, order, refreshOrderHistory } = props;
  const t = useTranslate();
  const { mutate: updateMutation } = useUpdate();

  const handleOk = () => {
    updateMutation(
      {
        resource: "orders",
        id: order.id,
        values: {
          order_status_id: OrderStatus.Cancelled,
        },
      },
      {
        onSuccess: () => {
          refreshOrderHistory();
          onClose();
        },
      }
    );
  };

  return (
    <Modal
      open={open}
      onOk={handleOk}
      onCancel={onClose}
      cancelText={t("buttons.no")}
      okText={t("buttons.yes")}
    >
      <Typography.Title level={4}>{t("orders.confirmCancerOrder")}</Typography.Title>
    </Modal>
  );
};
