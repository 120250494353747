import { FC } from "react";
import { HttpError, IResourceComponentsProps, useNotification, useTranslate } from "@pankod/refine-core";
import { Button, Col, DateField, Icons, List, Row, Table, TextField, useTable } from "@pankod/refine-antd";

import { IInvoice } from "interfaces";
import { useCurrentUser, useGetFileUrl, invoicePath } from "hooks";
import { AppEmpty } from "components/appEmpty";
import { localizeCurrency } from "helpers";

export const InvoiceList: FC<IResourceComponentsProps> = () => {
  const { tenantId } = useCurrentUser();
  const { tableProps } = useTable<IInvoice, HttpError>({
    permanentFilter: [{ field: "cancelled", operator: "ne", value: true }],
  });

  const t = useTranslate();
  const { open } = useNotification();

  const fileUrl = useGetFileUrl();

  const handleOpenInvoice = async (record: IInvoice) => {
    const { branch_id, invoice_nr, created_at } = record;

    const data = await fileUrl(invoicePath(tenantId, branch_id, invoice_nr, created_at));
    if (data.error && open) {
      open({ message: t(data.error.message), type: "error" });
    } else if (data.signedURL) {
      window.open(data.signedURL, "_blank");
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={24} xs={24}>
          <List title={false}>
            <Table
              {...tableProps}
              rowClassName="app-table-row"
              rowKey="id"
              size="small"
              locale={{
                emptyText: (
                  <AppEmpty
                    description={
                      <span style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>
                        {t("table.empty")}
                      </span>
                    }
                  />
                ),
              }}
            >
              <Table.Column
                key="invoice_nr"
                dataIndex="invoice_nr"
                title={t("invoices.fields.invoiceNumber")}
                render={value => <TextField value={value} />}
              />

              <Table.Column
                key="amount"
                dataIndex="amount"
                title={t("invoices.fields.amount")}
                render={value => <TextField value={localizeCurrency(value)} />}
              />
              <Table.Column
                key="created_at"
                dataIndex="created_at"
                title={t("invoices.fields.createdAt")}
                render={value => <DateField value={value} format="DD.MM.YYYY" />}
                sorter
              />

              <Table.Column<IInvoice>
                fixed="right"
                title={t("table.actions")}
                dataIndex="actions"
                key="actions"
                align="center"
                render={(_, record) => (
                  <>
                    <Button icon={<Icons.FilePdfOutlined />} onClick={() => handleOpenInvoice(record)} />
                  </>
                )}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};
