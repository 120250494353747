import { FC } from "react";
import { Button, Popconfirm, Table, TextField, useTable } from "@pankod/refine-antd";
import { HttpError, useDelete, useTranslate } from "@pankod/refine-core";

import { IClient } from "interfaces";
import { DeleteIcon, EditIcon } from "assets";
import { AppEmpty } from "components/appEmpty";
import { useCurrentUser } from "hooks";

interface Props {
  selectorId?: string;
  branchId: string;
  onRecordEdit: (id: number) => void;
}

export const ClientsTable: FC<Props> = props => {
  const { branchId, selectorId, onRecordEdit } = props;
  const t = useTranslate();
  const { mutate: deleteClient } = useDelete();
  const { userId } = useCurrentUser();

  const {
    tableProps,
    tableQueryResult: { refetch },
  } = useTable<IClient, HttpError>({
    resource: "clients",
    permanentFilter: [
      {
        field: "branch_id",
        operator: "eq",
        value: branchId,
      },
    ],
    queryOptions: {
      enabled: !!branchId,
    },
  });

  const handleDeleteClient = (id: number) => {
    deleteClient({ resource: "clients", id }, { onSuccess: () => refetch() });
  };

  return (
    <Table
      {...tableProps}
      id={selectorId}
      rowKey="id"
      size="small"
      rowClassName="app-table-row"
      locale={{
        emptyText: (
          <AppEmpty
            description={
              <span style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>{t("table.empty")}</span>
            }
          />
        ),
      }}
    >
      <Table.Column
        key="name"
        dataIndex="name"
        title={t("branches.fields.name")}
        sorter
        render={value => <TextField value={value} />}
      />
      <Table.Column
        key="passport_nr"
        dataIndex="passport_nr"
        title={t("branches.fields.passportNr.label")}
        render={value => <TextField value={value} />}
        sorter
      />
      <Table.Column
        key="country"
        dataIndex="country"
        title={t("branches.fields.country")}
        render={value => <TextField value={value ? t(`countries.${value}`) : ""} />}
        sorter
      />
      <Table.Column
        key="address"
        dataIndex="address"
        title={t("branches.fields.address")}
        render={value => <TextField value={value} />}
        sorter
      />
      <Table.Column
        key="city"
        dataIndex="city"
        title={t("branches.fields.city")}
        render={value => <TextField value={value} />}
      />
      <Table.Column
        key="zipcode"
        dataIndex="zipcode"
        title={t("branches.fields.zipcode")}
        render={value => <TextField value={value} />}
      />
      <Table.Column
        key="phone"
        dataIndex="phone"
        title={t("clients.phone")}
        render={value => <TextField value={value} />}
      />
      {/* // TODO: TBD in US #1139 */}
      {/* <Table.Column
        key="is_active"
        dataIndex="is_active"
        title={t("shops.fields.isActive")}
        render={value => <BooleanField value={value} />}
      /> */}
      <Table.Column<IClient>
        fixed="right"
        title={t("table.actions")}
        dataIndex="actions"
        key="actions"
        align="center"
        render={(_, record) => (
          <>
            <Button
              icon={<EditIcon />}
              style={{ borderColor: "transparent", background: "transparent" }}
              onClick={() => onRecordEdit(record.id)}
              disabled={record.is_branch && branchId === userId}
            />
            <Popconfirm
              key="delete"
              okText={t("buttons.delete")}
              cancelText={t("buttons.cancel")}
              okType="danger"
              title={t("buttons.confirm")}
              onConfirm={() => handleDeleteClient(record.id)}
              disabled={record.is_branch}
            >
              <Button
                icon={<DeleteIcon />}
                style={{ borderColor: "transparent", background: "transparent" }}
                disabled={record.is_branch}
              />
            </Popconfirm>
          </>
        )}
      />
    </Table>
  );
};
