import React from "react";
import { TitleProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={collapsed ? "/images/e-icon.svg" : "/images/logo-white.svg"}
        alt="flexibuy"
        style={{
          width: "200px",
          height: "40px",
          margin: "30px 25px",
        }}
      />
    </div>
  </Link>
);
