import { supabaseClient } from "config";
import { useState, useCallback } from "react";

const { REACT_APP_ENVIRONMENT } = process.env;

export const useDownloadFile = () => {
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const download = useCallback(async (path: string) => {
    setDownloading(true);
    setError(null);
    try {
      const { data, error } = await supabaseClient.storage.from(REACT_APP_ENVIRONMENT!).download(path);

      if (error) {
        setError(error);
      }

      return data;
    } catch (err: any) {
      setError(err.message);
    } finally {
      setDownloading(false);
    }
  }, []);

  return { loading: downloading, error, download };
};

export const useUploadFile = () => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const upload = useCallback(
    async (path: string, file: FormData | Blob, replaceIfExisting: boolean = false) => {
      setUploading(true);
      setError(null);
      try {
        return await supabaseClient.storage
          .from(REACT_APP_ENVIRONMENT!)
          .upload(path, file, { upsert: replaceIfExisting });
      } catch (err: any) {
        setError(err.message);
      } finally {
        setUploading(false);
      }
    },
    []
  );

  return { uploading, error, upload };
};

export const useRemoveFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const remove = useCallback(async (path: string) => {
    setLoading(true);
    setError(null);
    try {
      return await supabaseClient.storage.from(REACT_APP_ENVIRONMENT!).remove([path]);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, error, remove };
};

export const useGetFileUrl = () => {
  const fileUrl = useCallback(async (path: string) => {
    const data = await supabaseClient.storage.from(REACT_APP_ENVIRONMENT!).createSignedUrl(path, 4000);
    return data;
  }, []);

  return fileUrl;
};
