import { FC } from "react";
import { ButtonProps, Drawer, DrawerProps, Create, FormProps, Grid } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

import { CreateEditForm } from "./createEditForm";

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
}

export const CreateContainer: FC<Props> = props => {
  const { drawerProps, formProps, saveButtonProps } = props;
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t("containers.titles.create")}
      destroyOnClose
      forceRender
    >
      <Create
        resource="containers"
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        title={false}
        saveButtonProps={saveButtonProps}
      >
        {CreateEditForm(formProps)}
      </Create>
    </Drawer>
  );
};
