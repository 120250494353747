import dayjs from "dayjs";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import detector from "i18next-browser-languagedetector";

require("dayjs/locale/ru");
require("dayjs/locale/en");

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["ru", "en"],
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    defaultNS: "common",
    fallbackLng: ["ru", "en"],
  });

i18n.on("languageChanged", function (lng) {
  dayjs.locale(lng);
});

export default i18n;
