import { FC, Key, useState, useCallback } from "react";
import {
  CreateResponse,
  HttpError,
  IResourceComponentsProps,
  UpdateResponse,
  useCreate,
  useTranslate,
} from "@pankod/refine-core";
import { Button, List, Space, useDrawerForm, useTable } from "@pankod/refine-antd";

import { IOrder, IOrderFilterVariables } from "interfaces";
import {
  OrderFilter,
  OrderTable,
  createOnSearchFilter,
  EditOrder,
  CreateOrder,
  EditMultipleOrders,
} from "components/orders";
import { OrderStatus } from "../../constants";
import { FiltersButton } from "components/appFilterButton";
import { useCurrentUser } from "hooks";
import { AppCreateButton } from "components/appCreateButton";
import { isUnableToCreateOrder } from "helpers";

export const OrderList: FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { mutate } = useCreate();
  const { isAdmin, user } = useCurrentUser();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<IOrder[]>([]);

  const {
    tableProps,
    sorter,
    searchFormProps,
    filters,
    tableQueryResult: { refetch },
  } = useTable<IOrder, HttpError, IOrderFilterVariables>({
    resource: "orders",
    onSearch: params => createOnSearchFilter(params),
    hasPagination: true,
    initialPageSize: 25,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
  });

  const {
    drawerProps: editMultipleOrdersDrawerProps,
    formProps: editMultipleOrdersFormProps,
    saveButtonProps: editMultipleOrdersSaveButtonProps,
    show: editMultipleOrdersShow,
    close: editMultipleOrdersClose,
  } = useDrawerForm<IOrder, HttpError, IOrder>({
    action: "create",
    resource: "orders",
    redirect: false,
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<IOrder, HttpError, IOrder>({
    action: "edit",
    resource: "orders",
    redirect: false,
    onMutationSuccess: data => {
      createOrderHistoryOnEdit(data, editFormProps.initialValues);
    },
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
    close: createClose,
  } = useDrawerForm<IOrder[], HttpError, IOrder[]>({
    action: "create",
    resource: "orders",
    redirect: false,
    successNotification: false,
  });

  const rowSelection = {
    selectedRowKeys,
    getCheckboxProps: (record: IOrder) => ({
      disabled:
        record.order_status_id === OrderStatus.BeingPacked || record.order_status_id === OrderStatus.Shipped,
    }),
    onChange: (selectedRowKeys: Key[], selectedRows: IOrder[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const hasSelected = selectedRowKeys.length > 0;
  const disableToCreateOrder = isUnableToCreateOrder(user, isAdmin);

  const clearSelectedRows = useCallback(() => setSelectedRowKeys([]), []);
  const handleCloseEditMultipleOrders = useCallback(() => {
    editMultipleOrdersClose();
    refetch();
  }, [editMultipleOrdersClose, refetch]);

  const createOrderHistoryOnEdit = (
    response: CreateResponse<IOrder> | UpdateResponse<IOrder>,
    initialValues: any
  ) => {
    const {
      data: { under_control, order_status_id, id, branch_id },
    } = response;

    if (under_control !== initialValues.under_control) {
      mutate({
        resource: "order_history",
        values: {
          order_id: id,
          label: under_control ? "orderUnderControl" : "removedOrderControl",
          new_order_status_id: order_status_id,
          created_at: new Date(),
          branch_id,
        },
        successNotification: false,
      });
    }
  };

  return (
    <>
      <List
        title={false}
        headerProps={{
          subTitle: hasSelected && (
            <Space style={{ gap: 0, marginLeft: "1em" }}>
              <Button type="primary" onClick={() => editMultipleOrdersShow()}>
                {t("orders.titles.editOrders")}
              </Button>
            </Space>
          ),
        }}
        headerButtons={() => (
          <>
            <AppCreateButton onClick={() => createShow()}>{t("buttons.create")}</AppCreateButton>
            <FiltersButton
              content={
                <OrderFilter
                  isAdmin={isAdmin}
                  formProps={searchFormProps}
                  filters={filters || []}
                  fieldsConfig={{
                    hiddenArrivalPrice: true,
                    hiddenCountryField: true,
                  }}
                />
              }
            />
          </>
        )}
      >
        <OrderTable
          tableProps={tableProps}
          sorter={sorter}
          enableActions={true}
          rowSelection={rowSelection}
          onRecordEdit={editShow}
          columnConfig={{
            hiddenUpdatedAt: true,
            hiddenStoragePlace: true,
            hiddenArrivalPrice: true,
            hiddenCustomsPrice: true,
            hiddenWeight: true,
            hiddenCountry: true,
            hiddenOrderedPrice: true,
          }}
        />
      </List>
      <EditOrder
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
        isUnableToCreateOrder={disableToCreateOrder}
      />
      <CreateOrder
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        close={createClose}
        isUnableToCreateOrder={disableToCreateOrder}
      />
      {editMultipleOrdersDrawerProps.open && (
        <EditMultipleOrders
          drawerProps={editMultipleOrdersDrawerProps}
          formProps={editMultipleOrdersFormProps}
          saveButtonProps={editMultipleOrdersSaveButtonProps}
          close={handleCloseEditMultipleOrders}
          selectedRows={selectedRows}
          clearSelectedRows={clearSelectedRows}
        />
      )}
    </>
  );
};
