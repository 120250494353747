import { Colors } from "theme";

export const styles = {
  header: {
    height: "100px",
    display: "flex",
    alignItems: "center",
    backgroundColor: Colors.MintMain,
    paddingInline: "24px",
  },
  container: {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px",
    borderColor: Colors.NavyBlue,
    borderBottomStyle: "solid" as any,
    paddingTop: "30px",
    paddingBottom: "25px",
  },
  title: {
    fontSize: "30px",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  language: {
    fontSize: "16px",
    color: "black",
  },
};
