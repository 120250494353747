import { FC } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import { IContainer, IPackage, IBranch, ITenant, IOrder } from "interfaces";
import {
  calcContainerWeightInKilograms,
  calcOrdersTotalPriceWithDiscount,
  calcOrdersTotalWeight,
  groupSimilarOrders,
} from "../utils";
import { localizeCurrency } from "helpers";
import { convertGramsToKilo } from "helpers/weight";

interface Props {
  branch: IBranch;
  tenant: ITenant;
  container: IContainer | undefined;
  pkg: IPackage;
}

export const CN23: FC<Props> = props => {
  const { tenant, branch, container, pkg } = props;
  const { orders, clients } = pkg;

  const orderGroups = groupSimilarOrders(orders!);

  const calcOrdersGroupWeight = (orders: IOrder[]) => {
    const result = orders.reduce((total, current) => (total += current.weight), 0);
    return convertGramsToKilo(result);
  };

  const getOrdersTotalWeight = () => {
    if (container?.packages?.length === 1) {
      return calcContainerWeightInKilograms(container);
    } else {
      return calcOrdersTotalWeight(orders!);
    }
  };

  return (
    <Document title="CN23">
      <Page size="A5" orientation="landscape" style={styles.page}>
        <View style={styles.flexRow}>
          <View style={{ flex: 1 }}>
            <Text style={styles.boldText}>(Administration des postes)</Text>
            <View style={styles.table}>
              <View style={styles.row}>
                <View style={[styles.cell, { flex: 1 }]}>
                  <Text>De</Text>
                  <Text>Из</Text>
                </View>
                <View style={[styles.cell, { flex: 6 }]}>
                  <Text style={styles.boldText}>Nom et adresse de l`exediteur</Text>
                  <Text style={styles.boldText}>Фамилия и адрес отправителя</Text>
                </View>
                <View style={[styles.cell, { flex: 6 }]}>
                  <Text style={styles.boldText}>Reference del`expediteur (si elle existe)</Text>
                  <Text style={styles.boldText}>Ссылка отправителя (если она имеется)</Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 6, minHeight: 30 }]}>
                  <Text>{tenant.name}</Text>
                  <Text>{tenant.address}</Text>
                  <Text>{`${tenant.zipcode} ${tenant.city}`}</Text>
                  <Text>{`tel. ${tenant.phone}`}</Text>
                </View>
                <View style={[styles.cell, { flex: 6 }]} />
              </View>

              <View style={styles.row}>
                <View style={[styles.cell, { flex: 1 }]}>
                  <Text>A</Text>
                  <Text>B</Text>
                </View>
                <View style={[styles.cell, { flex: 12 }]}>
                  <Text style={styles.boldText}>
                    Nom et adresse du destinataire, y covhris lle pays dedestantion
                  </Text>
                  <Text style={styles.boldText}>Фамилия и адрес получателя, исключая страну назначения</Text>
                </View>
              </View>
              <View style={[styles.row, { borderBottomWidth: 0, minHeight: 30 }]}>
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 12 }]}>
                  <Text>{clients?.name ?? ""}</Text>
                  <Text>{clients?.address ?? ""}</Text>
                  <Text>{`${clients?.zipcode ?? ""} ${clients?.city ?? ""}`}</Text>
                  <Text> {`tel. ${clients?.phone ?? ""}`}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <View style={styles.declarationInfo}>
              <View style={styles.flexRow}>
                <View>
                  <Text style={styles.title}>DECLARATION EN DOUNE CN 23</Text>
                  <Text style={styles.title}>ТАМОЖЕННАЯ ДЕКЛАРАЦИЯ </Text>
                </View>
                <View>
                  <Text style={styles.boldText}>(ancien C 2 / CP 3)</Text>
                  <Text style={styles.boldText}>(прежний С 2/ СР 3)</Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  minHeight: 70,
                  justifyContent: "space-between",
                }}
              >
                <View style={{ marginTop: 5 }}>
                  <Text style={styles.boldText}>№ du/des colis (code a barres s`il existe)</Text>
                  <Text style={styles.boldText}>№ отправления/посылки (возможно штриховой код)</Text>
                </View>
                <View style={styles.packageInfo}>
                  <Text>{`Paket: ${pkg.package_nr}`}</Text>
                </View>
                <View>
                  <Text style={styles.boldText}>L`envoi/lu colis peut etre ouvert d`office</Text>
                  <Text style={styles.boldText}>
                    Отправление/посылка могут быть открыты в служебном порядке
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.flexRow, styles.table]}>
          <View style={{ flex: 1 }}>
            <View style={[styles.row, { height: 25 }]}>
              <View style={[styles.cell, { flex: 3, borderRightWidth: 0 }]}>
                <Text style={styles.boldText}>Designation du contenu (el nombre d`objects)</Text>
                <Text style={styles.boldText}>Описание вложения(и количество отправлений)</Text>
              </View>
              <View style={[styles.cell, { flex: 0.5 }]} />
              <View style={[styles.cell, { flex: 2 }]}>
                <Text style={styles.boldText}>Pay`s d`origine des marchandises</Text>
                <Text style={styles.boldText}>Страна подачи товаров</Text>
              </View>

              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.boldText}>№ tarifaire (siconnu)</Text>
                <Text style={styles.boldText}>№ тарифный (если он есть)</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.boldText}>Poids net (kg.)</Text>
                <Text style={styles.boldText}>Вес нетто (кг.)</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.boldText}>Valeur</Text>
                <Text style={styles.boldText}>Ценность</Text>
              </View>
            </View>
            {orderGroups.map(group => (
              <View style={[styles.row, { height: 25, borderBottomWidth: 0 }]}>
                <View style={[styles.cell, { flex: 3, borderRightWidth: 0 }]}>
                  <Text>{group.short_name ?? group.name}</Text>
                </View>

                <View style={[styles.cell, { flex: 0.5, alignItems: "center" }]}>
                  <Text>{group.items.length}</Text>
                </View>
                <View style={[styles.cell, { flex: 2 }]}>
                  <Text>Germany</Text>
                </View>
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 1 }]}>
                  <Text>{calcOrdersGroupWeight(group.items)}</Text>
                </View>
                <View style={[styles.cell, { flex: 1 }]}>
                  <Text>{localizeCurrency(+calcOrdersTotalPriceWithDiscount(group.items, branch.id))}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={[styles.flexRow, styles.table]}>
          <View style={{ flex: 1.5 }}>
            <View style={[styles.row, { height: 25 }]}>
              <View style={[styles.cell, styles.centeredView]}>
                <View style={[styles.flexRow, styles.centeredView]}>
                  <View style={styles.checkbox} />
                  <View>
                    <Text style={styles.boldText}>Echantilon commercial</Text>
                    <Text style={styles.boldText}>Коммерческий образец</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.cell, styles.centeredView]}>
                <View style={[styles.flexRow, styles.centeredView]}>
                  <View style={styles.checkbox} />
                  <View>
                    <Text style={styles.boldText}>Documents</Text>
                    <Text style={styles.boldText}>Документы</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.cell, styles.centeredView]}>
                <View style={[styles.flexRow, styles.centeredView]}>
                  <View style={styles.checkbox} />
                  <View>
                    <Text style={styles.boldText}>Cadeau</Text>
                    <Text style={styles.boldText}>Подарок</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.row, { flex: 1 }]}>
              <View style={{ flex: 1 }}>
                <View style={[styles.row, { flex: 1 }]}>
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text style={styles.boldText}>Observations</Text>
                    <Text style={styles.boldText}>Примечания</Text>
                  </View>
                  <View style={[styles.cell, { flex: 4 }]} />
                </View>
                <View style={[styles.row, { borderBottomWidth: 0 }]}>
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text>Lieferung:</Text>
                    <Text>Container: </Text>
                  </View>
                  <View style={[styles.cell, styles.containerInfo, { flex: 4 }]}>
                    <Text>{container?.container_nr}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={{ flex: 2 }}>
            <View style={[styles.row, { height: 25 }]}>
              <View style={[styles.cell, styles.centeredView, { flex: 3 }]}>
                <Text style={styles.boldText}>Nomre de cetrcertificats et actures</Text>
                <Text style={styles.boldText}>Количество сертификатов и счетов</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]}></View>
              <View style={[styles.cell, styles.centeredView, { flex: 3 }]}>
                <Text style={styles.boldText}>Bureau d`origine/ Date de depot</Text>
                <Text style={styles.boldText}>Учреждение подачи/ Дата подачи</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]}></View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 2 }]}>
                <Text style={styles.boldText}>Poids brut total (kg.)</Text>
                <Text style={styles.boldText}>Общий вес брутто (кг.)</Text>
              </View>
              <View style={[styles.cell, styles.centeredView, { flex: 1 }]}>
                <Text style={styles.boldText}>{getOrdersTotalWeight()}</Text>
              </View>
              <View style={[styles.cell, { flex: 2 }]}>
                <Text style={styles.boldText}>Montant:</Text>
                <Text style={styles.boldText}>Сумма:</Text>
              </View>
              <View style={[styles.cell, styles.centeredView, { flex: 1 }]}>
                <Text style={styles.boldText}>
                  {localizeCurrency(calcOrdersTotalPriceWithDiscount(orders!, branch.id))}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.boldText}>
                  Je certife gue les renseignements donnes dans la presente declarationen en doaunne sont
                  exacts et gue envoi ne contient aucun objet dangereux inerdit par la reglamentation postale
                </Text>
                <Text style={styles.boldText}>
                  Подтверждаю точность сведений в настоящей декларации, а также, что это отправление не
                  содержит никаких опасных предметов, запрещенных почтовой регламентацией.
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.boldText}>Date et signature de l`expediteur</Text>
                <Text style={styles.boldText}>Дата и подпись отправителя</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]}></View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 7,
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  title: {
    fontSize: 10,
    fontWeight: "bold",
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  table: {
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: "black",
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderColor: "black",
  },
  boldText: {
    fontWeight: "bold",
  },
  cell: {
    padding: 1,
    borderRightWidth: 0.5,
    borderColor: "black",
  },
  declarationInfo: {
    flex: 1,
    justifyContent: "space-between",
    paddingLeft: 3,
    paddingBottom: 3,
  },
  packageInfo: {
    fontSize: 11,
    fontWeight: "bold",
  },
  containerInfo: {
    fontSize: 10,
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
  },
  checkbox: {
    width: 16,
    height: 11,
    borderWidth: 1,
    borderColor: "black",
    marginRight: 10,
  },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
  },
});
