import "react-phone-number-input/style.css";
import { FC } from "react";
import { useTranslate } from "@pankod/refine-core";

import PhoneInput, { Value } from "react-phone-number-input";

interface Props {
  value?: Value;
  onChange?: (value?: Value) => void;
}

export const AppPhoneInput: FC<Props> = props => {
  const { value, onChange, ...rest } = props;
  const t = useTranslate();

  const triggerFormChange = (changedValue?: Value) => {
    onChange?.(changedValue);
  };

  return (
    <PhoneInput
      defaultCountry="DE"
      placeholder={t("clients.phone")}
      className="phone-input"
      value={value}
      onChange={value => triggerFormChange(value)}
      numberInputProps={{
        ...rest,
        style: {
          boxSizing: "border-box",
          borderLeftStyle: "solid",
          borderTopStyle: "solid",
          borderRadius: "6px",
          borderColor: "rgb(217, 217, 217)",
          borderWidth: "1px",
          minHeight: "32px",
          paddingInline: "11px",
        },
      }}
    />
  );
};
