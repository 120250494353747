import { IBranch, IOrder } from "interfaces";
import { OrderStatus } from "../constants";
import dayjs from "dayjs";

export const isOrderEditable = (order: IOrder, isAdmin: boolean) =>
  isAdmin ||
  order.order_status_id === OrderStatus.Received ||
  order.order_status_id === OrderStatus.Unpublished;

export const isOrderHasStorageInfo = (order: IOrder | undefined) => order && order.weight;

export const isUnableToCreateOrder = (user: IBranch, isAdmin: boolean) =>
  !isAdmin && user && dayjs().isAfter(user.next_balance_verification_date);

export const truncate = (str: string, n: number) => (str.length > n ? str.slice(0, n - 1) + "..." : str);
