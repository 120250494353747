import { Key, useCallback, useState } from "react";
import {
  Button,
  Card,
  Col,
  Icons,
  List,
  Modal,
  Row,
  Space,
  Typography,
  useModalForm,
  useTable,
} from "@pankod/refine-antd";
import { HttpError, useModal, useOne, useTranslate } from "@pankod/refine-core";

import { OrderFilter, OrderTable, createOnSearchFilter } from "components/orders";
import { OrderStatus } from "../../constants";
import { IOrderFilterVariables, IOrder, IOrderInvoiceInfo, ITenant } from "interfaces";
import { InvoiceInformation } from "./invoiceInformation";
import { ConfirmArrivalModal } from "./confirmArrivalModal";
import { ConfirmMultipleArrivalsModal } from "./confirmMultipleArrivalsModal";
import { OrderPdfLayout } from "documents/order";
import { FiltersButton } from "components/appFilterButton";
import { useCurrentUser } from "hooks";

export const ArrivalList = () => {
  const t = useTranslate();
  const [invoiceInfo, setInvoiceInfo] = useState<IOrderInvoiceInfo>();
  const [orderToReceive, setOrderToReceive] = useState<IOrder>();
  const [orderIdToFillPdf, setOrderIdToFillPdf] = useState<number>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<IOrder[]>([]);
  const { isAdmin, tenantId } = useCurrentUser();
  const hasSelected = selectedRowKeys.length > 0;

  const { data: tenant } = useOne<ITenant>({
    resource: "tenants",
    id: tenantId,
    queryOptions: { enabled: !!tenantId },
  });

  const {
    formProps: modalFormProps,
    modalProps,
    open,
    show,
  } = useModalForm({
    resource: "orders",
    action: "edit",
    redirect: false,
    onMutationSuccess: data => {
      setOrderIdToFillPdf(data.data.id as number);
      if (tenant?.data.has_storage_label) {
        showPdfModal();
      }
      setOrderToReceive(undefined);
    },
  });

  const {
    formProps: multipleOrdersFormProps,
    modalProps: multipleOrdersModalProps,
    open: multipleOrdersOpen,
    show: multipleOrdersShow,
    close: closeEditMultipleOrders,
  } = useModalForm({
    resource: "orders",
    action: "edit",
    redirect: false,
  });

  const { show: showPdfModal, visible: visiblePdfModal, close: closePdfModal } = useModal();

  const { tableProps, sorter, searchFormProps, filters } = useTable<IOrder, HttpError, IOrderFilterVariables>(
    {
      resource: "orders",
      onSearch: params => createOnSearchFilter(params),
      hasPagination: true,
      initialSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      permanentFilter: [
        {
          field: "order_status_id",
          operator: "eq",
          value: OrderStatus.Ordered,
        },
      ],
      // A trick to show empty table on page mount.
      // https://refine.dev/docs/api-reference/antd/hooks/table/useTable/#initialfilter
      defaultSetFilterBehavior: "replace",
      initialFilter: [
        {
          field: "shop_id",
          operator: "eq",
          value: 0,
        },
      ],
    }
  );

  const handleConfirmOrderArrival = useCallback(
    (record: IOrder) => {
      setOrderToReceive(record);
      show(record.id);
    },
    [show]
  );

  const renderConfirmArrivalAction = useCallback(
    (record: IOrder) => (
      <Button type="primary" onClick={() => handleConfirmOrderArrival(record)}>
        {t("buttons.receive")}
      </Button>
    ),
    [handleConfirmOrderArrival, t]
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: Key[], selectedRows: IOrder[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const areAllOrderedPricesEqual = () => {
    if (selectedRows.length) {
      const firstPrice = selectedRows[0].ordered_price;
      return selectedRows.every(item => item.ordered_price === firstPrice);
    }

    return false;
  };

  const clearSelectedRows = useCallback(() => setSelectedRowKeys([]), []);

  return (
    <Row gutter={[16, 16]}>
      <Col md={7} xl={7} lg={7} xs={7}>
        <Card bordered={false} style={{ display: "flex", height: "70vh" }}>
          <InvoiceInformation
            formProps={searchFormProps}
            filters={filters || []}
            setInvoiceData={data => setInvoiceInfo(data)}
          />
        </Card>
      </Col>
      <Col md={7} xl={17} lg={17} xs={17}>
        <List
          resource="orders"
          title={false}
          headerProps={{
            subTitle: hasSelected && (
              <Space style={{ gap: 0, marginLeft: "1em" }}>
                <Button
                  type="primary"
                  disabled={!areAllOrderedPricesEqual()}
                  onClick={() => multipleOrdersShow()}
                >
                  {t("buttons.receiveSelectedOrders")}
                </Button>
                {!areAllOrderedPricesEqual() && (
                  <div>
                    <Icons.ExclamationCircleOutlined style={{ color: "#D39230", marginRight: "5px" }} />
                    <Typography.Text>{t("arrival.receiveOrdersHint")}</Typography.Text>
                  </div>
                )}
              </Space>
            ),
          }}
          headerButtons={() => (
            <FiltersButton
              content={
                <OrderFilter
                  isAdmin={isAdmin}
                  formProps={searchFormProps}
                  filters={filters || []}
                  fieldsConfig={{
                    hiddenShop: true,
                    hiddenStatus: true,
                    hiddenArrivalPrice: true,
                    hiddenCountryField: true,
                  }}
                />
              }
            />
          )}
        >
          <OrderTable
            tableProps={tableProps}
            sorter={sorter}
            columnConfig={{
              hiddenStatus: true,
              hiddenShop: true,
              hiddenUpdatedAt: true,
              hiddenStoragePlace: true,
              hiddenArrivalPrice: true,
              hiddenCustomsPrice: true,
              hiddenWeight: true,
              hiddenCountry: true,
            }}
            enableActions
            customAction={renderConfirmArrivalAction}
            emptyText={!invoiceInfo ? t("arrival.emptyOrderInfo") : undefined}
            /** Allows multiple row selection if the user doesn't use storage label docs. */
            rowSelection={!tenant?.data.has_storage_label ? rowSelection : undefined}
          />
        </List>
      </Col>
      {open && invoiceInfo && orderToReceive && (
        <ConfirmArrivalModal
          modalProps={modalProps}
          formProps={modalFormProps}
          initialValues={{ ...invoiceInfo, order_status_id: OrderStatus.InStock }}
          order={orderToReceive}
        />
      )}
      {multipleOrdersOpen && selectedRows.length >= 1 && (
        <ConfirmMultipleArrivalsModal
          modalProps={multipleOrdersModalProps}
          formProps={multipleOrdersFormProps}
          initialValues={{ ...invoiceInfo, order_status_id: OrderStatus.InStock }}
          orders={selectedRows}
          close={closeEditMultipleOrders}
          clearSelectedRows={clearSelectedRows}
        />
      )}
      {visiblePdfModal && (
        <Modal open={visiblePdfModal} onCancel={closePdfModal} width="80%" footer={null}>
          <OrderPdfLayout orderId={orderIdToFillPdf!} />
        </Modal>
      )}
    </Row>
  );
};
