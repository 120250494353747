import { Dispatch, FC, Key, SetStateAction, useState } from "react";
import {
  Table,
  TextField,
  UrlField,
  ImageField,
  BooleanField,
  DeleteButton,
  TableProps,
  Button,
} from "@pankod/refine-antd";
import { t } from "i18next";

import { EditIcon, DeleteIcon } from "assets";
import { IShop } from "interfaces";
import { AppEmpty } from "components/appEmpty";

interface Props {
  tableProps: TableProps<IShop>;
  setSelectedRows?: Dispatch<SetStateAction<IShop[]>>;
  enableActions?: boolean;
  enableSelection?: boolean;
  onRecordEdit?: (id: number) => void;
  selectorId?: string;
}

export const ShopsTable: FC<Props> = ({
  tableProps,
  enableActions,
  setSelectedRows,
  enableSelection = false,
  onRecordEdit,
  selectorId,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();

  const rowSelection = {
    selectedRowKeys,

    onChange: (selectedRowKeys: Key[], selectedRows: IShop[]) => {
      setSelectedRowKeys(selectedRowKeys);
      if (!!setSelectedRows) setSelectedRows(selectedRows);
    },
  };

  return (
    <Table
      {...tableProps}
      id={selectorId}
      rowKey="id"
      size="small"
      rowClassName="app-table-row"
      rowSelection={enableSelection ? rowSelection : undefined}
      locale={{
        emptyText: (
          <AppEmpty
            description={
              <span style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>{t("table.empty")}</span>
            }
          />
        ),
      }}
    >
      <Table.Column
        key="name"
        dataIndex="name"
        title={t("shops.fields.name")}
        render={value => <TextField value={value} />}
        sorter
      />
      <Table.Column
        key="logo"
        dataIndex="logo"
        title={t("shops.fields.logo")}
        render={value => <ImageField value={value} style={{ maxWidth: 80, maxHeight: 40 }} preview={false} />}
      />
      <Table.Column
        key="is_active"
        dataIndex="is_active"
        title={t("shops.fields.isActive")}
        render={value => <BooleanField value={value} />}
      />
      <Table.Column
        key="link"
        dataIndex="link"
        title={t("shops.fields.link")}
        render={value => <UrlField value={value} target="_blank" />}
      />
      <Table.Column
        key="description"
        dataIndex="description"
        title={t("shops.fields.description")}
        render={value => <TextField value={value} />}
        sorter
      />
      {enableActions && (
        <Table.Column<IShop>
          fixed="right"
          title={t("table.actions")}
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_, record) => (
            <>
              <Button
                icon={<EditIcon />}
                style={{ borderColor: "transparent", background: "transparent" }}
                onClick={() => {
                  onRecordEdit!(record.id);
                }}
              />
              <DeleteButton
                hideText
                recordItemId={record.id}
                icon={<DeleteIcon />}
                style={{ borderColor: "transparent", background: "transparent" }}
              />
            </>
          )}
        />
      )}
    </Table>
  );
};
