import React, { FC } from "react";
import { useGetLocale, useTranslate } from "@pankod/refine-core";
import { Form, FormProps, Input, Select } from "@pankod/refine-antd";
import { AppPhoneInput } from "../appPhoneInput";
import { countries } from "helpers/countries";

interface Props {
  formProps: FormProps<{}>;
  initialValues?: {};
}

export const CreateEditForm: FC<Props> = props => {
  const { formProps, initialValues = false } = props;
  const t = useTranslate();
  const locale = useGetLocale();
  const options = countries(locale() ?? "en");

  return (
    <Form
      {...formProps}
      initialValues={{
        isActive: true,
        ...formProps.initialValues,
        ...initialValues,
      }}
      layout="vertical"
    >
      <Form.Item label={t("branches.fields.name")} name="name" rules={[{ required: true }]}>
        <Input placeholder={t("branches.fields.name")} />
      </Form.Item>
      <Form.Item label={t("clients.phone")} name="phone" rules={[{ required: true }]}>
        <AppPhoneInput />
      </Form.Item>
      <Form.Item label={t("orders.filter.country.label")} name="country" rules={[{ required: true }]}>
        <Select options={options} placeholder={t("orders.filter.country.placeholder")} allowClear />
      </Form.Item>
      <Form.Item name="address" label={t("branches.fields.address")} rules={[{ required: true }]}>
        <Input placeholder={t("branches.fields.address")} />
      </Form.Item>
      <Form.Item name="city" label={t("branches.fields.city")} rules={[{ required: true }]}>
        <Input placeholder={t("branches.fields.city")} />
      </Form.Item>
      <Form.Item name="zipcode" label={t("branches.fields.zipcode")} rules={[{ required: true }]}>
        <Input placeholder={t("branches.fields.zipcode")} />
      </Form.Item>
      <Form.Item label={t("branches.fields.email")} name="email" rules={[{ type: "email" }]}>
        <Input type="email" />
      </Form.Item>
      <Form.Item label={t("branches.fields.passportNr.label")} name="passport_nr">
        <Input placeholder={t("branches.fields.passportNr.placeholder")} />
      </Form.Item>
      <Form.Item name="branch_id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="is_active" hidden>
        <Input />
      </Form.Item>
    </Form>
  );
};
