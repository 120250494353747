import { FC } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";

import { IDelivery, IContainer } from "interfaces";
import { calcContainerWeightInKilograms, calcTotalWeightOfContainersInKilograms } from "../utils";

interface Props {
  delivery: IDelivery;
}

export const CN38: FC<Props> = props => {
  const { delivery } = props;
  const { postal_operators, airdepartment_nr, departure_nr, flight_nr, shipment_date, containers, tenants } =
    delivery;
  const grid: IContainer[] = [
    ...(containers || []),
    ...(Array.apply(null, new Array(14 - (containers?.length ?? 0))) as any),
  ];

  const calcAmountOfPackages = () =>
    grid.reduce((total, current) => {
      if (current) {
        return (total += current.packages?.length ?? 0);
      }
      return total;
    }, 0);

  return (
    <Document title="CN 38">
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={styles.idents}>
          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Administration des postes d' origone</Text>
              <Text style={{ marginBottom: 10 }}>Почтовая администрация подачи</Text>
              <Text>Enterprise des Postes allemendes</Text>
              <Text style={styles.boldText}>{tenants?.name}</Text>
              <Text style={styles.boldText}>{tenants?.address}</Text>
              <Text style={styles.boldText}>{`${tenants?.zipcode} ${tenants?.city}`}</Text>
              <Text style={{ marginBottom: 10 }}>Bureau d'originale du bordereau</Text>
              <Text>Bureau de destination de bordereau</Text>
              <Text>Учреждение назначения накладной</Text>
              <Text style={styles.boldText}>{postal_operators?.name}</Text>
              <Text
                style={styles.boldText}
              >{`${postal_operators?.address} ${postal_operators?.city} ${postal_operators?.zipcode}`}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <View style={[styles.flexRow, { justifyContent: "space-between" }]}>
                <Text style={styles.title}>BORDEREAU DE LIVRASION</Text>
                <Text style={styles.title}>CN 38</Text>
              </View>
              <Text style={styles.title}>НАКЛАДНАЯ СДАЧИ</Text>
              <Text>Depeche-avion</Text>
              <View style={[styles.flexRow, { marginBottom: 10 }]}>
                <Text>Авиадепеши</Text>
                <Text style={[styles.boldText, { marginLeft: 50 }]}>{`№ ${airdepartment_nr ?? ""}`}</Text>
              </View>
              <View style={[styles.flexRow, { marginBottom: 10 }]}>
                <View style={{ flex: 1 }}>
                  <Text>№ de serie</Text>
                </View>
              </View>
              <View style={[styles.flexRow, { marginBottom: 10 }]}>
                <View style={{ flex: 1 }}>
                  <Text>№ указаный в отправлении</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldText}>{departure_nr ?? ""}</Text>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={{ flex: 1 }}>
                  <View style={[styles.flexRow, { alignItems: "center" }]}>
                    <View style={[styles.checkbox, styles.centered]}>
                      <Text>X</Text>
                    </View>
                    <View>
                      <Text>Prioritaire</Text>
                      <Text>Приорететная</Text>
                    </View>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={[styles.flexRow, { alignItems: "center" }]}>
                    <View style={[styles.checkbox, styles.centered]}>
                      <Text>X</Text>
                    </View>
                    <View>
                      <Text>Par avion</Text>
                      <Text>Авиа</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.table, { marginTop: 10 }]}>
            <View style={[styles.row, { padding: 1 }]}>
              <View style={[styles.flexRow, { flex: 1, alignItems: "center" }]}>
                <View>
                  <Text>Ligue n'</Text>
                  <Text>№ рейса</Text>
                </View>
                <View>
                  <Text style={[styles.boldText, { marginLeft: 50 }]}>{flight_nr ?? ""}</Text>
                </View>
              </View>
              <View style={[styles.flexRow, { flex: 1 }]}>
                <View style={[styles.flexRow, { flex: 1, alignItems: "center" }]}>
                  <View>
                    <Text>Data departa</Text>
                    <Text>Дата отправки</Text>
                  </View>
                  <View>
                    <Text style={[styles.boldText, { marginLeft: 25 }]}>
                      {shipment_date ? dayjs(shipment_date).format("L") : ""}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View>
                    <Text>Heure</Text>
                    <Text>время</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.row, { padding: 1 }]}>
              <View style={{ flex: 1 }}>
                <Text>Aeroport de trausbordement direct</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text>Aeroport de dechargement</Text>
              </View>
            </View>
            <View style={[styles.row, { padding: 1, marginBottom: 3 }]}>
              <View style={{ flex: 1 }}>
                <Text>Аэропорт прямой перегрузки</Text>
              </View>
              <View style={[styles.flexRow, { flex: 1 }]}>
                <View style={{ flex: 1 }}>
                  <Text>Аэропорт выгрузки</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldText}>{postal_operators?.airport_unloading}</Text>
                </View>
              </View>
            </View>
            <View>
              <Text>En cas d'utilisation de conteneur</Text>
              <Text>В случае использования контейнира</Text>
            </View>
            <View style={[styles.row, { padding: 1 }]}>
              <View style={{ flex: 1 }}>
                <Text style={styles.boldText}>№ du conteneur</Text>
                <Text style={styles.boldText}>№ контейнера</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.boldText}>№ du scelle</Text>
                <Text style={styles.boldText}>№ печати</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.boldText}>№ du conteneur</Text>
                <Text style={styles.boldText}>№ контейнера</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.boldText}>№ du scelle</Text>
                <Text style={styles.boldText}>№ печати</Text>
              </View>
            </View>
            <View style={[styles.row, { padding: 1 }]}>
              <View style={{ flex: 1 }}>
                <Text>№ conteneur</Text>
                <Text>№ контейнера</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text>№ du scelle</Text>
                <Text>№ печати</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text>№ conteneur</Text>
                <Text>№ контейнера</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text>№ du scelle</Text>
                <Text>№ печати</Text>
              </View>
            </View>
          </View>
          <View style={{ marginVertical: 5 }}>
            <Text style={styles.boldText}>Inscription</Text>
            <Text style={styles.boldText}>Запись</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <View style={styles.tableHeader}>
                  <Text>Depeche №</Text>
                  <Text>Депеша №</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text>1</Text>
                </View>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <View style={styles.tableHeader}>
                  <Text>Burcau</Text>
                  <Text>d'origine</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text>2</Text>
                </View>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <View style={styles.tableHeader}>
                  <Text>burcau de</Text>
                  <Text>Destination</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text>3</Text>
                </View>
              </View>
              <View style={styles.cell}>
                <View style={[{ height: 95.5 }]}>
                  <View style={{ paddingLeft: 1 }}>
                    <Text>Nombre de</Text>
                    <Text>Количество</Text>
                  </View>
                  <View style={[styles.flexRow, { flex: 1 }]}>
                    <View style={[styles.cell, { justifyContent: "space-between" }]}>
                      <View>
                        <Text>recipients poste</Text>
                        <Text>aux lettres</Text>
                        <Text>мешков с пись-</Text>
                        <Text>менной коррес-</Text>
                        <Text>понденцией</Text>
                      </View>
                      <View style={styles.cellNumber}>
                        <Text>4</Text>
                      </View>
                    </View>
                    <View style={[styles.cell, { justifyContent: "space-between" }]}>
                      <View>
                        <Text>recipients</Text>
                        <Text>CP etcolls</Text>
                        <Text>hors</Text>
                        <Text>recipient</Text>
                        <Text>мешков</Text>
                        <Text>CP и</Text>
                        <Text>посылок</Text>
                        <Text>без мешка</Text>
                      </View>
                      <View style={styles.cellNumber}>
                        <Text>5</Text>
                      </View>
                    </View>
                    <View style={{ justifyContent: "space-between" }}>
                      <View>
                        <Text>recipients</Text>
                        <Text>EMS</Text>
                        <Text>мешков</Text>
                        <Text>EMS</Text>
                      </View>
                      <View style={styles.cellNumber}>
                        <Text>6</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.cell}>
                <View style={[{ height: 95.5 }]}>
                  <View>
                    <Text>Poids brut des recipients,etc.</Text>
                    <Text>Вес брутто мешков и т.д</Text>
                  </View>
                  <View style={[styles.flexRow, { flex: 1 }]}>
                    <View style={[styles.cell, { flex: 2, padding: 0, justifyContent: "space-between" }]}>
                      <View style={{ padding: 1 }}>
                        <Text>Poste aux</Text>
                        <Text>letters</Text>
                        <Text>Письменная</Text>
                        <Text>корреспон-</Text>
                        <Text>денция</Text>
                      </View>
                      <View style={styles.cellNumber}>
                        <Text>7</Text>
                      </View>
                    </View>
                    <View style={[styles.cell, { flex: 1, justifyContent: "space-between" }]}>
                      <View>
                        <Text>CP</Text>
                        <Text>CP</Text>
                      </View>
                      <View style={styles.cellNumber}>
                        <Text>8</Text>
                      </View>
                    </View>
                    <View style={{ flex: 1, justifyContent: "space-between" }}>
                      <View>
                        <Text>EMS</Text>
                        <Text>EMS</Text>
                      </View>
                      <View style={styles.cellNumber}>
                        <Text>9</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={[styles.cell, { borderRightWidth: 0 }]}>
                <View style={styles.tableHeader}>
                  <Text>Observations</Text>
                  <Text>(ycomprislenombre dessacs</Text>
                  <Text>M</Text>
                  <Text>et/ou des colis horsrecipient)</Text>
                  <Text>Примечание</Text>
                  <Text>(включая количество М </Text>
                  <Text>и/или посылок без мешка)</Text>
                </View>
                <View style={styles.cellNumber}>
                  <Text>10</Text>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, styles.boldText, { width: 60, justifyContent: "center" }]}>
                <Text>{`Dep № ${airdepartment_nr ?? ""}`}</Text>
              </View>
              <View style={[styles.cell, { width: 60.5, alignItems: "center" }]}>
                <Text>{postal_operators?.departure_point}</Text>
              </View>
              <View style={[styles.cell, { width: 60, alignItems: "center" }]}>
                <Text>{postal_operators?.destination_point}</Text>
              </View>
              <View style={[styles.cell, { width: 52.5 }]}></View>
              <View style={[styles.cell, { width: 35.5 }]}></View>
              <View style={[styles.cell, { width: 31 }]}></View>
              <View style={[styles.cell, { width: 45, alignItems: "center" }]}>
                <Text>kg</Text>
                <Text>кг</Text>
              </View>
              <View style={[styles.cell, { width: 22.5, alignItems: "center" }]}>
                <Text>kg</Text>
                <Text>кг</Text>
              </View>
              <View style={[styles.cell, { width: 23, alignItems: "center" }]}>
                <Text>kg</Text>
                <Text>кг</Text>
              </View>
              <View style={[styles.cell, { width: 93, borderRightWidth: 0 }]}></View>
            </View>
            {grid.map((row, index) => (
              <View key={index} style={styles.row}>
                <View style={[styles.cell, styles.centered, styles.containerNr]}>
                  {row && (
                    <View style={styles.rowNr}>
                      <Text>{index + 1}</Text>
                    </View>
                  )}
                  <Text>{row?.container_nr}</Text>
                </View>
                <View style={[styles.cell, { width: 60.5 }]}></View>
                <View style={[styles.cell, { width: 60 }]}></View>
                <View style={[styles.cell, { width: 52.5 }]}></View>
                <View style={[styles.cell, styles.centered, { width: 35.5 }]}>
                  <Text>{row?.packages ? row.packages.length : ""}</Text>
                </View>
                <View style={[styles.cell, { width: 31 }]}></View>
                <View style={[styles.cell, { width: 45 }]}></View>
                <View style={[styles.cell, styles.centered, { width: 22.5 }]}>
                  <Text>{row ? calcContainerWeightInKilograms(row) : ""}</Text>
                </View>
                <View style={[styles.cell, { width: 23 }]}></View>
                <View style={[styles.cell, { width: 93, borderRightWidth: 0 }]}></View>
              </View>
            ))}
            <View style={styles.row}>
              <View style={[styles.cell, { width: 60 }]}>
                <Text>Totaux</Text>
                <Text>Итого</Text>
              </View>
              <View style={[styles.cell, { width: 60.5 }]}></View>
              <View style={[styles.cell, { width: 60 }]}></View>
              <View style={[styles.cell, { width: 52.5 }]}></View>
              <View style={[styles.cell, styles.centered, { width: 35.5 }]}>
                <Text>{calcAmountOfPackages()}</Text>
              </View>
              <View style={[styles.cell, { width: 31 }]}></View>
              <View style={[styles.cell, { width: 45 }]}></View>
              <View style={[styles.cell, styles.centered, { width: 22.5 }]}>
                <Text>{calcTotalWeightOfContainersInKilograms(grid)}</Text>
              </View>
              <View style={[styles.cell, { width: 23 }]}></View>
              <View style={[styles.cell, { width: 93, borderRightWidth: 0 }]}></View>
            </View>
          </View>
          <View style={[styles.flexRow, { marginBottom: 15 }]}>
            <View style={{ flex: 2 }}>
              <Text>Bureau d'echange expediteur</Text>
              <Text>Отправляющие учереждение обмена</Text>
            </View>
            <View style={{ flex: 3 }}>
              <Text>L'agent du transporteur / l'agent de l'aeroport</Text>
              <Text>Служащий транспортной компании / аэропорта</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text>Breau d'echange de destination</Text>
              <Text>Учереждение обмена назначения</Text>
            </View>
          </View>
          <View style={[styles.flexRow, { marginBottom: 30 }]}>
            <View style={{ flex: 2 }}>
              <Text>Signature</Text>
              <Text>Подпись</Text>
            </View>
            <View style={{ flex: 3 }}>
              <Text>Date et signature</Text>
              <Text>Дата и подпись</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text>Date es signature</Text>
              <Text>Дата и подпись</Text>
            </View>
          </View>
          <View style={styles.flexRow}>
            <View style={{ flex: 2 }}>
              <View style={[styles.signature, { width: 110 }]} />
            </View>
            <View style={{ flex: 3 }}>
              <View style={[styles.signature, { width: 160 }]} />
            </View>
            <View style={{ flex: 2 }}>
              <View style={[styles.signature, { width: 110 }]} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 7,
  },
  idents: {
    flex: 1,
    margin: "56",
  },
  flexRow: {
    flexDirection: "row",
  },
  title: {
    fontSize: 10,
    fontWeight: "bold",
    paddingBottom: 2,
  },
  boldText: {
    fontWeight: "bold",
  },
  checkbox: {
    width: 16,
    height: 11,
    borderWidth: 1,
    borderColor: "black",
    marginRight: 2,
    alignItems: "center",
  },
  table: {
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: "black",
  },
  tableHeader: {
    height: 87,
    padding: 1,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderColor: "black",
  },
  cell: {
    borderRightWidth: 0.5,
    borderColor: "black",
    minHeight: 10,
  },
  centered: {
    alignItems: "center",
    justifyContent: "center",
  },
  cellNumber: {
    borderTopWidth: 0.5,
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
  },
  signature: {
    height: 1,
    backgroundColor: "black",
  },
  containerNr: {
    width: 60,
    position: "relative",
  },
  rowNr: {
    position: "absolute",
    left: -10,
  },
});
