import { FC, useState } from "react";
import { Modal, useTable } from "@pankod/refine-antd";
import { useCreateMany, useModalReturnType, useTranslate } from "@pankod/refine-core";

import { IShop, IBranchShops } from "interfaces";
import { ShopsTable } from "../shops";

interface Props extends useModalReturnType {
  branchId: string;
  refetch: () => void;
}

export const AddBranchShops: FC<Props> = ({ visible, close, branchId, refetch }) => {
  const t = useTranslate();

  const [selectedRows, setSelectedRows] = useState<IShop[]>([]);

  const { tableProps } = useTable<IShop>({
    resource: "available_branch_shops",
    permanentFilter: [{ field: "branch_id", operator: "eq", value: branchId }],
  });

  const { mutate } = useCreateMany<IBranchShops>();
  const hasSelected = selectedRows.length > 0;

  const handleOk = () => {
    if (hasSelected) {
      mutate(
        {
          resource: "branch_shops",
          values: selectedRows.map(shop => ({
            branch_id: branchId,
            shop_id: shop.id,
          })),
        },
        {
          onSuccess: () => {
            refetch();
            close();
          },
        }
      );
    } else {
      close();
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={close}
      width="70%"
      title={t("shops.titles.add")}
      onOk={handleOk}
      okButtonProps={{
        disabled: !hasSelected,
      }}
    >
      <ShopsTable
        tableProps={tableProps}
        enableActions={false}
        enableSelection={true}
        setSelectedRows={setSelectedRows}
        selectorId="whiteTable"
      />
    </Modal>
  );
};
