import { useCurrentUser } from "hooks";
import { supabaseClient } from "config";
import { useState, useEffect } from "react";
import { avatarPath } from "./storagePaths";

const { REACT_APP_ENVIRONMENT } = process.env;

export const useAvatar = (userId: string) => {
  const [avatar, setAvatar] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { tenantId } = useCurrentUser();

  useEffect(() => {
    async function fetchAvatar() {
      setLoading(true);
      setError(null);
      try {
        const { data, error } = await supabaseClient.storage
          .from(REACT_APP_ENVIRONMENT!)
          .download(avatarPath(userId, tenantId));

        if (data) {
          setAvatar(URL.createObjectURL(data));
        }
        if (error) {
          setError(error);
        }
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    if (userId) {
      fetchAvatar();
    }
  }, [tenantId, userId]);

  return { avatar, loading, error };
};
