import { Button, ButtonProps } from "@pankod/refine-antd";
import { AddIcon } from "assets";
import { FC } from "react";

export const AppCreateButton: FC<ButtonProps> = props => (
  <Button
    type="primary"
    icon={<AddIcon fill="white" style={{ marginRight: "7px" }} />}
    style={{ display: "flex", alignItems: "center" }}
    {...props}
  />
);
