import { FC, ChangeEvent, MouseEvent, useRef, useState } from "react";
import { useCreate, useTranslate } from "@pankod/refine-core";
import {
  Form,
  getValueFromEvent,
  Upload,
  Button,
  Icons,
  Input,
  Radio,
  ImageField,
  Select,
  Divider,
  Space,
  FormProps,
  useSelect,
} from "@pankod/refine-antd";

import { ICategory } from "interfaces";
import { DEFAULT_SHOP_CATEGORY_ID } from "../../constants";

interface Props {
  formProps: FormProps<{}>;
  isEdit: boolean;
  setShopCategories: (categories: number[]) => void;
  tenantId: number | undefined;
}

export const CreateEditForm: FC<Props> = props => {
  const { formProps, isEdit = false, setShopCategories, tenantId } = props;
  const t = useTranslate();
  const inputRef = useRef<any>(null);
  const { mutate } = useCreate<ICategory>();

  const [categoryName, setCategoryName] = useState("");

  const { selectProps: selectCategoryProps } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "is_default",
        operator: "ne",
        value: true,
      },
    ],
  });

  const handleCreateCategory = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();

    mutate({
      resource: "categories",
      values: {
        name: categoryName,
        tenant_id: tenantId,
      },
    });

    setCategoryName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCategoryName(event.target.value);
  };

  const handleSubmit = (values: any) => {
    const { logoUpload, ...rest } = values;

    const request = {
      ...rest,
      ...(logoUpload?.length ? { logo: logoUpload[0]?.thumbUrl } : {}),
    };

    const { tenant_shop_categories, ...shopData } = request;

    if (tenant_shop_categories) {
      setShopCategories(tenant_shop_categories);
    }
    formProps.onFinish!(shopData);
  };

  const formInitialValues = {
    isActive: !isEdit,
    ...formProps.initialValues,
    tenant_shop_categories: formProps.initialValues?.tenant_shop_categories
      ? formProps.initialValues?.tenant_shop_categories.reduce(
          (total: number[], current: { category_id: number }) => {
            if (current.category_id !== DEFAULT_SHOP_CATEGORY_ID) {
              return [...total, current.category_id];
            }
            return total;
          },
          []
        )
      : [],
  };

  return (
    <Form {...formProps} layout="vertical" initialValues={formInitialValues} onFinish={handleSubmit}>
      <Form.Item label={t("shops.fields.logo")}>
        {isEdit && (
          <Form.Item name="logo">
            <ImageField value={formProps?.initialValues?.logo} />
          </Form.Item>
        )}
        <Form.Item name="logoUpload" valuePropName="fileList" getValueFromEvent={getValueFromEvent} noStyle>
          <Upload listType="picture" maxCount={1} accept="image/*" beforeUpload={() => false}>
            <Button icon={<Icons.UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      </Form.Item>
      <Form.Item label={t("shops.fields.name")} name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t("shops.fields.description")} name="description">
        <Input />
      </Form.Item>
      <Form.Item label={t("shops.fields.link")} name="link" rules={[{ required: true, type: "url" }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t("shops.fields.categories")} name="tenant_shop_categories">
        <Select
          {...selectCategoryProps}
          placeholder={t("shops.placeholder.categories")}
          mode="multiple"
          dropdownRender={menu => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Input
                  placeholder={t("shops.placeholder.createCategory")}
                  ref={inputRef}
                  value={categoryName}
                  onChange={onNameChange}
                />
                <Button onClick={handleCreateCategory}>{t("buttons.create")}</Button>
              </Space>
            </>
          )}
        />
      </Form.Item>
      <Form.Item label={t("shops.fields.isActive")} name="is_active" rules={[{ required: true }]}>
        <Radio.Group>
          <Radio value={true}>{t("shops.status.enabled")}</Radio>
          <Radio value={false}>{t("shops.status.disabled")}</Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};
