import { FC } from "react";
import { Button, Col, Form, FormProps, Input, Row, Select, useSelect, DatePicker } from "@pankod/refine-antd";
import { CrudFilters, getDefaultFilter, useTranslate } from "@pankod/refine-core";

import { IOrderInvoiceInfo, IShop } from "interfaces";
import { Colors } from "theme";

interface Props {
  formProps: FormProps;
  filters: CrudFilters;
  setInvoiceData: (data?: IOrderInvoiceInfo) => void;
}

type FormData = {
  shop_invoice_nr: string;
  shop_invoice_date: Date;
  shop_id: number;
};

export const InvoiceInformation: FC<Props> = props => {
  const { formProps, filters, setInvoiceData } = props;
  const t = useTranslate();

  const { selectProps: shopSelectProps } = useSelect<IShop>({
    resource: "shops",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: getDefaultFilter("shop_id", filters),
  });

  const handleSubmit = (values: FormData) => {
    const { shop_invoice_nr, shop_invoice_date } = values;
    setInvoiceData({ shop_invoice_nr, shop_invoice_date });

    formProps.onFinish!(values);
  };

  const handleReset = () => {
    formProps.form?.resetFields();
    setInvoiceData(undefined);
    const formData: FormData = formProps.form?.getFieldsValue();
    // Apply non-exist filter to clean up table.
    formProps.onFinish!({ ...formData, shop_id: 0 });
  };

  return (
    <Form layout="vertical" {...formProps} onFinish={handleSubmit} style={styles.form}>
      <Row>
        <h2 style={styles.title}>{t("arrival.info")}</h2>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item
            label={t("arrival.additionalInfo.invoiceNumber.label")}
            name="shop_invoice_nr"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("arrival.additionalInfo.invoiceNumber.placeholder")} allowClear />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item
            label={t("arrival.additionalInfo.invoiceDate.label")}
            name="shop_invoice_date"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              allowClear
              placeholder={t("arrival.additionalInfo.invoiceDate.placeholder")}
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item
            label={t("arrival.additionalInfo.shop.label")}
            name="shop_id"
            rules={[{ required: true }]}
          >
            <Select
              {...shopSelectProps}
              allowClear
              placeholder={t("arrival.additionalInfo.shop.placeholder")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" align="bottom" style={styles.buttons}>
        <Col>
          <Button onClick={handleReset}>{t("buttons.reset")}</Button>
        </Col>
        <Col>
          <Form.Item noStyle>
            <Button htmlType="submit" type="primary">
              {t("buttons.apply")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const styles = {
  form: { height: "100%", display: "flex", flexDirection: "column" as any, justifyContent: "space-between" },
  title: { width: "100%", textAlign: "center" as any, fontWeight: "700" },
  buttons: { width: "100%", borderTop: `1px solid ${Colors.MintHover}`, paddingTop: "20px" },
};
