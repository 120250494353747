import { FC, useMemo } from "react";
import {
  ButtonProps,
  Drawer,
  DrawerProps,
  FormProps,
  Grid,
  Form,
  Input,
  Select,
  useSelect,
  Icons,
  Typography,
  Edit,
} from "@pankod/refine-antd";
import { useTranslate, useUpdateMany } from "@pankod/refine-core";

import { IClient, IOrder, IOrderStatus } from "interfaces";
import { OrderStatus } from "../../constants";

interface EditOrdersForm {
  short_name?: string;
  order_status_id?: number;
  description?: string;
}

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  close: () => void;
  selectedRows: IOrder[];
  clearSelectedRows: () => void;
}

export const EditMultipleOrders: FC<Props> = props => {
  const { drawerProps, formProps, saveButtonProps, close, selectedRows, clearSelectedRows } = props;
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();
  const { mutate: updateOrders, isLoading } = useUpdateMany();

  const branchIds = useMemo(
    () =>
      selectedRows.reduce(
        (acc: string[], curr) => (acc.includes(curr.branch_id) ? acc : [...acc, curr.branch_id]),
        []
      ),
    [selectedRows]
  );

  const allArticlesAreEqual = useMemo(
    () => selectedRows.every(val => val.article_number === selectedRows[0].article_number),
    [selectedRows]
  );

  const allBranchesAreEqual = useMemo(
    () => selectedRows.every(val => val.branch_id === selectedRows[0].branch_id),
    [selectedRows]
  );

  const ordersHaveInStockStatus = useMemo(
    () => selectedRows.some(val => val.order_status_id === OrderStatus.InStock),
    [selectedRows]
  );

  const { selectProps: orderStatusSelectProps } = useSelect<IOrderStatus>({
    resource: "order_statuses",
    optionLabel: "value",
    optionValue: "id",
    fetchSize: 30,
  });

  const { selectProps: clientSelectProps } = useSelect<IClient>({
    resource: "clients",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 100,
    filters: [
      {
        field: "branch_id",
        operator: "eq",
        value: branchIds,
      },
    ],
    queryOptions: {
      enabled: !!branchIds.length && allBranchesAreEqual,
    },
  });

  const onFinish = (data: EditOrdersForm) => {
    if (Object.values(data).some(value => !!value)) {
      updateOrders(
        {
          resource: "orders",
          ids: selectedRows.map(order => order!.id),
          values: data,
        },
        {
          onSuccess: () => {
            close();
            clearSelectedRows();
            formProps?.form?.resetFields();
          },
        }
      );
    } else {
      close();
    }
  };

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t("orders.titles.editOrders")}
      destroyOnClose
      forceRender
    >
      <Edit
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        title={false}
        saveButtonProps={saveButtonProps}
        isLoading={isLoading}
      >
        <Form {...formProps} layout="vertical" onFinish={onFinish}>
          <Form.Item label={t("orders.fields.shortName")} name="short_name">
            <Input />
          </Form.Item>
          <Form.Item label={t("orders.fields.description")} name="description">
            <Input />
          </Form.Item>
          <Form.Item label={t("orders.fields.status")} name="order_status_id">
            <Select
              {...orderStatusSelectProps}
              disabled={ordersHaveInStockStatus}
              placeholder={t("orders.fields.status")}
              options={orderStatusSelectProps.options?.map(({ value, label }) => ({
                value,
                label: t(`orders.statuses.${label}`),
                disabled:
                  value === OrderStatus.InStock ||
                  value === OrderStatus.BeingPacked ||
                  value === OrderStatus.Shipped,
              }))}
            />
          </Form.Item>
          <Form.Item label={t("orders.fields.client")} name="client_id">
            <Select
              {...clientSelectProps}
              placeholder={t("orders.fields.client")}
              disabled={!allBranchesAreEqual || clientSelectProps.options?.length === 0}
            />
          </Form.Item>
          {selectedRows?.length && !allArticlesAreEqual && (
            <div>
              <Icons.ExclamationCircleOutlined style={{ color: "#D39230", marginRight: "5px" }} />
              <Typography.Text>{t("orders.differentArticleNumbers")}</Typography.Text>
            </div>
          )}
          {selectedRows?.length && !allBranchesAreEqual && (
            <div>
              <Icons.ExclamationCircleOutlined style={{ color: "#D39230", marginRight: "5px" }} />
              <Typography.Text>{t("orders.differentBranches")}</Typography.Text>
            </div>
          )}

          {selectedRows?.length && ordersHaveInStockStatus && (
            <div>
              <Icons.ExclamationCircleOutlined style={{ color: "#D39230", marginRight: "5px" }} />
              <Typography.Text>{t("orders.ordersWithInStockStatus")}</Typography.Text>
            </div>
          )}
        </Form>
      </Edit>
    </Drawer>
  );
};
