import { FC, useState } from "react";
import { Button, List, useDrawerForm, useTable } from "@pankod/refine-antd";
import { HttpError, useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";

import { AddIcon } from "assets";
import { Colors } from "theme";
import { FiltersButton } from "components/appFilterButton";
import { TransactionsFilter, createOnSearchFilter } from "./filters";
import { IFinancialTransactionFilterVariables, IFinancialTransactions } from "interfaces";
import { TransactionsTable } from "./table";
import { CreateTransaction } from "./create";
import { TransactionTypes } from "../../constants";

interface Props {
  branchId: string;
  selectorId?: string;
}

export const BranchTransactionsList: FC<Props> = props => {
  const { branchId, selectorId } = props;
  const t = useTranslate();
  const [transactionType, setTransactionType] = useState<TransactionTypes>(TransactionTypes.Incoming);

  const { tableProps, sorter, searchFormProps, filters } = useTable<
    IFinancialTransactions,
    HttpError,
    IFinancialTransactionFilterVariables
  >({
    resource: "financial_transactions",
    onSearch: params => createOnSearchFilter(params),
    permanentFilter: [
      {
        field: "branch_id",
        operator: "eq",
        value: branchId,
      },
    ],
    queryOptions: {
      enabled: !!branchId,
    },
    initialSorter: [
      {
        field: "transaction_date",
        order: "desc",
      },
    ],
    metaData: { select: "*, invoices(*)" },
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<IFinancialTransactions, HttpError, IFinancialTransactions>({
    action: "create",
    resource: "financial_transactions",
    redirect: false,
    onMutationSuccess: () => createFormProps.form.resetFields(),
  });

  const initialValues = {
    branch_id: branchId,
    transaction_type: transactionType,
    transaction_date: dayjs(),
  };

  return (
    <>
      <List
        resource="financial_transactions"
        breadcrumb={false}
        title={false}
        headerButtons={() => (
          <>
            <Button
              icon={<AddIcon fill={Colors.MintFocus} style={{ marginRight: "7px" }} />}
              onClick={() => {
                setTransactionType(TransactionTypes.Correction);
                createShow();
              }}
              style={{ display: "flex", alignItems: "center" }}
            >
              {t("branch_balance.titles.addCorrection")}
            </Button>
            <Button
              icon={<AddIcon fill={Colors.MintFocus} style={{ marginRight: "7px" }} />}
              onClick={() => {
                setTransactionType(TransactionTypes.Incoming);
                createShow();
              }}
              style={{ display: "flex", alignItems: "center" }}
            >
              {t("branch_balance.titles.addIncoming")}
            </Button>
            <FiltersButton
              content={<TransactionsFilter formProps={searchFormProps} filters={filters || []} />}
            />
          </>
        )}
      >
        <TransactionsTable tableProps={tableProps} sorter={sorter} selectorId={selectorId} />
      </List>
      <CreateTransaction
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        transactionType={transactionType}
        initialValues={initialValues}
      />
    </>
  );
};
