import { FC } from "react";
import { useTranslate } from "@pankod/refine-core";
import { AntdList, Button, useDrawerForm } from "@pankod/refine-antd";

import { IContainer, IPackage } from "interfaces";
import { canUpdateDelivery } from "helpers";
import { Package } from "./package";
import { CreatePackage } from "./create";
import { Colors } from "theme";
import { AppEmpty } from "components/appEmpty";
import { AddIcon } from "assets";
import { useCurrentUser } from "hooks";

interface Props {
  deliveryStatus: number;
  container: IContainer;
  refetchDelivery: () => void;
}

export const PackageList: FC<Props> = ({ container, deliveryStatus, refetchDelivery }) => {
  const t = useTranslate();
  const { isAdmin } = useCurrentUser();

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<IPackage>({
    action: "create",
    resource: "packages",
    redirect: false,
    onMutationSuccess: () => {
      createFormProps.form.resetFields();
      refetchDelivery();
    },
  });

  return (
    <>
      <AntdList
        pagination={false}
        size="small"
        footer={
          canUpdateDelivery(deliveryStatus) &&
          isAdmin && (
            <Button
              icon={<AddIcon fill="black" style={{ marginRight: "7px" }} />}
              onClick={() => createShow()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: Colors.MintFocus,
              }}
              block
            >
              {t("packages.titles.create")}
            </Button>
          )
        }
      >
        {!container.packages?.length ? (
          <AppEmpty
            description={<span style={{ fontSize: "18px", fontWeight: "500" }}>{t("packages.empty")}</span>}
            style={{ marginBottom: "20px" }}
            imageStyle={{ height: "90px" }}
          />
        ) : (
          <>
            {container.packages
              .sort((a, b) =>
                b.package_nr.localeCompare(a.package_nr, undefined, { numeric: true, sensitivity: "base" })
              )
              .map(pkg => (
                <Package
                  key={pkg.id}
                  deliveryStatus={deliveryStatus}
                  pkg={pkg}
                  container={container}
                  refetchDelivery={refetchDelivery}
                />
              ))}
          </>
        )}
      </AntdList>
      <CreatePackage
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        containerId={container.id}
      />
    </>
  );
};
