import { supabaseClient } from "config";
import { useCurrentUser } from "hooks/auth";
import { useState, useEffect, useCallback } from "react";
import { avatarPath } from "./storagePaths";

const { REACT_APP_ENVIRONMENT } = process.env;

export const useAvatars = (branchIds: string[]) => {
  const [avatars, setAvatars] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { tenantId } = useCurrentUser();

  const fetchAvatars = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const tmpAvatars: { [key: string]: string } = {};
      for (let index = 0; index < branchIds.length; index++) {
        const { data, error } = await supabaseClient.storage
          .from(REACT_APP_ENVIRONMENT!)
          .download(avatarPath(branchIds[index], tenantId));

        if (data) {
          tmpAvatars[branchIds[index]] = URL.createObjectURL(data);
        }

        if (error) {
          setError(error);
        }
      }

      setAvatars(tmpAvatars);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [branchIds, tenantId]);

  useEffect(() => {
    if (branchIds?.length) {
      fetchAvatars();
    }
  }, [branchIds?.length, fetchAvatars]);

  return { avatars, loading, error };
};
