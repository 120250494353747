import { FC, useState, useMemo, useEffect } from "react";
import { Form, Input, useSelect, Select, FormProps, Checkbox, Icons, Typography } from "@pankod/refine-antd";
import { useNavigation, useTranslate } from "@pankod/refine-core";

import { useCurrentUser } from "hooks";
import { IClient, IOrderStatus, IShop, IBranch } from "interfaces";
import { OrderStatus } from "../../constants";

const PRICE_FROM_TO_CONTROL = 100;

interface Props {
  formProps: FormProps<{}>;
  isEdit: boolean;
  isVisible: boolean;
  disableSaveAsDraftButton?: (shouldDisable: boolean) => void;
  shouldShowWarning?: boolean;
}

export const OrderForm: FC<Props> = props => {
  const { formProps, isEdit, isVisible, disableSaveAsDraftButton, shouldShowWarning } = props;
  const { isAdmin, userId } = useCurrentUser();
  const t = useTranslate();
  const { list } = useNavigation();

  const isControlFieldVisible = useMemo(
    () =>
      formProps.initialValues?.ordered_price &&
      formProps.initialValues.ordered_price >= PRICE_FROM_TO_CONTROL,
    [formProps.initialValues]
  );

  const [selectedBranchId, setSelectedBranchId] = useState<string>();
  const [hasPriceToControl, setHasPriceToControl] = useState<boolean>(isControlFieldVisible);

  const { selectProps: shopSelectProps } = useSelect<IShop>({
    resource: "shops",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: branchSelectProps } = useSelect<IBranch>({
    resource: "branches",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: orderStatusSelectProps } = useSelect<IOrderStatus>({
    resource: "order_statuses",
    optionLabel: "value",
    optionValue: "id",
    fetchSize: 30,
  });

  const { selectProps: clientSelectProps } = useSelect<IClient>({
    resource: "clients",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 100,
    filters: isAdmin
      ? [
          {
            field: "branch_id",
            operator: "eq",
            value: selectedBranchId,
          },
        ]
      : undefined,
    queryOptions: {
      enabled: !!selectedBranchId || !isAdmin,
    },
  });

  /**
   * Clear client field if branch was chaned.
   * Technical notes: form.reset() sets the initial value only.
   */
  const onChangeUser = (branch: any) => {
    setSelectedBranchId(branch);
    formProps.form?.setFieldValue("client_id", null);
  };

  const formInitialValues: any = {
    isActive: true,
    ...formProps.initialValues,
    branch_id: isAdmin ? formProps.initialValues?.branch_id : userId,
    under_control: !!formProps.initialValues?.under_control,
  };

  const handleOnFieldsChange = (changedFields: any) => {
    changedFields.forEach((field: any) => {
      if (field.name.includes("ordered_price")) {
        const hasDifference = Number(field.value) >= PRICE_FROM_TO_CONTROL;
        if (hasDifference) {
          setHasPriceToControl(true);
        } else {
          const isUnderControl = formProps.form?.getFieldValue("under_control");

          // Remove under_control value if ordered_price was changed to < PRICE_FROM_TO_CONTROL.
          if (isUnderControl) {
            formProps.form?.setFieldValue("under_control", false);
          }
          setHasPriceToControl(false);
        }
      }
      if (isEdit && field.name.includes("order_status_id")) {
        if (
          formInitialValues.order_status_id === OrderStatus.Unpublished &&
          field.value !== OrderStatus.Unpublished
        ) {
          disableSaveAsDraftButton!(true);
        } else {
          disableSaveAsDraftButton!(false);
        }
      }
    });
  };

  useEffect(() => {
    if (isEdit && formProps.initialValues?.branch_id) {
      setSelectedBranchId(formProps.initialValues?.branch_id);
    } else {
      setSelectedBranchId(undefined);
    }
  }, [formProps.initialValues?.branch_id, isEdit]);

  useEffect(() => {
    setHasPriceToControl(!!isControlFieldVisible);
  }, [isControlFieldVisible, isVisible]);

  /** Try to select default client for branch (default client as a branch itself). */
  useEffect(() => {
    if (selectedBranchId && clientSelectProps.options?.length && !isEdit) {
      const branch = branchSelectProps.options?.find(option => option.value === selectedBranchId);
      const client = clientSelectProps.options.find(option => option.label === branch?.label);

      if (client) {
        formProps.form?.setFieldValue("client_id", client.value);
      }
    }
  }, [branchSelectProps.options, clientSelectProps.options, formProps.form, selectedBranchId, isEdit]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={formInitialValues}
      onFieldsChange={changedFields => handleOnFieldsChange(changedFields)}
    >
      {isAdmin ? (
        <Form.Item label={t("orders.fields.branch")} name="branch_id" rules={[{ required: true }]}>
          <Select {...branchSelectProps} placeholder={t("orders.fields.branch")} onChange={onChangeUser} />
        </Form.Item>
      ) : (
        <Form.Item name="branch_id" hidden>
          <Input />
        </Form.Item>
      )}

      <Form.Item label={t("orders.fields.client")} name="client_id" rules={[{ required: true }]}>
        <Select
          {...clientSelectProps}
          placeholder={t("orders.fields.client")}
          disabled={(!selectedBranchId && isAdmin) || clientSelectProps.options?.length === 0}
        />
      </Form.Item>

      <Form.Item label={t("orders.fields.shop")} name="shop_id" rules={[{ required: true }]}>
        <Select {...shopSelectProps} placeholder={t("orders.fields.shop")} />
      </Form.Item>
      <Form.Item label={t("orders.fields.articleNumber")} name="article_number" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t("orders.fields.name")} name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t("orders.fields.shortName")} name="short_name">
        <Input />
      </Form.Item>
      <Form.Item label={t("orders.fields.size")} name="size" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t("orders.fields.color")} name="color" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t("orders.fields.price")} name="ordered_price" rules={[{ required: true }]}>
        <Input type="number" addonBefore="€" min={0} />
      </Form.Item>
      {isAdmin && (
        <Form.Item label={t("orders.fields.customsPrice")} name="customs_price" rules={[{ required: false }]}>
          <Input type="number" addonBefore="€" min={0} />
        </Form.Item>
      )}
      <Form.Item
        label={t("orders.fields.control")}
        name="under_control"
        valuePropName="checked"
        hidden={!hasPriceToControl}
      >
        <Checkbox>{t("orders.putUnderControl")}</Checkbox>
      </Form.Item>
      {isEdit && formInitialValues.weight && (
        <Form.Item label={t("arrival.additionalInfo.weight.label")} name="weight">
          <Input
            type="number"
            min={0}
            addonBefore={t("arrival.additionalInfo.weight.suffix")}
            placeholder={t("arrival.additionalInfo.weight.placeholder")}
          />
        </Form.Item>
      )}
      {!isEdit && (
        <Form.Item label={t("orders.fields.amount")} name="amount" rules={[{ required: true }]}>
          <Input type="number" min={1} />
        </Form.Item>
      )}
      <Form.Item label={t("orders.fields.link")} name="link" rules={[{ required: true, type: "url" }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t("orders.fields.description")} name="description">
        <Input />
      </Form.Item>
      <Form.Item
        label={t("orders.fields.status")}
        name="order_status_id"
        rules={[{ required: true }]}
        hidden={isAdmin ? !isEdit : true}
      >
        <Select
          {...orderStatusSelectProps}
          placeholder={t("orders.fields.status")}
          options={orderStatusSelectProps.options?.map(({ value, label }) => ({
            value,
            label: t(`orders.statuses.${label}`),
            disabled:
              value === OrderStatus.InStock ||
              value === OrderStatus.BeingPacked ||
              value === OrderStatus.Shipped,
          }))}
        />
      </Form.Item>
      {shouldShowWarning && (
        <div>
          <Icons.ExclamationCircleOutlined style={{ color: "#D39230", marginRight: "5px" }} />
          <Typography.Text>{t("orders.balanceVerificationWarning")}</Typography.Text>
          <Typography.Link onClick={() => list("financial_transactions")} style={{ marginLeft: "5px" }}>
            {t("orders.balanceLink")}
          </Typography.Link>
        </div>
      )}
    </Form>
  );
};
