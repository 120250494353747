import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { IResourceComponentsProps, useTranslate, useRouterContext } from "@pankod/refine-core";
import { Show, useForm } from "@pankod/refine-antd";

import { BranchTransactionsList } from "components/transactions";
import { UserForm } from "components/user";
import { ClientsList } from "components/clients";
import { BranchShopsTable } from "components/branchShops";
import { IBranch } from "interfaces";
import { useCurrentUser } from "hooks";

export const BranchShow: FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, queryResult } = useForm<IBranch>({ action: "edit" });
  const { userId } = useCurrentUser();
  const { useParams } = useRouterContext();
  const routeParams: any = useParams();

  const personalDataEnabled = routeParams.id !== userId;

  const [activeTabKey, setActiveTabKey] = useState<string>(personalDataEnabled ? "personalData" : "shops");

  const personalDataTab = {
    key: "personalData",
    tab: t("branches.personalData"),
  };

  const tabList = [
    {
      key: "shops",
      tab: t("shops.shops"),
    },
    {
      key: "transactions",
      tab: t("branches.balance"),
    },
    {
      key: "clients",
      tab: t("clients.clients"),
    },
  ];

  const refetchBranchInfo = useCallback(() => queryResult?.refetch(), [queryResult]);

  const contentList: Record<string, ReactNode> = {
    ...(personalDataEnabled && {
      personalData: (
        <UserForm formProps={formProps} isReadOnly={false} refetch={refetchBranchInfo} shouldShowFormAction />
      ),
    }),
    shops: <BranchShopsTable branch={queryResult?.data?.data} selectorId="whiteTable" />,
    transactions: <BranchTransactionsList branchId={queryResult?.data?.data.id!} selectorId="whiteTable" />,
    clients: <ClientsList branchId={queryResult?.data?.data.id!} selectorId="whiteTable" />,
  };

  return (
    <Show
      breadcrumb={false}
      isLoading={queryResult?.isFetching}
      headerProps={{ extra: null }}
      contentProps={{
        tabList: !personalDataEnabled ? tabList : [personalDataTab, ...tabList],
        onTabChange: (key: string) => setActiveTabKey(key),
      }}
    >
      {contentList[activeTabKey]}
    </Show>
  );
};
