import { FC } from "react";
import { useMany, useTranslate } from "@pankod/refine-core";
import { Button, DateField, ShowButton, Table, TableProps, TextField } from "@pankod/refine-antd";

import { AppTag } from "components/appTag";
import { IDelivery, IDeliveryStatus } from "interfaces";
import { useCurrentUser } from "hooks";
import { EditIcon, ShowIcon } from "assets";
import { AppEmpty } from "components/appEmpty";

interface IColumnConfig {
  hiddenCreatedAt?: boolean;
  hiddenUpdatedAt?: boolean;
}

interface Props {
  tableProps: TableProps<IDelivery>;
  selectorId?: string;
  enableActions?: boolean;
  columnConfig?: IColumnConfig;
  onRecordEdit?: (id: number) => void;
}

export const DeliveriesTable: FC<Props> = props => {
  const { tableProps, enableActions = true, onRecordEdit, columnConfig, selectorId } = props;
  const t = useTranslate();
  const { isAdmin } = useCurrentUser();

  const deliveryStatusIds = tableProps.dataSource?.map(p => p.delivery_status_id.toString()) || [];
  const { data: deliveryStatusData, isFetching: isFetchingDeliveryStatus } = useMany<IDeliveryStatus>({
    resource: "delivery_statuses",
    ids: deliveryStatusIds,
    queryOptions: {
      enabled: deliveryStatusIds.length > 0,
    },
  });

  return (
    <Table
      {...tableProps}
      rowKey="id"
      size="small"
      id={selectorId}
      rowClassName="app-table-row"
      locale={{
        emptyText: (
          <AppEmpty
            description={
              <span style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>{t("table.empty")}</span>
            }
          />
        ),
      }}
    >
      <Table.Column
        key="delivery_nr"
        dataIndex="delivery_nr"
        title={t("deliveries.fields.deliveryID")}
        render={value => <TextField value={value} />}
      />
      <Table.Column
        key="delivery_status_id"
        title={t("deliveries.fields.status")}
        render={(value: IDelivery) => {
          if (isFetchingDeliveryStatus) return "loading...";

          return (
            <AppTag
              queryResult={deliveryStatusData}
              translationPrefix="deliveries.statuses"
              value={value.delivery_status_id}
            />
          );
        }}
      />
      {!columnConfig?.hiddenCreatedAt && (
        <Table.Column
          key="created_at"
          dataIndex="created_at"
          title={t("deliveries.fields.createdAt")}
          render={value => <DateField value={value} format="DD.MM.YYYY HH:mm" />}
          sorter
        />
      )}
      {!columnConfig?.hiddenUpdatedAt && (
        <Table.Column
          key="updated_at"
          dataIndex="updated_at"
          title={t("orders.fields.updatedAt")}
          render={value => (value ? <DateField value={value} format="DD.MM.YYYY HH:mm" /> : "")}
          sorter
        />
      )}
      {enableActions && (
        <Table.Column<IDelivery>
          fixed="right"
          title={t("table.actions")}
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_value, record) => (
            <>
              {isAdmin && (
                <Button
                  icon={<EditIcon />}
                  style={{ borderColor: "transparent", background: "transparent" }}
                  onClick={() => {
                    onRecordEdit!(record.id);
                  }}
                />
              )}
              <ShowButton
                hideText
                recordItemId={record.id}
                style={{ borderColor: "transparent", background: "transparent" }}
                icon={<ShowIcon />}
              />
            </>
          )}
        />
      )}
    </Table>
  );
};
