const DEFAULT_SHOP_CATEGORY_ID = 1;

enum TransactionTypes {
  Incoming = 1,
  Outgoing = 2,
  Correction = 3,
}

enum OrderStatus {
  Unpublished = 1, // The order is not published
  Received = 2, // The order has been received,gold
  Accepted = 3, // The order was accepted,lime
  Ordered = 5, // Has been ordered,green
  InStock = 6, // Arrived at warehouse,blue
  Shipped = 7, // Was shipped,purple
  Cancelled = 8, // Was cancelled by customer,volcano
  SoldOut = 9, // Is sold out in the shop,magenta
  OrderError = 10, // There are errors in the order,red
  Returned = 11, // Was returned,black
  BeingPacked = 12, // Is being packed,geekblue
  HeldInStock = 13, // The order is held in stock in case when price is higher than ordered.
  Delivered = 14,
}

enum PdfType {
  CN23,
  CP71,
  CN38,
  CP87,
  CustomManifest,
  CargoManifest,
  CommercialInvoice,
  RussianInvoice,
  EnglishInvoice,
  PackingList,
}

enum DeliveryStatus {
  Packaging = 1,
  ReadyForShipping = 2,
  Shipped = 3,
  Delivered = 4,
}

export { DEFAULT_SHOP_CATEGORY_ID, TransactionTypes, OrderStatus, PdfType, DeliveryStatus };
