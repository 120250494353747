import { FC } from "react";
import {
  HttpError,
  IResourceComponentsProps,
  useNotification,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { Button, List, useTable } from "@pankod/refine-antd";
import dayjs from "dayjs";

import { TransactionsTable } from "components/transactions";
import { useCurrentUser } from "hooks";
import { IFinancialTransactions } from "interfaces";

export const TransactionsList: FC<IResourceComponentsProps> = () => {
  const { userId, user } = useCurrentUser();
  const t = useTranslate();
  const { mutate: updateMutation } = useUpdate();
  const { open } = useNotification();

  const { tableProps, sorter } = useTable<IFinancialTransactions, HttpError>({
    resource: "financial_transactions",
    permanentFilter: [
      {
        field: "branch_id",
        operator: "eq",
        value: userId,
      },
    ],
    queryOptions: {
      enabled: !!userId,
    },
    initialSorter: [
      {
        field: "transaction_date",
        order: "desc",
      },
    ],
    metaData: { select: "*, invoices(*)" },
  });

  const handleConfirmBalanceClick = () => {
    updateMutation(
      {
        resource: "branches",
        id: userId,
        values: {
          last_balance_verification_date: dayjs(),
          next_balance_verification_date: dayjs().add(user.balance_verification_period_in_weeks, "week"),
        },
        successNotification: false,
      },
      {
        onSuccess: () => {
          open?.({
            type: "success",
            message: t("branch_balance.notification.message"),
            description: t("branch_balance.notification.description"),
          });
        },
      }
    );
  };

  return (
    <List
      resource="financial_transactions"
      breadcrumb={false}
      title={`${t("branch_balance.lastBalanceConfirmation")}: ${
        user ? dayjs(user.last_balance_verification_date).format("L LT") : ""
      }`}
      headerButtons={() => (
        <Button type="primary" onClick={handleConfirmBalanceClick}>
          {t("branch_balance.titles.confirmBalance")}
        </Button>
      )}
    >
      <TransactionsTable tableProps={tableProps} sorter={sorter} />
    </List>
  );
};
