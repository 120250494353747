import { FC } from "react";
import { Col, List, Row } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useOne } from "@pankod/refine-core";
import { t } from "i18next";

import { IBranch } from "interfaces";
import { BranchShopsTable } from "components/branchShops";

export const BranchShopList: FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { data: branchData } = useOne<IBranch>({
    resource: "branches",
    id: user?.id,
    queryOptions: {
      enabled: !!user,
    },
  });

  return (
    <Row gutter={[16, 16]}>
      <Col xl={24} xs={24}>
        <List title={false}>
          <BranchShopsTable branch={branchData?.data} enableActions={false} />
        </List>
      </Col>
    </Row>
  );
};
