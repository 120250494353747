import { FC } from "react";
import { useOne, useTranslate } from "@pankod/refine-core";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import { CN23 } from "documents/package/cn23";
import { CP71 } from "documents/package/cp71";
import { PdfType } from "../../constants";
import { IContainer, IPackage, IBranch, ITenant } from "interfaces";
import { useCurrentUser } from "hooks";
import { Button } from "@pankod/refine-antd";
import { DocumentIcon } from "assets";

type Props = {
  pkg: IPackage;
  pdfType?: PdfType;
  branch: IBranch;
  container: IContainer;
};

export const PackagePdfLayout: FC<Props> = ({ pkg, pdfType, branch, container }) => {
  const { tenantId } = useCurrentUser();
  const t = useTranslate();

  const { data: tenantResponse, isFetching: isTenantFetching } = useOne<ITenant>({
    resource: "tenants",
    id: tenantId,
    queryOptions: { enabled: !!tenantId },
  });

  if (isTenantFetching || isTenantFetching || !tenantResponse?.data) {
    return <div>Loading...</div>;
  }

  let doc: any;
  switch (pdfType) {
    case PdfType.CN23:
      doc = <CN23 branch={branch} tenant={tenantResponse.data} container={container} pkg={pkg} />;
      break;
    case PdfType.CP71:
      doc = <CP71 branch={branch} tenant={tenantResponse.data} container={container} pkg={pkg} />;
      break;
    default:
      break;
  }

  return (
    <>
      <PDFViewer
        style={{
          paddingTop: 32,
          width: "100%",
          height: "80vh",
          border: "none",
        }}
      >
        {doc}
      </PDFViewer>
      <div
        style={{
          width: "100px",
        }}
      >
        <PDFDownloadLink document={doc!} fileName={`${PdfType[pdfType!]}_#${pkg.package_nr}.pdf`}>
          <Button
            icon={<DocumentIcon style={{ marginRight: 4 }} />}
            style={{ display: "flex", alignItems: "center" }}
          >
            {t("buttons.save")}
          </Button>
        </PDFDownloadLink>
      </div>
    </>
  );
};
