import { IBranch, IContainer, IOrder } from "interfaces";

interface OrdersGroup {
  name: string;
  short_name?: string;
  article_number: string;
  items: IOrder[];
}

const removeTrailingZeroes = (value: number | string) => parseFloat(value.toString());

/** Сalculates the total weight of packages in the container. Result also includes the weight of empty container. Unit of measure - kilograms. */
const calcContainerWeightInKilograms = (row: IContainer) => {
  const totalContainerWeight = row.packages?.reduce((total, current) => {
    if (current) {
      const packageWeight = current.orders?.reduce((total, current) => (total += Number(current.weight)), 0);
      return (total += packageWeight ?? 0);
    }
    return total;
  }, 0);

  return removeTrailingZeroes((((totalContainerWeight ?? 0) + (row.weight ?? 0)) / 1000).toFixed(3));
};

/** Сalculates the total weight of containers in the delivery. Result also includes the weight of empty container. Unit of measure - kilograms. */
const calcTotalWeightOfContainersInKilograms = (grid: IContainer[]) => {
  const totalWeight = grid.reduce((total, current) => {
    if (current) {
      return (total += calcContainerWeightInKilograms(current));
    }
    return total;
  }, 0);

  return removeTrailingZeroes(totalWeight.toFixed(3));
};

/** Calculates the order price (customs_price) with discount for current order. */
const calcPriceAfterDiscountForOrder = (order: IOrder, branch_id: string) => {
  const { arrival_price, customs_price, shops } = order;
  const discount =
    shops?.branch_shops?.find(branch_shop => branch_shop.branch_id === branch_id)?.discount ?? "";

  return discount
    ? ((customs_price ?? +arrival_price) * ((100 - +discount) / 100)).toFixed(2)
    : customs_price ?? arrival_price;
};

/** Calculates the total price of orders with discount in the delivery. */
const calcOrdersTotalPriceWithDiscount = (orders: IOrder[], branch_id: string) => {
  const total = orders.reduce(
    (total: number, current) => total + Number(calcPriceAfterDiscountForOrder(current, branch_id)),
    0
  );

  return total;
};

/** Calculates the order price (arrival_price) with discount for current order. Only for commercial invoice (Rechnung). */
const calcPriceAfterDiscountForOrderInRechnung = (order: IOrder, branch_id: string) => {
  const { arrival_price, shops } = order;
  const discount =
    shops?.branch_shops?.find(branch_shop => branch_shop.branch_id === branch_id)?.discount ?? "";

  const total = discount ? +arrival_price * ((100 - +discount) / 100) : arrival_price;

  return removeTrailingZeroes(total.toFixed(2));
};

/** Calculates the total price of orders with discount in the delivery. Only for commercial invoice (Rechnung). */
const calcOrdersTotalPriceWithDiscountInRechnung = (orders: IOrder[], branch_id: string) => {
  const total = orders.reduce(
    (total: number, current) => total + Number(calcPriceAfterDiscountForOrderInRechnung(current, branch_id)),
    0
  );

  return removeTrailingZeroes(total.toFixed(2));
};

/** Сalculates the total weight of orders in the delivery. Unit of measure - kilograms. */
const calcOrdersTotalWeight = (orders: IOrder[]) => {
  const total = (orders.reduce((total: number, current) => total + current.weight, 0) / 1000).toFixed(3);
  return removeTrailingZeroes(total);
};

/** Calculates the shipping cost based on shipping cost per kg and total orders weight with empty containers weight. */
const calcShippingCostOfTotalWeight = (
  orders: IOrder[],
  branch: IBranch,
  emptyContainerWeight: number = 0 // Unit of measure - grams.
) => {
  const total = (calcOrdersTotalWeight(orders) + emptyContainerWeight / 1000) * branch?.shipping_cost_per_kg;
  return removeTrailingZeroes(total.toFixed(2));
};

/** Calculates the total shipping cost from the shops where the goods were ordered in the delivery */
const calcShippingCostFromTheShops = (orders: IOrder[], branch: IBranch) => {
  const shopsShippingCostCollection = new Map();
  let shopsShippingCost = 0;

  orders
    .flatMap(order => order.shops?.branch_shops)
    .forEach(branch_shop => {
      if (branch_shop && branch_shop.branch_id === branch?.id) {
        shopsShippingCostCollection.set(branch_shop.shop_id, branch_shop.shipping_cost);
      }
    });
  shopsShippingCostCollection.forEach((value, key) => {
    if (value) {
      shopsShippingCost += value;
    }
  });

  return Number(shopsShippingCost.toFixed(2));
};

/** Calculates the amount to pay for invoice. */
const calcInvoicePrice = (orders: IOrder[], branch: IBranch, containers: IContainer[]) => {
  // Works only in case of 1 container has 1 package.
  const weightOfEmptyContainers = calcTotalWeightOfEmptyContainers(containers);

  const result =
    calcShippingCostFromTheShops(orders, branch) +
    calcShippingCostOfTotalWeight(orders, branch, weightOfEmptyContainers * 1000) +
    calcOrdersTotalPriceWithDiscount(orders, branch.id);

  return removeTrailingZeroes(result.toFixed(2));
};

const sortOrdersViaBranchId = (containers: IContainer[] | undefined) => {
  if (containers) {
    const packages = containers.flatMap(container => container.packages);
    const orders = packages.flatMap(pkg => pkg?.orders!);

    return orders.reduce((total: { [key: string]: IOrder[] }, current) => {
      if (current) {
        const { branch_id } = current;

        if (total[branch_id]) {
          return { ...total, [branch_id]: [...total[branch_id], current] };
        } else {
          return { ...total, [branch_id]: [current] };
        }
      }

      return total;
    }, {});
  }
};

/** Сalculates the total weight of empty containers in the delivery. Unit of measure - kilograms. */
const calcTotalWeightOfEmptyContainers = (containers: IContainer[]) => {
  const total = (
    containers.reduce((total: number, current) => total + (current?.weight ?? 0), 0) / 1000
  ).toFixed(3);
  return removeTrailingZeroes(total);
};

/** Group orders via name, short_name и article_number. These keys should be similar for each order in the group. */
const groupSimilarOrders = (orders: IOrder[]): OrdersGroup[] => {
  const result = orders.reduce((acc: { [key: string]: OrdersGroup }, curr: IOrder) => {
    const key = `${curr.name}-${curr.short_name}-${curr.article_number}`;

    if (!acc[key]) {
      acc[key] = {
        name: curr.name,
        short_name: curr.short_name,
        article_number: curr.article_number,
        items: [],
      };
    }

    acc[key].items.push(curr);
    return acc;
  }, {});

  return Object.values(result);
};

export {
  calcContainerWeightInKilograms,
  calcTotalWeightOfContainersInKilograms,
  calcPriceAfterDiscountForOrder,
  calcOrdersTotalPriceWithDiscount,
  calcOrdersTotalPriceWithDiscountInRechnung,
  calcOrdersTotalWeight,
  calcShippingCostOfTotalWeight,
  calcShippingCostFromTheShops,
  calcInvoicePrice,
  sortOrdersViaBranchId,
  removeTrailingZeroes,
  calcTotalWeightOfEmptyContainers,
  groupSimilarOrders,
};
