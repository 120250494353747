import { FC } from "react";
import { useGetLocale, useSetLocale, useLogout, useResource } from "@pankod/refine-core";
import { AntdLayout, Button, Dropdown, Avatar, Typography, MenuProps } from "@pankod/refine-antd";
import { useTranslation } from "react-i18next";

import { styles } from "./styles";
import { LogoutIcon } from "assets";

const { Text } = Typography;

export const Header: FC = () => {
  const { i18n, t } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { mutate: mutateLogout } = useLogout();

  const { resource } = useResource();
  const currentLocale = locale();

  const items: MenuProps["items"] = [...(i18n.languages || [])].sort().map((lang: string) => ({
    key: lang,
    label: <span style={styles.language}>{lang.toUpperCase()}</span>,
    onClick: () => changeLanguage(lang),
    icon: <Avatar size={20} src={`/images/flags/${lang}.svg`} style={{ marginRight: 8 }} />,
  }));

  return (
    <AntdLayout.Header style={styles.header}>
      <div style={styles.container}>
        <Text style={styles.title} strong>
          {resource.label ? t(`${resource.label}`) : t("dashboard.title")}
        </Text>
        <div style={styles.flexContainer}>
          <Dropdown menu={{ items }}>
            <Button type="link">
              <div style={styles.flexContainer}>
                <Avatar size={20} src={`/images/flags/${currentLocale}.svg`} style={{ marginRight: 10 }} />
                <span style={styles.language}>{currentLocale?.toUpperCase()}</span>
              </div>
            </Button>
          </Dropdown>
          <LogoutIcon style={{ cursor: "pointer" }} onClick={() => mutateLogout()} />
        </div>
      </div>
    </AntdLayout.Header>
  );
};
