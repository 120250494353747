import { Colors } from "./colors";

export const appTheme = {
  token: {
    colorPrimary: Colors.Emerald,
    colorBgLayout: Colors.MintMain,
    fontFamily: "Nunito",
  },
  components: {
    Menu: {
      colorItemBg: "transparent",
      colorItemText: Colors.White,
      colorItemTextSelected: Colors.NavyBlue,
      colorItemBgSelected: Colors.MintMain,
      colorItemTextHover: Colors.White,
      itemMarginInline: 15,
    },
    Input: {
      colorTextDisabled: "rgba(0, 0, 0, 0.88)",
    },
  },
};
