import { FC } from "react";
import { HttpError, IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { List, useDrawerForm, useTable } from "@pankod/refine-antd";

import { IBranch } from "interfaces";
import { AppCreateButton } from "components/appCreateButton";
import { BranchesTable, CreateBranch } from "components/branches";

export const BranchList: FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const {
    tableProps,
    tableQueryResult: { refetch },
  } = useTable<IBranch, HttpError>({
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
    close: createClose,
  } = useDrawerForm<IBranch, HttpError, IBranch>({
    action: "create",
    resource: "branches",
    redirect: false,
  });

  return (
    <>
      <List
        title={false}
        headerButtons={() => (
          <>
            <AppCreateButton onClick={() => createShow()}>{t("branches.addUser")}</AppCreateButton>
          </>
        )}
      >
        <BranchesTable tableProps={tableProps} />
      </List>
      <CreateBranch
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        close={() => {
          createClose();
          refetch();
        }}
      />
    </>
  );
};
