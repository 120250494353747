import { FC, useEffect, useState } from "react";
import { ButtonProps, Drawer, DrawerProps, Edit, FormProps, Grid, Button } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

import { IOrder } from "interfaces";
import { OrderStatus } from "../../constants";
import { OrderForm } from "./createEditForm";

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  isUnableToCreateOrder: boolean;
}

export const EditOrder: FC<Props> = props => {
  const { drawerProps, formProps, saveButtonProps, isUnableToCreateOrder } = props;
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();

  const [shouldDisableSaveAsDraft, setShouldDisableSaveAsDraft] = useState<boolean>(false);

  const isUnpublished = () => {
    if (formProps.initialValues) {
      return formProps.initialValues.order_status_id === OrderStatus.Unpublished;
    }
    return false;
  };

  const updateSaveButtonProps = () => {
    if (isUnpublished()) {
      return {
        ...saveButtonProps,
        children: t("buttons.publish"),
        onClick: handleSaveAndPublish,
        disabled: isUnableToCreateOrder,
      };
    }

    return saveButtonProps;
  };

  const handleSaveAsUnpublished = async () => {
    await formProps.form?.validateFields();

    const formData = formProps.form?.getFieldsValue();
    const request = { ...formData, order_status_id: OrderStatus.Unpublished };
    formProps.onFinish!(request);
  };

  const handleSaveAndPublish = async () => {
    await formProps.form?.validateFields();

    const formData = formProps.form?.getFieldsValue() as IOrder;
    let request = formData;

    // Check if the user update status before submit. Default status is Received.
    if (formData.order_status_id === OrderStatus.Unpublished) {
      request = { ...formData, order_status_id: OrderStatus.Received };
    }

    formProps.onFinish!(request);
  };

  useEffect(() => {
    if (drawerProps.open) {
      setShouldDisableSaveAsDraft(false);
    }
  }, [drawerProps.open]);

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t("orders.titles.edit")}
      destroyOnClose
      forceRender
    >
      <Edit
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        title={false}
        saveButtonProps={updateSaveButtonProps()}
        footerButtons={({ defaultButtons }) => (
          <>
            {isUnpublished() && (
              <Button type="default" onClick={handleSaveAsUnpublished} disabled={shouldDisableSaveAsDraft}>
                {t("buttons.save")}
              </Button>
            )}
            {defaultButtons}
          </>
        )}
      >
        <OrderForm
          formProps={formProps}
          isEdit={true}
          isVisible={!!drawerProps.open}
          disableSaveAsDraftButton={shouldDisable => setShouldDisableSaveAsDraft(shouldDisable)}
          shouldShowWarning={isUnableToCreateOrder}
        />
      </Edit>
    </Drawer>
  );
};
