import { FC, useCallback, useEffect, useState } from "react";
import {
  HttpError,
  IResourceComponentsProps,
  useCreateMany,
  useDeleteMany,
  useList,
  useTranslate,
} from "@pankod/refine-core";
import { List, useDrawerForm, useTable } from "@pankod/refine-antd";

import { IShop, IShopFilterVariables, ITenantShopCategory } from "interfaces";
import { createOnSearchFilter, ShopFilter, ShopsTable, CreateShop, EditShop } from "components/shops";
import { FiltersButton } from "components/appFilterButton";
import { useCurrentUser } from "hooks";
import { AppCreateButton } from "components/appCreateButton";
import { DEFAULT_SHOP_CATEGORY_ID } from "../../constants";

export const ShopList: FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const createMutation = useCreateMany<ITenantShopCategory>();
  const deleteMutation = useDeleteMany<ITenantShopCategory>();
  const { tenantId } = useCurrentUser();

  const [categoriesToCreate, setCategoriesToCreate] = useState<number[]>([]);
  const [categoriesToUpdate, setCategoriesToUpdate] = useState<number[] | undefined>(undefined);
  const [shopId, setShopId] = useState<number | undefined>();

  const { tableProps, searchFormProps, filters } = useTable<IShop, HttpError, IShopFilterVariables>({
    onSearch: params => createOnSearchFilter(params),
  });

  /** All available categories for selected Tenant. */
  const { data: shopCategories } = useList<ITenantShopCategory>({
    resource: "tenant_shop_categories",
    queryOptions: {
      enabled: !!tenantId,
    },
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<IShop, HttpError, IShop>({
    action: "create",
    resource: "shops",
    redirect: false,
    onMutationSuccess: data => {
      setShopId(data.data.id);
      createFormProps.form.resetFields();
    },
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<IShop, HttpError, IShop>({
    action: "edit",
    resource: "shops",
    redirect: false,
    onMutationSuccess: data => setShopId(data.data.id),
    metaData: {
      select: "*, tenant_shop_categories(category_id)",
    },
  });

  const addCategoriesToShop = useCallback(() => {
    if (shopId && categoriesToCreate.length) {
      createMutation.mutate(
        {
          resource: "tenant_shop_categories",
          values: categoriesToCreate.map((category: number) => ({
            shop_id: shopId,
            category_id: category,
            tenant_id: tenantId,
          })),
          successNotification: false,
        },
        {
          onSuccess: () => {
            setCategoriesToCreate([]);
            setShopId(undefined);
          },
        }
      );
    } else {
      setShopId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesToCreate, shopId, tenantId]);

  const updateShopCategories = useCallback(() => {
    if (shopId && categoriesToUpdate) {
      const valuesToCreate = shopCategories?.data.length
        ? categoriesToUpdate
            .filter(
              category =>
                shopCategories?.data.length &&
                !shopCategories.data.find(
                  item =>
                    item.category_id === category &&
                    item.shop_id === shopId &&
                    (item.tenant_id === tenantId || !item.tenant_id)
                )
            )
            .map((category: number) => ({
              shop_id: shopId,
              category_id: category,
              tenant_id: tenantId,
            }))
        : categoriesToUpdate.map((category: number) => ({
            shop_id: shopId,
            category_id: category,
            tenant_id: tenantId,
          }));

      createMutation.mutate({
        resource: "tenant_shop_categories",
        values: valuesToCreate,
        successNotification: false,
      });

      if (categoriesToUpdate.length) {
        deleteMutation.mutate({
          resource: "tenant_shop_categories",
          ids:
            shopCategories?.data
              .filter(
                item =>
                  !categoriesToUpdate.includes(item.category_id) &&
                  item.category_id !== DEFAULT_SHOP_CATEGORY_ID &&
                  item.shop_id === shopId &&
                  (item.tenant_id === tenantId || !item.tenant_id)
              )
              .map(item => item.id) ?? [],
          successNotification: false,
        });
      } else {
        // Remove all existing categories for selected shop.
        deleteMutation.mutate({
          resource: "tenant_shop_categories",
          ids:
            shopCategories?.data
              .filter(
                item =>
                  item.shop_id === shopId &&
                  item.category_id !== DEFAULT_SHOP_CATEGORY_ID &&
                  (item.tenant_id === tenantId || !item.tenant_id)
              )
              .map(item => item.id) ?? [],
          successNotification: false,
        });
      }

      setShopId(undefined);
      setCategoriesToUpdate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesToUpdate, shopCategories, shopId, tenantId]);

  useEffect(() => {
    addCategoriesToShop();
  }, [addCategoriesToShop]);

  useEffect(() => {
    updateShopCategories();
  }, [updateShopCategories]);

  return (
    <>
      <List
        title={false}
        headerButtons={() => (
          <>
            <AppCreateButton onClick={() => createShow()}>{t("buttons.create")}</AppCreateButton>
            <FiltersButton content={<ShopFilter formProps={searchFormProps} filters={filters || []} />} />
          </>
        )}
      >
        <ShopsTable tableProps={tableProps} enableActions={true} onRecordEdit={editShow} />
      </List>
      <CreateShop
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        setShopCategories={(data: number[]) => setCategoriesToCreate(data)}
        tenantId={tenantId}
      />
      <EditShop
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
        setShopCategories={(data: number[]) => setCategoriesToUpdate(data)}
        tenantId={tenantId}
      />
    </>
  );
};
