import { FC, ReactNode } from "react";
import { CrudSorting, useMany, useTranslate } from "@pankod/refine-core";
import {
  BooleanField,
  Button,
  DateField,
  getDefaultSortOrder,
  ImageField,
  ShowButton,
  Table,
  TableProps,
  TextField,
  Tooltip,
} from "@pankod/refine-antd";

import { AppTag } from "components/appTag";
import { IBranch, IClient, IOrder, IOrderStatus, IShop } from "interfaces";
import { useCurrentUser } from "hooks";
import { isOrderEditable, truncate } from "helpers";
import { EditIcon, ShowIcon } from "assets";
import { AppEmpty } from "components/appEmpty";
import { convertGramsToKilo } from "helpers/weight";

interface IColumnConfig {
  hiddenStatus?: boolean;
  hiddenShop?: boolean;
  hiddenOrderControl?: boolean;
  hiddenBranch?: boolean;
  hiddenClient?: boolean;
  hiddenCreatedAt?: boolean;
  hiddenUpdatedAt?: boolean;
  hiddenStoragePlace?: boolean;
  hiddenArrivalPrice?: boolean;
  hiddenCustomsPrice?: boolean;
  hiddenWeight?: boolean;
  hiddenCountry?: boolean;
  hiddenOrderedPrice?: boolean;
}

interface Props {
  selectorId?: string;
  tableProps: TableProps<IOrder>;
  sorter: CrudSorting | undefined;
  rowSelection?: any;
  enableActions?: boolean;
  columnConfig?: IColumnConfig;
  customAction?: (record: IOrder) => ReactNode;
  emptyText?: string;
  onRecordEdit?: (id: number) => void;
}

export const OrderTable: FC<Props> = props => {
  const {
    tableProps,
    enableActions = false,
    sorter,
    rowSelection,
    selectorId,
    columnConfig,
    customAction,
    emptyText,
    onRecordEdit,
  } = props;
  const t = useTranslate();
  const { isAdmin } = useCurrentUser();

  const orderStatusIds = tableProps.dataSource?.map(p => p.order_status_id.toString()) || [];
  const { data: orderStatusData, isFetching: isFetchingOrderStatus } = useMany<IOrderStatus>({
    resource: "order_statuses",
    ids: orderStatusIds,
    queryOptions: {
      enabled: !columnConfig?.hiddenStatus && orderStatusIds.length > 0,
    },
  });

  const shopIds = tableProps.dataSource?.map(p => p.shop_id.toString()) || [];
  const { data: shopData, isFetching: isFetchingShops } = useMany<IShop>({
    resource: "shops",
    ids: shopIds,
    queryOptions: {
      enabled: !columnConfig?.hiddenShop && shopIds.length > 0,
    },
  });

  const branchIds = tableProps.dataSource?.map((p: IOrder) => p.branch_id.toString()) || [];
  const { data: branchData, isFetching: isFetchingBranchs } = useMany<IBranch>({
    resource: "branches",
    ids: branchIds,
    queryOptions: {
      enabled: branchIds.length > 0,
    },
  });

  const clientIds =
    tableProps.dataSource?.filter((o: IOrder) => !!o.client_id).map((p: IOrder) => p.client_id.toString()) ||
    [];
  const { data: clientData, isFetching: isFetchingClients } = useMany<IClient>({
    resource: "clients",
    ids: clientIds,
    queryOptions: {
      enabled: clientIds.length > 0,
    },
  });

  return (
    <Table
      {...tableProps}
      rowKey="id"
      size="small"
      rowSelection={rowSelection}
      rowClassName={"app-table-row"}
      id={selectorId}
      locale={{
        emptyText: (
          <AppEmpty
            description={
              <span style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>
                {emptyText ?? t("table.empty")}
              </span>
            }
          />
        ),
      }}
    >
      <Table.Column
        key="name"
        dataIndex="name"
        title={t("orders.fields.name")}
        ellipsis={{ showTitle: false }}
        render={value => (
          <Tooltip
            placement="topLeft"
            title={value}
            mouseEnterDelay={0.7}
            color="white"
            overlayInnerStyle={{ color: "black" }}
          >
            {truncate(value, 50)}
          </Tooltip>
        )}
      />

      {!columnConfig?.hiddenShop && (
        <Table.Column
          key="logo"
          title={t("orders.fields.shop")}
          render={value => {
            if (isFetchingShops) return "loading...";
            const logo = shopData?.data.find(p => p.id === value.shop_id)?.logo;
            return <ImageField value={logo} style={{ maxWidth: 80, maxHeight: 40 }} preview={false} />;
          }}
        />
      )}

      {!columnConfig?.hiddenStatus && (
        <Table.Column
          key="order_status_id"
          title={t("orders.fields.status")}
          render={(value: IOrder) => {
            if (isFetchingOrderStatus) return "loading...";

            return (
              <AppTag
                queryResult={orderStatusData}
                translationPrefix="orders.statuses"
                value={value.order_status_id}
              />
            );
          }}
          defaultSortOrder={getDefaultSortOrder("order_statuses.value", sorter)}
        />
      )}

      {!columnConfig?.hiddenOrderedPrice && (
        <Table.Column
          key="ordered_price"
          dataIndex="ordered_price"
          title={t("orders.fields.orderPrice")}
          sorter
        />
      )}

      {!columnConfig?.hiddenOrderControl && (
        <Table.Column
          key="under_control"
          dataIndex="under_control"
          title={t("orders.fields.control")}
          render={value => <BooleanField value={value} />}
        />
      )}

      {isAdmin && !columnConfig?.hiddenBranch && (
        <Table.Column
          key="branch_id"
          render={(value: IOrder) => {
            if (isFetchingBranchs) return "loading...";
            return branchData?.data.find(p => p.id === value.branch_id)?.name;
          }}
          title={t("orders.fields.branch")}
          sorter
        />
      )}

      {!columnConfig?.hiddenClient && (
        <Table.Column
          key="client_id"
          render={(value: IOrder) => {
            if (isFetchingClients) return "loading...";
            return clientData?.data.find(p => p.id === value.client_id)?.name;
          }}
          title={t("orders.fields.client")}
        />
      )}

      <Table.Column
        key="article_number"
        dataIndex="article_number"
        title={t("orders.fields.articleNumber")}
      />

      {!columnConfig?.hiddenCountry && (
        <Table.Column
          key="clients.country"
          dataIndex={["clients", "country"]}
          title={t("orders.filter.country.label")}
          render={value => (value ? t(`countries.${value}`) : "")}
        />
      )}

      {!columnConfig?.hiddenWeight && (
        <Table.Column
          key="weight"
          dataIndex="weight"
          title={`${t("weight.title")} (${t("weight.kilogram")})`}
          render={value => convertGramsToKilo(value)}
        />
      )}

      {!columnConfig?.hiddenStoragePlace && (
        <Table.Column
          key="storage_place"
          dataIndex="storage_place"
          title={t("orders.fields.storagePlace")}
          sorter
        />
      )}

      {!columnConfig?.hiddenArrivalPrice && (
        <Table.Column
          key="arrival_price"
          dataIndex="arrival_price"
          title={t("orders.fields.arrivalPrice")}
          sorter
        />
      )}

      {!columnConfig?.hiddenCustomsPrice && (
        <Table.Column
          key="customs_price"
          dataIndex="customs_price"
          title={t("orders.fields.customsPrice")}
          sorter
        />
      )}

      {!columnConfig?.hiddenCreatedAt && (
        <Table.Column
          key="created_at"
          dataIndex="created_at"
          title={t("orders.fields.createdAt")}
          render={value => <DateField value={value} format="DD.MM.YYYY HH:mm" />}
          sorter
        />
      )}

      {!columnConfig?.hiddenUpdatedAt && (
        <Table.Column
          key="updated_at"
          dataIndex="updated_at"
          title={t("orders.fields.updatedAt")}
          render={value => (value ? <DateField value={value} format="DD.MM.YYYY HH:mm" /> : "")}
          sorter
        />
      )}

      {enableActions && (
        <Table.Column<IOrder>
          fixed="right"
          title={t("table.actions")}
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_, record) =>
            customAction ? (
              customAction(record)
            ) : (
              <>
                {isOrderEditable(record, isAdmin) && (
                  <Button
                    icon={<EditIcon />}
                    style={{ borderColor: "transparent", background: "transparent" }}
                    onClick={() => {
                      onRecordEdit!(record.id);
                    }}
                  />
                )}
                <ShowButton
                  hideText
                  recordItemId={record.id}
                  icon={<ShowIcon />}
                  style={{ borderColor: "transparent", background: "transparent" }}
                />
              </>
            )
          }
        />
      )}
    </Table>
  );
};
