import { Form, Input } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

export const CreateEditForm = (formProps: any) => {
  const t = useTranslate();

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={{
        isActive: true,
        ...formProps.initialValues,
      }}
    >
      <Form.Item label={t("arrival.additionalInfo.weight.label")} name="weight">
        <Input
          defaultValue={0}
          type="number"
          min={0}
          addonBefore={t("arrival.additionalInfo.weight.suffix")}
          placeholder={t("arrival.additionalInfo.weight.placeholder")}
        />
      </Form.Item>
      <Form.Item name="delivery_id" hidden>
        <Input />
      </Form.Item>
    </Form>
  );
};
