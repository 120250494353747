import { useGetIdentity, useOne } from "@pankod/refine-core";
import { IBranch } from "interfaces";
import { useMemo } from "react";

export const useCurrentUser = () => {
  const { data } = useGetIdentity();

  const branch = useOne<IBranch>({
    resource: "branches",
    id: data?.id,
    queryOptions: { enabled: !!data },
  });

  const isAdmin: boolean = useMemo(() => data?.user_metadata?.isAdmin, [data?.user_metadata]);
  const userId: string = useMemo(() => data?.id, [data?.id]);
  const tenantId: number = useMemo(() => branch.data?.data.tenant_id!, [branch.data?.data.tenant_id]);
  const user: IBranch = useMemo(() => branch.data?.data!, [branch.data?.data]);

  return { isAdmin, userId, tenantId, user };
};
