import { FC } from "react";
import { ButtonProps, Drawer, DrawerProps, Edit, FormProps, Grid } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

import { CreateEditForm } from "./createEditForm";

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  formLoading: boolean;
}

export const EditDelivery: FC<Props> = props => {
  const { drawerProps, formProps, saveButtonProps, formLoading } = props;
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t("deliveries.titles.edit")}
      destroyOnClose
      forceRender
    >
      <Edit
        saveButtonProps={saveButtonProps}
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        title={false}
        resource="deliveries"
        isLoading={formLoading}
      >
        {CreateEditForm(formProps, t, true, !!drawerProps?.open)}
      </Edit>
    </Drawer>
  );
};
