import { FC, useState, cloneElement } from "react";
import { useTranslate } from "@pankod/refine-core";
import { Button, Popover } from "@pankod/refine-antd";

import { FilterIcon } from "assets";

interface Props {
  content: JSX.Element;
}

export const FiltersButton: FC<Props> = props => {
  const { content } = props;
  const t = useTranslate();
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  return (
    <Popover
      title={null}
      trigger="click"
      showArrow={false}
      open={isFiltersOpen}
      onOpenChange={(newOpen: boolean) => setIsFiltersOpen(newOpen)}
      content={cloneElement(content, { closeModal: () => setIsFiltersOpen(false) })}
    >
      <Button
        icon={<FilterIcon style={{ marginRight: "7px" }} />}
        onClick={e => {
          e.preventDefault();
          setIsFiltersOpen(true);
        }}
        style={{ display: "flex", alignItems: "center" }}
      >
        {t("orders.filter.title")}
      </Button>
    </Popover>
  );
};
