import { FC, useState } from "react";
import {
  CrudFilters,
  getDefaultFilter,
  HttpError,
  useDelete,
  useModal,
  useTranslate,
} from "@pankod/refine-core";
import {
  Button,
  Col,
  Form,
  Icons,
  ImageField,
  Input,
  Popconfirm,
  Row,
  Table,
  TextField,
  Typography,
  UrlField,
  useDrawerForm,
  useTable,
} from "@pankod/refine-antd";

import { AddBranchShops } from "./addShops";
import { IBranch, IBranchShops, ICategoryFilterVariables, IShop } from "interfaces";
import { ShopsCategoryFilter } from "./filter";
import { AddIcon, DeleteIcon, EditIcon } from "assets";
import { Colors } from "theme";
import { EditBranchShop } from "./edit";
import { AppEmpty } from "components/appEmpty";
import { localizeCurrency } from "helpers";

interface Props {
  selectorId?: string;
  branch: IBranch | undefined;
  enableActions?: boolean;
}

export const BranchShopsTable: FC<Props> = ({ branch, enableActions = true, selectorId }) => {
  const t = useTranslate();
  const { mutate: deleteShop } = useDelete();
  const [shopNameToEdit, setShopNameToEdit] = useState<string>();

  const {
    tableProps,
    searchFormProps,
    filters,
    tableQueryResult: { refetch },
  } = useTable<IShop, HttpError, ICategoryFilterVariables>({
    resource: "shops",
    syncWithLocation: false,

    permanentFilter: [{ field: "branch_shops.branch_id", operator: "eq", value: branch?.id }],
    metaData: {
      select: "*, tenant_shop_categories!inner(*), branch_shops!inner(*)",
    },
    onSearch: ({ name, categories }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "tenant_shop_categories.category_id",
        operator: "in",
        value: categories?.length > 0 ? categories : undefined,
      });

      filters.push({
        field: "name",
        operator: "contains",
        value: name ? name : undefined,
      });

      return filters;
    },
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<IBranchShops>({
    action: "edit",
    resource: "branch_shops",
    redirect: false,
    onMutationSuccess: () => refetch(),
  });

  const { show: showShopsModal, visible: visibleShopsModal, close: closeShopsModal } = useModal();

  const handleDeleteShop = (id: number) => {
    deleteShop({ resource: "branch_shops", id }, { onSuccess: () => refetch() });
  };

  return (
    <>
      <Form
        {...searchFormProps}
        onValuesChange={() => {
          searchFormProps.form?.submit();
        }}
        initialValues={{
          name: getDefaultFilter("name", filters, "contains"),
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18}>
            <Form.Item name="name">
              <Input
                style={{
                  width: "400px",
                }}
                placeholder="Search"
                suffix={<Icons.SearchOutlined />}
              />
            </Form.Item>
          </Col>
          <Col xs={0} sm={6}>
            {enableActions && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  icon={<AddIcon fill={Colors.MintFocus} style={{ marginRight: "7px" }} />}
                  onClick={() => showShopsModal()}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {t("shops.titles.add")}
                </Button>
              </div>
            )}
          </Col>
        </Row>
        {!enableActions && (
          <Row gutter={[16, 16]}>
            <Form.Item name="categories">
              <ShopsCategoryFilter />
            </Form.Item>
          </Row>
        )}
      </Form>
      <Table
        {...tableProps}
        id={selectorId}
        rowKey="id"
        size="small"
        rowClassName="app-table-row"
        locale={{
          emptyText: (
            <AppEmpty
              description={
                <span style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>
                  {t("table.empty")}
                </span>
              }
            />
          ),
        }}
      >
        <Table.Column
          key="shop_id"
          render={value => (
            <ImageField value={value.logo} style={{ maxWidth: 80, maxHeight: 40 }} preview={false} />
          )}
          title={t("branch_shops.fields.logo")}
        />

        <Table.Column
          key="name"
          render={value => <TextField value={value.name} />}
          title={t("branch_shops.fields.shop")}
        />

        <Table.Column
          key="description"
          title={t("shops.fields.description")}
          render={value => <Typography.Text>{value.description}</Typography.Text>}
        />
        <Table.Column
          key="link"
          dataIndex="link"
          title={t("shops.fields.link")}
          render={value => <UrlField value={value} target="_blank" />}
        />
        <Table.Column
          key="discount"
          dataIndex="branch_shops"
          title={t("branch_shops.fields.discount")}
          render={(value: IBranchShops[]) =>
            value?.length && <TextField value={value[0].discount ? `${value[0].discount} %` : undefined} />
          }
        />
        <Table.Column
          key="shipping_cost"
          dataIndex="branch_shops"
          title={t("branch_shops.fields.shipping_cost")}
          render={(value: IBranchShops[]) =>
            value?.length && (
              <TextField
                value={value[0].shipping_cost ? localizeCurrency(value[0].shipping_cost) : undefined}
              />
            )
          }
        />

        {enableActions && (
          <Table.Column<IShop>
            fixed="right"
            title={t("table.actions")}
            dataIndex="actions"
            key="actions"
            align="center"
            render={(_, record) => (
              <>
                <Button
                  icon={<EditIcon />}
                  style={{ borderColor: "transparent", background: "transparent" }}
                  onClick={() => {
                    setShopNameToEdit(record.name);
                    editShow(record.branch_shops![0].id);
                  }}
                />

                <Popconfirm
                  key="delete"
                  okText={t("buttons.delete")}
                  cancelText={t("buttons.cancel")}
                  okType="danger"
                  title={t("buttons.confirm")}
                  onConfirm={() => handleDeleteShop(record.branch_shops![0].id)}
                >
                  <Button
                    icon={<DeleteIcon />}
                    style={{ borderColor: "transparent", background: "transparent" }}
                  />
                </Popconfirm>
              </>
            )}
          />
        )}
      </Table>
      <EditBranchShop
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
        shopName={shopNameToEdit}
      />
      {enableActions && branch?.id && visibleShopsModal && (
        <AddBranchShops
          visible={visibleShopsModal}
          close={closeShopsModal}
          show={showShopsModal}
          branchId={branch?.id}
          refetch={refetch}
        />
      )}
    </>
  );
};
