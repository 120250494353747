import { FC } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import { ClientsList as ClientsListComponent } from "components/clients";
import { useCurrentUser } from "hooks";

export const ClientsList: FC<IResourceComponentsProps> = () => {
  const { userId } = useCurrentUser();

  return <ClientsListComponent branchId={userId} />;
};
