import { FC } from "react";
import {
  DateField,
  getDefaultSortOrder,
  NumberField,
  Table,
  TextField,
  Button,
  Icons,
  Tag,
  TableProps,
} from "@pankod/refine-antd";
import { CrudSorting, useMany, useNotification, useTranslate } from "@pankod/refine-core";

import { AppTag } from "../appTag";
import { IFinancialTransactions, IPaymentMethod } from "interfaces";
import { useCurrentUser, useGetFileUrl, invoicePath } from "hooks";
import { AppEmpty } from "components/appEmpty";
import { localizeCurrency } from "helpers";

interface Props {
  selectorId?: string;
  tableProps: TableProps<IFinancialTransactions>;
  sorter: CrudSorting | undefined;
}

export const TransactionsTable: FC<Props> = props => {
  const { selectorId, tableProps, sorter } = props;
  const t = useTranslate();
  const { open: openNotification } = useNotification();
  const fileUrl = useGetFileUrl();
  const { tenantId } = useCurrentUser();

  const paymentMethodIds =
    tableProps.dataSource?.filter(p => !!p.payment_method).map(p => p.payment_method.toString()) || [];
  const { data: paymentMethodData, isFetching: isFetchingPaymentMethods } = useMany<IPaymentMethod>({
    resource: "payment_methods",
    ids: paymentMethodIds,
    queryOptions: {
      enabled: paymentMethodIds.length > 0,
    },
  });

  const handleOpenInvoice = async (record: IFinancialTransactions) => {
    const { branch_id, invoice_nr, created_at } = record.invoices;

    const data = await fileUrl(invoicePath(tenantId, branch_id, invoice_nr, created_at));

    if (data.error && openNotification) {
      openNotification({ message: t(data.error.message), type: "error" });
    } else if (data.signedURL) {
      window.open(data.signedURL, "_blank");
    }
  };

  return (
    <Table
      {...tableProps}
      id={selectorId}
      rowKey="id"
      size="small"
      rowClassName="app-table-row"
      locale={{
        emptyText: (
          <AppEmpty
            description={
              <span style={{ fontSize: "18px", fontWeight: "500", color: "black" }}>{t("table.empty")}</span>
            }
          />
        ),
      }}
    >
      <Table.Column
        align="right"
        key="transaction_date"
        dataIndex="transaction_date"
        title={t("branch_balance.fields.transactionDate")}
        sorter
        defaultSortOrder={getDefaultSortOrder("transaction_date.value", sorter)}
        render={value => <DateField value={value} format="DD.MM.YYYY HH:mm" />}
      />
      <Table.Column
        align="center"
        key="payment_method"
        dataIndex="payment_method"
        title={t("branch_balance.fields.paymentMethod")}
        sorter
        render={(id: number) => {
          if (isFetchingPaymentMethods) return "loading...";

          if (id) {
            return (
              <AppTag
                queryResult={paymentMethodData}
                value={id}
                translationPrefix="branch_balance.paymentMethods"
              />
            );
          }
        }}
      />
      <Table.Column
        align="right"
        key="description"
        dataIndex="description"
        title={t("branch_balance.fields.description")}
        sorter
        render={value => <TextField value={value} />}
      />

      <Table.Column
        align="right"
        key="amount"
        dataIndex="amount"
        title={t("branch_balance.fields.amount")}
        sorter
        render={value =>
          value && (
            <Tag color={value >= 0 ? "green" : "white"} style={{ fontSize: 14 }}>
              <TextField
                value={new Intl.NumberFormat("de-DE", {
                  signDisplay: "exceptZero",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(value)}
              />
            </Tag>
          )
        }
      />
      <Table.Column
        align="right"
        key="balance"
        dataIndex="balance"
        title={t("branch_balance.fields.balance")}
        sorter
        render={value => value && <TextField value={localizeCurrency(value)} />}
      />
      <Table.Column
        align="right"
        key="note"
        dataIndex="note"
        title={t("branch_balance.fields.note")}
        sorter
        render={value => <TextField value={value} />}
      />
      <Table.Column
        fixed="right"
        title={t("table.actions")}
        dataIndex="invoice_id"
        key="actions"
        align="center"
        render={(invoiceId, record) =>
          invoiceId && (
            <Button
              icon={<Icons.FilePdfOutlined />}
              onClick={() => handleOpenInvoice(record as IFinancialTransactions)}
            />
          )
        }
      />
    </Table>
  );
};
