import { useState } from "react";
import {
  Row,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  DatePicker,
  getValueFromEvent,
  Upload,
  Icons,
  Steps,
  Grid,
  StepProps,
} from "@pankod/refine-antd";
import { useNavigation, useTranslate, useUpdate, useUpdatePassword, useList } from "@pankod/refine-core";
import dayjs from "dayjs";

import "./styles.css";
import { AppPhoneInput } from "components/appPhoneInput";
import { SuccessIcon } from "assets";
import { useCurrentUser, useUploadFile, avatarPath } from "hooks";
import { IBranch, IClient } from "interfaces";

const { Title } = Typography;

interface IRegisterForm {
  name: string;
  avatar?: any;
  password: string;
  confirmPassword: string;
  phone: string;
  address: string;
  city: string;
  zipcode: string;
  birthday?: Date;
  passport_nr: string;
}

export const Register = () => {
  const [form] = Form.useForm<IRegisterForm>();
  const t = useTranslate();
  const { mutate: updatePassword } = useUpdatePassword();
  const { mutate } = useUpdate();
  const { upload } = useUploadFile();
  const { replace } = useNavigation();
  const { userId, tenantId } = useCurrentUser();

  const [current, setCurrent] = useState(0);

  const { data: clients } = useList<IClient>({
    resource: "clients",
    queryOptions: { enabled: !!userId },
    config: {
      filters: [
        {
          field: "branch_id",
          operator: "eq",
          value: userId,
        },
      ],
    },
  });

  const screens = Grid.useBreakpoint();
  const currentBreakPoints = Object.entries(screens)
    .filter(screen => !!screen[1])
    .map(screen => screen[0]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const defineSubmitButtonTitle = () => {
    if (current === 1) {
      return t("buttons.register");
    }

    return t("buttons.continue");
  };

  const handleNextStep = () => {
    if (current === 0) {
      form.validateFields(["name", "password", "confirmPassword", "phone"]).then(() => next());
    }
    if (current === 1) {
      form.validateFields(["address", "city", "zipcode"]).then(() => form.submit());
    }
  };

  const handleOnFinish = (values: IRegisterForm) => {
    const { password, avatar, confirmPassword, ...rest } = values;
    updatePassword({ password });

    if (avatar?.length > 0) {
      upload(avatarPath(userId, tenantId), avatar[0].originFileObj, true);
    }

    const branchInfo: Partial<IBranch> = {
      ...rest,
      last_balance_verification_date: dayjs(),
      next_balance_verification_date: dayjs().add(4, "week"),
    };

    mutate(
      {
        resource: "branches",
        id: userId,
        values: branchInfo,
        successNotification: false,
      },
      {
        onSuccess: () => {
          const { birthday, ...client } = rest;
          mutate(
            {
              resource: "clients",
              id: clients?.data[0].id!,
              values: { ...client, is_branch: true },
              successNotification: false,
            },
            {
              onSuccess: () => replace("/"),
            }
          );
        },
      }
    );
  };

  const defineStepIcon = (stepNumber: number) => {
    if (current > stepNumber) {
      return <SuccessIcon />;
    }

    return undefined;
  };

  const createStepItems = (): StepProps[] => [
    { title: t("pages.register.step1"), icon: defineStepIcon(0) },
    { title: t("pages.register.step2"), icon: defineStepIcon(1) },
  ];

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <div
          style={{
            maxWidth: 460,
            borderBottom: "1px solid white",
            marginBottom: 60,
            paddingInline: 30,
          }}
        >
          <img src={"/images/logo-white.svg"} alt="flexibuy logo" style={{ marginBottom: 20 }} width="100%" />
        </div>
        <Card
          title={
            <Title level={3} className="title">
              {t("pages.register.title")}
            </Title>
          }
          headStyle={{ borderBottom: 0 }}
          style={{ minWidth: "30%" }}
          bodyStyle={{ paddingTop: 0 }}
        >
          <Form<IRegisterForm> layout="vertical" form={form} onFinish={handleOnFinish}>
            <Form.Item
              name="name"
              label={t("branches.fields.name")}
              rules={[{ required: true }]}
              hidden={current !== 0}
            >
              <Input placeholder={t("branches.fields.name")} />
            </Form.Item>
            <Form.Item
              name="avatar"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
              label={t("branches.fields.avatar")}
              hidden={current !== 0}
            >
              <Upload listType="picture" maxCount={1} accept="image/*" beforeUpload={() => false}>
                <Button icon={<Icons.UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              name="password"
              label={t("branches.fields.password")}
              rules={[{ required: true }]}
              hidden={current !== 0}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label={t("branches.fields.confirmPassword")}
              dependencies={["password"]}
              hidden={current !== 0}
              validateTrigger={"onBlur"}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("pages.register.errors.passwordMismatch")));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t("clients.phone")}
              name="phone"
              rules={[{ required: true }]}
              hidden={current !== 0}
            >
              <AppPhoneInput />
            </Form.Item>

            <Form.Item
              name="address"
              label={t("branches.fields.address")}
              rules={[{ required: true }]}
              hidden={current !== 1}
            >
              <Input placeholder={t("branches.fields.address")} />
            </Form.Item>
            <Form.Item
              name="city"
              label={t("branches.fields.city")}
              rules={[{ required: true }]}
              hidden={current !== 1}
            >
              <Input placeholder={t("branches.fields.city")} />
            </Form.Item>
            <Form.Item
              name="zipcode"
              label={t("branches.fields.zipcode")}
              rules={[{ required: true }]}
              hidden={current !== 1}
            >
              <Input placeholder={t("branches.fields.zipcode")} />
            </Form.Item>
            <Form.Item
              name="passport_nr"
              label={t("branches.fields.passportNr.label")}
              rules={[{ required: true }]}
              hidden={current !== 1}
            >
              <Input placeholder={t("branches.fields.passportNr.placeholder")} />
            </Form.Item>
            <Form.Item name="birthday" label={t("branches.fields.birthday")} hidden={current !== 1}>
              <DatePicker placeholder={t("branches.fields.birthday")} style={{ width: "100%" }} />
            </Form.Item>

            <div className="buttonContainer">
              {current >= 1 && (
                <Button type="primary" style={{ marginRight: 10 }} onClick={prev}>
                  {t("buttons.back")}
                </Button>
              )}
              <Button type="primary" block={!current} onClick={handleNextStep}>
                {defineSubmitButtonTitle()}
              </Button>
            </div>
          </Form>
          <Steps
            current={current}
            direction={currentBreakPoints.includes("lg") ? "horizontal" : "vertical"}
            items={createStepItems()}
            style={{ paddingTop: 20 }}
          />
        </Card>
      </Row>
    </AntdLayout>
  );
};
