import { FC, useState } from "react";
import {
  AntdLayout,
  Menu,
  Grid,
  Icons,
  Drawer,
  Button,
  Divider,
  Typography,
  RefineLayoutSiderProps,
} from "@pankod/refine-antd";
import { useTranslate, CanAccess, ITreeMenu, useRouterContext, useMenu } from "@pankod/refine-core";

import { Title } from "../title";
import { drawerButtonStyles } from "./styles";
import { Colors } from "theme";
import { DashboardIcon } from "assets";

const { UnorderedListOutlined, BarsOutlined } = Icons;
const { Text } = Typography;

export const Sider: FC<RefineLayoutSiderProps> = () => {
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const { Link } = useRouterContext();
  const { REACT_APP_BUILD_ID, REACT_APP_BUILD_DATE } = process.env;
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const isMobile = typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const renderItemsForResources = (tree: ITreeMenu[], selectedKey: string) =>
    tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item;
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);

      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <Menu.Item
            key={route}
            style={{
              fontSize: "20px",
              fontWeight: isSelected ? "600" : "400",
              marginBottom: "15px",
              paddingInline: "15px",
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route}>{t(label!)}</Link>
            {!collapsed && isSelected && <div className="ant-menu-tree-arrow" />}
          </Menu.Item>
        </CanAccess>
      );
    });

  const version = !collapsed ? (
    <Menu.Item
      key="version"
      style={{
        fontSize: "14px",
        fontWeight: "600",
        color: Colors.White,
        backgroundColor: "transparent",
        paddingInline: "15px",
        position: "fixed",
        bottom: "50px",
      }}
    >
      <Text style={{ color: Colors.White }}>{`${t("common.version")}: ${REACT_APP_BUILD_DATE ?? ""} (${
        REACT_APP_BUILD_ID ? REACT_APP_BUILD_ID.substring(0, 5) : t("common.notSpecified")
      })`}</Text>
    </Menu.Item>
  ) : null;

  const dashboard = (
    <Menu.Item
      key="dashboard"
      style={{
        fontSize: "20px",
        fontWeight: selectedKey === "/" ? "600" : "400",
        color: selectedKey === "/" ? Colors.NavyBlue : Colors.White,
        backgroundColor: selectedKey === "/" ? Colors.MintMain : "transparent",
        marginBottom: "15px",
        paddingInline: "15px",
      }}
      icon={<DashboardIcon />}
    >
      <Link to="/">{t("dashboard.title", "Dashboard")}</Link>
      {!collapsed && selectedKey === "/" && <div className="ant-menu-tree-arrow" />}
    </Menu.Item>
  );

  const renderMenu = () => (
    <Menu
      selectedKeys={[selectedKey]}
      defaultOpenKeys={defaultOpenKeys}
      mode="inline"
      onClick={() => {
        setDrawerOpen(false);
        if (!breakpoint.lg) {
          setCollapsed(true);
        }
      }}
    >
      {dashboard}
      {renderItemsForResources(menuItems, selectedKey)}
      {version}
    </Menu>
  );

  const renderDrawerSider = () => (
    <>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        placement="left"
        closable={false}
        width={200}
        bodyStyle={{
          padding: 0,
        }}
        maskClosable={true}
      >
        <AntdLayout>
          <AntdLayout.Sider style={{ height: "100vh", overflow: "hidden" }}>
            <Title collapsed={false} />
            {renderMenu()}
          </AntdLayout.Sider>
        </AntdLayout>
      </Drawer>
      <Button
        style={drawerButtonStyles}
        size="large"
        onClick={() => setDrawerOpen(true)}
        icon={<BarsOutlined />}
      ></Button>
    </>
  );

  if (isMobile) {
    return renderDrawerSider();
  }

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={80}
      breakpoint="lg"
      width={250}
    >
      <Title collapsed={collapsed} />
      <Divider
        style={{
          width: "unset",
          minWidth: "unset",
          backgroundColor: Colors.MintMain,
          margin: "0px 20px 25px 20px",
        }}
      />

      {renderMenu()}
    </AntdLayout.Sider>
  );
};
