import { FC } from "react";
import { CrudFilters, getDefaultFilter, useTranslate } from "@pankod/refine-core";
import { Button, Col, DatePicker, Form, FormProps, Input, Row, Select, useSelect } from "@pankod/refine-antd";

import { IFinancialTransactionFilterVariables, IPaymentMethod } from "interfaces";
import { Colors } from "theme";

const TRANSACTION_TYPES = [
  { label: "Incoming", value: 1 },
  { label: "Outgoing", value: 2 },
  { label: "Correction", value: 3 },
];

interface Props {
  formProps: FormProps;
  filters: CrudFilters;
  closeModal?: () => void;
}

export const createOnSearchFilter = (params: IFinancialTransactionFilterVariables) => {
  const filters: CrudFilters = [];
  const { transaction_type, payment_method, transaction_date, description, note } = params;

  filters.push({
    field: "transaction_type",
    operator: "eq",
    value: transaction_type,
  });

  filters.push({
    field: "payment_method",
    operator: "eq",
    value: payment_method,
  });

  filters.push({
    field: "description",
    operator: "contains",
    value: description,
  });

  filters.push({
    field: "note",
    operator: "contains",
    value: note,
  });

  filters.push(
    {
      field: "transaction_date",
      operator: "gte",
      value: transaction_date ? transaction_date[0].startOf("day").toISOString() : undefined,
    },
    {
      field: "transaction_date",
      operator: "lte",
      value: transaction_date ? transaction_date[1].endOf("day").toISOString() : undefined,
    }
  );

  return filters;
};

export const TransactionsFilter: FC<Props> = props => {
  const t = useTranslate();

  const { formProps, filters, closeModal } = props;
  const { RangePicker } = DatePicker;

  const { selectProps: paymentMethodProps } = useSelect<IPaymentMethod>({
    resource: "payment_methods",
    optionLabel: "value",
    optionValue: "id",
    defaultValue: getDefaultFilter("payment_method", filters),
  });

  const handleOnFinish = (values: any) => {
    formProps.onFinish!(values);
    closeModal!();
  };

  const handleReset = () => formProps.form?.resetFields();

  return (
    <Form layout="vertical" {...formProps} onFinish={handleOnFinish}>
      <Row gutter={[10, 0]} align="bottom" style={{ maxWidth: "344px" }}>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("branch_balance.filter.type.label")} name="transaction_type">
            <Select
              allowClear
              placeholder={t("branch_balance.filter.type.placeholder")}
              options={TRANSACTION_TYPES.map(({ value, label }) => ({
                value,
                label: t(`branch_balance.transactionType.${label}`),
              }))}
            />
          </Form.Item>
        </Col>

        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("branch_balance.filter.payment_method.label")} name="payment_method">
            <Select
              {...paymentMethodProps}
              options={paymentMethodProps.options?.map(({ value, label }) => ({
                value,
                label: t(`branch_balance.paymentMethods.${label}`),
              }))}
              allowClear
              mode="multiple"
              placeholder={t("branch_balance.filter.payment_method.placeholder")}
            />
          </Form.Item>
        </Col>

        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("branch_balance.filter.description.label")} name="description">
            <Input placeholder={t("branch_balance.filter.description.placeholder")} allowClear />
          </Form.Item>
        </Col>

        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("branch_balance.filter.note.label")} name="note">
            <Input placeholder={t("branch_balance.filter.note.placeholder")} allowClear />
          </Form.Item>
        </Col>

        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("branch_balance.fields.transactionDate")} name="transaction_date">
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row
        justify="end"
        align="bottom"
        style={{ width: "100%", borderTop: `1px solid ${Colors.MintHover}`, paddingTop: "20px" }}
      >
        <Col>
          <Button htmlType="button" style={{ marginRight: "17px" }} onClick={handleReset}>
            {t("buttons.reset")}
          </Button>
        </Col>
        <Col>
          <Form.Item noStyle>
            <Button htmlType="submit" type="primary">
              {t("buttons.apply")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
