import { FC, Key, useCallback, useMemo, useState } from "react";
import {
  HttpError,
  IResourceComponentsProps,
  useList,
  useModal,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { Button, List, useTable } from "@pankod/refine-antd";

import { IOrder, IOrderFilterVariables } from "interfaces";
import { OrderFilter, OrderTable, createOnSearchFilter } from "components/orders";
import { OrderStatus } from "../../constants";
import { FiltersButton } from "components/appFilterButton";
import { useCurrentUser } from "hooks";
import { localizeCurrency, localizeWeight } from "helpers";
import { BalanceIcon, ScaleIcon } from "assets";
import { AddOrderToDeliveryModal } from "./addOrdersToDeliveryModal";

export const StockList: FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { isAdmin } = useCurrentUser();
  const { mutate: updateMutation } = useUpdate();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<IOrder[]>([]);

  const { show, visible, close } = useModal();

  const { tableProps, sorter, searchFormProps, filters } = useTable<IOrder, HttpError, IOrderFilterVariables>(
    {
      resource: "orders",
      onSearch: params => createOnSearchFilter(params),
      hasPagination: true,
      permanentFilter: [
        {
          field: "order_status_id",
          operator: "in",
          value: [OrderStatus.InStock, OrderStatus.HeldInStock],
        },
      ],
      initialSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      metaData: {
        select: "*, clients!inner(country)",
      },
    }
  );

  // TODO: will update.
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: Key[], selectedRows: IOrder[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const hasSelected = selectedRowKeys.length > 0;

  const { data: allOrders } = useList<IOrder>({
    resource: "orders",
    config: {
      filters,
      hasPagination: false,
    },
    metaData: {
      select: "*, clients!inner(country)",
    },
  });

  const { totalPrice, totalWeight } = useMemo(() => {
    if (allOrders?.data.length) {
      const { totalPrice, totalWeight } = allOrders?.data.reduce(
        (acc: any, current: any): any => {
          if (current.arrival_price) {
            acc.totalPrice += current.arrival_price;
          }

          if (current.weight) {
            acc.totalWeight += current.weight;
          }
          return acc;
        },
        {
          totalPrice: 0,
          totalWeight: 0,
        }
      );

      return {
        totalPrice: localizeCurrency(totalPrice ?? 0),
        totalWeight: localizeWeight(totalWeight ?? 0),
      };
    }

    return {
      totalPrice: localizeCurrency(0),
      totalWeight: localizeWeight(0),
    };
  }, [allOrders]);

  const updateStatus = useCallback(
    (record: IOrder) => {
      updateMutation({
        resource: "orders",
        id: record.id,
        values: {
          order_status_id: OrderStatus.InStock,
        },
      });
    },
    [updateMutation]
  );

  const renderVerifyOrderAction = useCallback(
    (record: IOrder) => {
      if (record.order_status_id === OrderStatus.HeldInStock) {
        return (
          <Button type="primary" onClick={() => updateStatus(record)}>
            {t("buttons.orderVerified")}
          </Button>
        );
      }
      return null;
    },
    [t, updateStatus]
  );

  return (
    <List
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <BalanceIcon style={{ marginRight: "5px" }} />
          <h4 style={{ marginBottom: 0, fontWeight: 600 }}>{`${t("stock.totalCost")}: ${totalPrice}`}</h4>
          <ScaleIcon style={{ marginInline: "5px" }} />
          <h4 style={{ marginBottom: 0, fontWeight: 600 }}>{`${t("stock.totalWeight")}: ${totalWeight}`}</h4>
        </div>
      }
      headerButtons={() => (
        <>
          {hasSelected && (
            <Button type="primary" onClick={() => show()}>
              {t("deliveries.addToDelivery.addToDelivery")}
            </Button>
          )}
          <FiltersButton
            content={
              <OrderFilter
                isAdmin={isAdmin}
                formProps={searchFormProps}
                filters={filters || []}
                fieldsConfig={{ hiddenStatus: true, hiddenCreatedAt: true }}
              />
            }
          />
        </>
      )}
    >
      <OrderTable
        tableProps={tableProps}
        sorter={sorter}
        enableActions
        customAction={renderVerifyOrderAction}
        columnConfig={{
          hiddenUpdatedAt: true,
          hiddenCreatedAt: true,
          hiddenOrderedPrice: true,
        }}
      />
      {hasSelected && visible && (
        <AddOrderToDeliveryModal visible={visible} close={close} show={show} selectedRows={selectedRows} />
      )}
    </List>
  );
};
