import { useState, useEffect } from "react";
import { Form, useSelect, Select, Input } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

import { IBranch, IClient } from "interfaces";

export const CreateEditForm = (formProps: any, isEdit: boolean = false) => {
  const t = useTranslate();

  const [selectedBranchId, setSelectedBranchId] = useState<string>();

  const { selectProps: branchSelectProps } = useSelect<IBranch>({
    resource: "branches",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: clientSelectProps } = useSelect<IClient>({
    resource: "clients",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 100,
    filters: [
      {
        field: "branch_id",
        operator: "eq",
        value: selectedBranchId,
      },
    ],
    queryOptions: {
      enabled: !!selectedBranchId,
    },
  });

  /**
   * Clear client field if branch was chaned.
   * Technical notes: form.reset() sets the initial value only.
   */
  const onChangeUser = (branch: any) => {
    setSelectedBranchId(branch);
    formProps.form?.setFieldValue("client_id", null);
  };

  useEffect(() => {
    if (isEdit && formProps.initialValues?.branch_id) {
      setSelectedBranchId(formProps.initialValues?.branch_id);
    }
  }, [formProps.initialValues?.branch_id, isEdit]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={{
        isActive: true,
        ...formProps.initialValues,
      }}
    >
      <Form.Item label={t("orders.fields.branch")} name="branch_id" rules={[{ required: true }]}>
        <Select {...branchSelectProps} placeholder={t("deliveries.fields.branch")} onChange={onChangeUser} />
      </Form.Item>
      <Form.Item label={t("orders.fields.client")} name="client_id" rules={[{ required: true }]}>
        <Select {...clientSelectProps} placeholder={t("orders.fields.client")} />
      </Form.Item>
      <Form.Item name="container_id" hidden>
        <Input />
      </Form.Item>
    </Form>
  );
};
