import { FC } from "react";
import { CrudFilters, getDefaultFilter, useGetLocale, useTranslate } from "@pankod/refine-core";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  useSelect,
} from "@pankod/refine-antd";

import { IOrderFilterVariables, IOrderStatus, IShop, IBranch, IClient } from "interfaces";
import { Colors } from "theme";
import { countries } from "helpers/countries";

export const createOnSearchFilter = (params: IOrderFilterVariables) => {
  const filters: CrudFilters = [];
  const {
    article_number,
    shop_id,
    branch_id,
    client_id,
    created_at,
    order_status_id,
    name,
    under_control,
    arrival_price,
    clients,
  } = params;

  filters.push({
    field: "name",
    operator: "contains",
    value: name,
  });
  filters.push({
    field: "article_number",
    operator: "contains",
    value: article_number,
  });
  filters.push({
    field: "shop_id",
    operator: "eq",
    value: shop_id,
  });
  filters.push({
    field: "branch_id",
    operator: "eq",
    value: branch_id,
  });
  filters.push({
    field: "client_id",
    operator: "eq",
    value: client_id,
  });
  filters.push({
    field: "order_status_id",
    operator: "in",
    value: order_status_id?.length! > 0 ? order_status_id : undefined,
  });
  filters.push(
    {
      field: "created_at",
      operator: "gte",
      value: created_at ? created_at[0].startOf("day").toISOString() : undefined,
    },
    {
      field: "created_at",
      operator: "lte",
      value: created_at ? created_at[1].endOf("day").toISOString() : undefined,
    }
  );
  filters.push(
    {
      field: "arrival_price",
      operator: "gte",
      value: arrival_price?.arrival_price_from,
    },
    {
      field: "arrival_price",
      operator: "lte",
      value: arrival_price?.arrival_price_to,
    }
  );
  filters.push({
    field: "under_control",
    operator: "eq",
    value: under_control,
  });
  filters.push({
    field: "clients.country",
    operator: "eq",
    value: clients?.country ? clients.country : undefined,
  });

  return filters;
};

interface IFieldsConfig {
  hiddenStatus?: boolean;
  hiddenShop?: boolean;
  hiddenCreatedAt?: boolean;
  hiddenArrivalPrice?: boolean;
  hiddenCountryField?: boolean;
}

interface Props {
  formProps: FormProps;
  filters: CrudFilters;
  isAdmin: boolean;
  closeModal?: () => void;
  fieldsConfig?: IFieldsConfig;
}

export const OrderFilter: FC<Props> = props => {
  const t = useTranslate();
  const locale = useGetLocale();
  const options = countries(locale() ?? "en");
  const { formProps, filters, isAdmin, closeModal, fieldsConfig } = props;
  const { selectProps: shopSelectProps } = useSelect<IShop>({
    resource: "shops",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: getDefaultFilter("shop_id", filters),
    queryOptions: {
      enabled: !fieldsConfig?.hiddenShop,
    },
  });

  const { selectProps: orderSelectProps } = useSelect<IOrderStatus>({
    resource: "order_statuses",
    optionLabel: "value",
    optionValue: "id",
    defaultValue: getDefaultFilter("order_status_id", filters),
    queryOptions: {
      enabled: !fieldsConfig?.hiddenStatus,
    },
    fetchSize: 30,
  });

  const { selectProps: branchSelectProps } = useSelect<IBranch>({
    resource: "branches",
    optionLabel: "name",
    defaultValue: getDefaultFilter("branch_id", filters),
    queryOptions: {
      enabled: !!isAdmin,
    },
  });

  const { selectProps: clientSelectProps } = useSelect<IClient>({
    resource: "clients",
    optionLabel: "name",
    fetchSize: 100,
    defaultValue: getDefaultFilter("client_id", filters),
  });

  const { RangePicker } = DatePicker;

  const handleOnFinish = (values: any) => {
    formProps.onFinish!(values);
    closeModal!();
  };

  const handleReset = () => formProps.form?.resetFields();

  return (
    <Form layout="vertical" {...formProps} onFinish={handleOnFinish}>
      <Row gutter={[10, 0]} align="bottom" style={{ maxWidth: "344px" }}>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("orders.filter.name.label")} name="name">
            <Input placeholder={t("orders.filter.name.placeholder")} allowClear />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("orders.filter.articleNumber.label")} name="article_number">
            <Input placeholder={t("orders.filter.articleNumber.placeholder")} allowClear />
          </Form.Item>
        </Col>
        {!fieldsConfig?.hiddenStatus && (
          <Col xl={24} md={8} sm={12} xs={24}>
            <Form.Item label={t("orders.filter.status.label")} name="order_status_id">
              <Select
                {...orderSelectProps}
                options={orderSelectProps.options?.map(({ value, label }) => ({
                  value,
                  label: t(`orders.statuses.${label}`),
                }))}
                allowClear
                mode="multiple"
                placeholder={t("orders.filter.status.placeholder")}
              />
            </Form.Item>
          </Col>
        )}
        {!fieldsConfig?.hiddenShop && (
          <Col xl={24} md={8} sm={12} xs={24}>
            <Form.Item label={t("orders.filter.shop.label")} name="shop_id">
              <Select {...shopSelectProps} allowClear placeholder={t("orders.filter.shop.placeholder")} />
            </Form.Item>
          </Col>
        )}
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item
            label={t("orders.filter.underControl.label")}
            name="under_control"
            valuePropName="checked"
          >
            <Checkbox>{t("orders.filter.underControl.placeholder")}</Checkbox>
          </Form.Item>
        </Col>
        {isAdmin && (
          <Col xl={24} md={8} sm={12} xs={24}>
            <Form.Item label={t("orders.filter.branch.label")} name="branch_id">
              <Select {...branchSelectProps} allowClear placeholder={t("orders.filter.branch.placeholder")} />
            </Form.Item>
          </Col>
        )}
        {!fieldsConfig?.hiddenCountryField && (
          <Col xl={24} md={8} sm={12} xs={24}>
            <Form.Item label={t("orders.filter.country.label")} name={["clients", "country"]}>
              <Select
                options={options}
                placeholder={t("orders.filter.country.placeholder")}
                allowClear
                showSearch
              />
            </Form.Item>
          </Col>
        )}
        {
          <Col xl={24} md={8} sm={12} xs={24}>
            <Form.Item label={t("orders.filter.client.label")} name="client_id">
              <Select {...clientSelectProps} allowClear placeholder={t("orders.filter.client.placeholder")} />
            </Form.Item>
          </Col>
        }
        {!fieldsConfig?.hiddenCreatedAt && (
          <Col xl={24} md={8} sm={12} xs={24}>
            <Form.Item label={t("orders.filter.createdAt.label")} name="created_at">
              <RangePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        )}
        {!fieldsConfig?.hiddenArrivalPrice && (
          <Col
            xl={24}
            md={8}
            sm={12}
            xs={24}
            style={{ display: "flex", flexDirection: "row", alignItems: "end" }}
          >
            <Form.Item
              label={t("orders.filter.arrivalPrice.label")}
              name={["arrival_price", "arrival_price_from"]}
              style={{ width: "50%", marginRight: 10 }}
            >
              <InputNumber addonBefore={t("orders.filter.arrivalPrice.from")} min={0} />
            </Form.Item>

            <Form.Item name={["arrival_price", "arrival_price_to"]} style={{ width: "50%", marginLeft: 10 }}>
              <InputNumber addonBefore={t("orders.filter.arrivalPrice.to")} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row
        justify="end"
        align="bottom"
        style={{ width: "100%", borderTop: `1px solid ${Colors.MintHover}`, paddingTop: "20px" }}
      >
        <Col>
          <Button style={{ marginRight: "17px" }} onClick={handleReset}>
            {t("buttons.reset")}
          </Button>
        </Col>
        <Col>
          <Form.Item noStyle>
            <Button htmlType="submit" type="primary">
              {t("buttons.apply")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
