import { FC, useEffect } from "react";
import { ButtonProps, Drawer, DrawerProps, Create, FormProps, Grid } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

import { CreateEditForm } from "./createEditForm";

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  containerId: number;
}

export const CreatePackage: FC<Props> = props => {
  const { drawerProps, formProps, saveButtonProps, containerId } = props;
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();

  useEffect(() => {
    if (drawerProps.open) {
      formProps.form?.setFieldValue("container_id", containerId);
    }
  }, [drawerProps.open, formProps.form, containerId]);

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t("packages.titles.create")}
      destroyOnClose
      forceRender
    >
      <Create
        resource="packages"
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        title={false}
        saveButtonProps={saveButtonProps}
      >
        {CreateEditForm(formProps, false)}
      </Create>
    </Drawer>
  );
};
