import { useMemo } from "react";
import { useList } from "@pankod/refine-core";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Title,
} from "chart.js";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";

import { IFinancialTransactions } from "interfaces";
import { useTranslation } from "react-i18next";
import { localizeCurrency } from "helpers";

dayjs.extend(localeData);
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Title);
ChartJS.defaults.backgroundColor = "#DFEBE9";
ChartJS.defaults.borderColor = "#DFEBE9";
ChartJS.defaults.color = "#DFEBE9";

export const BalanceLineChart = () => {
  const { t } = useTranslation();

  const { data: transactions } = useList<IFinancialTransactions>({
    resource: "financial_transactions",
    config: {
      sort: [{ field: "transaction_date", order: "asc" }],
    },
  });

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        title: {
          display: true,
          position: "top" as const,
          align: "start" as const,
          text: `${t("dashboard.currentBalance")}: ${localizeCurrency(
            transactions?.data[transactions?.data.length - 1]?.balance ?? 0
          )}`,
          font: { size: 18, weight: "700" },
          padding: { bottom: 20 },
        },
      },
    }),
    [t, transactions?.data]
  );

  const chartData = useMemo(() => {
    const data = transactions?.data
      .map(transaction => ({
        balance: transaction.balance,
        transactionDate: dayjs(transaction.transaction_date).format("L"),
      }))
      .slice(-7);

    return {
      labels: data?.map(row => row.transactionDate),
      datasets: [
        {
          data: data?.map(row => row.balance),
          tension: 0.3,
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions?.data, t]);

  return <Line options={options} data={chartData} />;
};
