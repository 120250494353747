import { FC } from "react";
import {
  ButtonProps,
  Drawer,
  DrawerProps,
  Edit,
  Form,
  FormProps,
  Grid,
  InputNumber,
  Input,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  shopName: string | undefined;
}

export const EditBranchShop: FC<Props> = props => {
  const { drawerProps, formProps, saveButtonProps, shopName } = props;
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t("shops.titles.edit")}
    >
      <Edit
        saveButtonProps={saveButtonProps}
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        title={false}
        resource="branch_shops"
      >
        <Form {...formProps} layout="vertical">
          <div style={{ marginBottom: "24px" }}>
            <label>{t("branch_shops.fields.shop")}</label>
            <Input disabled style={{ width: "100%", marginTop: "8px" }} value={shopName} />
          </div>

          <Form.Item label={t("branch_shops.fields.discount")} name="discount">
            <InputNumber addonBefore="%" max={100} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label={t("branch_shops.fields.shipping_cost")} name="shipping_cost">
            <InputNumber addonBefore="€" style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Edit>
    </Drawer>
  );
};
