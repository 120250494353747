import { FC } from "react";
import { HttpError, IResourceComponentsProps, useTranslate, useUpdateMany } from "@pankod/refine-core";
import { List, useDrawerForm, useTable } from "@pankod/refine-antd";

import { IContainer, IDelivery, IInvoice, IOrder, IPackage } from "interfaces";
import { useCurrentUser } from "hooks";
import { CreateDelivery, EditDelivery, DeliveriesTable } from "components/deliveries";
import { AppCreateButton } from "components/appCreateButton";
import { DeliveryStatus, OrderStatus } from "../../constants";

export const DeliveryList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IDelivery, HttpError>({
    initialSorter: [
      {
        field: "delivery_nr",
        order: "desc",
      },
    ],
  });

  const t = useTranslate();
  const { isAdmin } = useCurrentUser();
  const { mutate: updateData } = useUpdateMany();

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
    formLoading: editFormLoading,
  } = useDrawerForm<IDelivery>({
    action: "edit",
    resource: "deliveries",
    redirect: false,
    metaData: { select: "*, containers(packages(orders(id))), invoices(id, cancelled)" },
    onMutationSuccess: (data, variables: any) => {
      const orders = (editFormProps.initialValues as any).containers
        .flatMap((container: IContainer) => container.packages)
        .flatMap((pkg: IPackage) => pkg?.orders);

      updateData({
        resource: "orders",
        ids: orders.map((order: IOrder) => order!.id),
        values: {
          order_status_id:
            data.data.delivery_status_id === DeliveryStatus.Delivered
              ? OrderStatus.Delivered
              : data.data.delivery_status_id === DeliveryStatus.Shipped
              ? OrderStatus.Shipped
              : OrderStatus.BeingPacked,
        },
        successNotification: false,
      });

      // Cancel all availabe invoices on delivery edit.
      if (
        (editFormProps.initialValues as any).invoices.length &&
        (editFormProps.initialValues as any).delivery_status_id !== variables?.delivery_status_id
      ) {
        updateData({
          resource: "invoices",
          ids: (editFormProps.initialValues as any).invoices
            .filter((invoice: IInvoice) => !invoice.cancelled)
            .map((invoice: IInvoice) => invoice.id),
          values: { cancelled: true, cancelled_date: new Date() },
          successNotification: false,
        });
      }
    },
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
    formLoading: createFormLoading,
  } = useDrawerForm<IDelivery>({
    action: "create",
    resource: "deliveries",
    redirect: false,
    onMutationSuccess: () => createFormProps.form.resetFields(),
  });

  return (
    <>
      <List
        title={false}
        headerButtons={() => (
          <>
            {isAdmin && <AppCreateButton onClick={() => createShow()}>{t("buttons.create")}</AppCreateButton>}
          </>
        )}
      >
        <DeliveriesTable
          tableProps={tableProps}
          onRecordEdit={editShow}
          columnConfig={{ hiddenUpdatedAt: true }}
        />
      </List>
      <EditDelivery
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
        formLoading={editFormLoading}
      />
      <CreateDelivery
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        formLoading={createFormLoading}
      />
    </>
  );
};
