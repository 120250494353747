import { FC, useCallback, useEffect } from "react";
import { ButtonProps, Drawer, DrawerProps, Edit, FormProps, Grid } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

import { CreateEditForm } from "./createEditForm";
import { ITenantShopCategory } from "interfaces";

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  setShopCategories: (categories: number[]) => void;
  tenantId: number | undefined;
}

export const EditShop: FC<Props> = props => {
  const { drawerProps, formProps, saveButtonProps, setShopCategories, tenantId } = props;
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t("shops.titles.edit")}
      destroyOnClose
      forceRender
    >
      <Edit
        resource="shops"
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        title={false}
        saveButtonProps={saveButtonProps}
      >
        <CreateEditForm
          formProps={formProps}
          isEdit={true}
          setShopCategories={(data: number[]) => setShopCategories(data)}
          tenantId={tenantId}
        />
      </Edit>
    </Drawer>
  );
};
