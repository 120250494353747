import { FC } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { IDelivery } from "interfaces";
import dayjs from "dayjs";

import {
  calcOrdersTotalWeight,
  calcOrdersTotalPriceWithDiscount,
  calcPriceAfterDiscountForOrder,
  calcTotalWeightOfEmptyContainers,
} from "../utils";
import { localizeCurrency } from "helpers";

interface Props {
  delivery: IDelivery;
}

export const PackingList: FC<Props> = props => {
  const { delivery } = props;
  const { airdepartment_nr, tenants, shipment_date, delivery_nr, containers } = delivery;

  const packages = containers?.flatMap(container => container.packages);
  const allOrders = packages?.flatMap(pkg => pkg!.orders!);

  const calcInvoiceTotalPrice = () =>
    packages
      ? localizeCurrency(
          packages.reduce((total, current) => {
            if (current) {
              const { orders, branch_id } = current;
              return (total += calcOrdersTotalPriceWithDiscount(orders ?? [], branch_id));
            }
            return total;
          }, 0)
        )
      : "";

  const totalWeight = () => {
    const ordersWeight = calcOrdersTotalWeight(allOrders ?? []) ?? 0;
    const emptyContainersWeight = calcTotalWeightOfEmptyContainers(delivery.containers ?? []) ?? 0;

    const total = ordersWeight + emptyContainersWeight;

    return total > 0 ? total.toFixed(3) : 0;
  };

  return (
    <Document title="Russian Invoice">
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.title}>{`PACKING LIST № ${delivery_nr}`}</Text>
          <Text style={styles.boldText}>{`Consignor: ${tenants?.name}`}</Text>
          <Text style={styles.boldText}>{`Date of dispatch: ${
            shipment_date ? dayjs(shipment_date).format("L") : ""
          }`}</Text>
          <Text style={styles.boldText}>{`Dispatch No: ${airdepartment_nr ?? ""}`}</Text>
        </View>
        <View style={styles.table}>
          <View style={[styles.row, styles.headerRow]}>
            <View style={[styles.cell, { width: 30 }]}>
              <Text style={styles.boldText}>Parcel No</Text>
            </View>
            <View style={[styles.cell, { width: 70 }]}>
              <Text style={styles.boldText}>Article Number</Text>
            </View>
            <View style={[styles.cell, { width: 30 }]}>
              <Text style={styles.boldText}>Quantity</Text>
            </View>
            <View style={[styles.cell, { flex: 1 }]}>
              <Text style={styles.boldText}>Product Name</Text>
            </View>
            <View style={[styles.cell, { width: 30 }]}>
              <Text style={styles.boldText}>Weight</Text>
              <Text style={styles.boldText}>kg</Text>
            </View>
            <View style={[styles.cell, { width: 40 }]}>
              <Text style={styles.boldText}>Price by</Text>
              <Text style={styles.boldText}>Katalog</Text>
            </View>
            <View style={[styles.cell, { width: 35 }]}>
              <Text style={styles.boldText}>Amount</Text>
              <Text style={styles.boldText}>€</Text>
            </View>
          </View>
          {allOrders?.map(order => {
            const { article_number, amount, name, branch_id } = order;
            return (
              <View style={styles.row}>
                <View style={[styles.cell, { width: 30 }]} />
                <View style={[styles.cell, { width: 70, alignItems: "flex-start" }]}>
                  <Text> {article_number ?? ""}</Text>
                </View>
                <View style={[styles.cell, { width: 30 }]}>
                  <Text>{amount}</Text>
                </View>
                <View style={[styles.cell, { flex: 1, alignItems: "flex-start" }]}>
                  <Text>{name}</Text>
                </View>
                <View style={[styles.cell, { width: 30 }]} />
                <View style={[styles.cell, { width: 40 }]} />
                <View style={[styles.cell, { width: 35 }]}>
                  <Text>{localizeCurrency(+calcPriceAfterDiscountForOrder(order, branch_id))}</Text>
                </View>
              </View>
            );
          })}
          <View style={[styles.row, styles.cell, { justifyContent: "space-around", paddingVertical: 7 }]}>
            <View>
              <Text style={styles.boldText}>{`Parcels total: ${containers?.length ?? ""}`}</Text>
            </View>
            <View>
              <Text style={styles.boldText}>{`Total kg: ${totalWeight()}`}</Text>
            </View>
            <View>
              <Text style={styles.boldText}>{`Total amount: ${calcInvoiceTotalPrice()}`}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 7,
    paddingHorizontal: 84,
    paddingVertical: 56,
  },
  boldText: {
    fontWeight: "bold",
  },
  title: {
    fontSize: 10,
    fontWeight: "bold",
    paddingBottom: 2,
  },
  table: {
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: "black",
    marginTop: 20,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderColor: "black",
  },
  cell: {
    padding: 1,
    minHeight: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRightWidth: 0.5,
    borderColor: "black",
  },
  headerRow: {
    backgroundColor: "#C0C0C0",
  },
  branchRow: {
    backgroundColor: "#E9E9E9",
  },
  TODO: {
    color: "red",
  },
});
