import i18next from "i18next";

interface ISelectCountry {
  label: string;
  value?: string | number | null;
}

export const countries = (lng: string) => {
  const { countries: options }: any = require(`i18n-iso-countries/langs/${lng}.json`);

  return Object.keys(options ?? {}).reduce(
    (acc: ISelectCountry[], key: any) => [...acc, { label: i18next.t(`countries.${key}`), value: key }],
    []
  );
};
