import { FC } from "react";
import { AntdList, Button, Card, Popconfirm, useDrawerForm } from "@pankod/refine-antd";
import { HttpError, useDelete, useTranslate } from "@pankod/refine-core";

import { AddIcon, DeleteIcon, EditIcon, ScaleWhiteIcon } from "assets";
import { AppEmpty } from "components/appEmpty";
import { PackageList } from "components/packages";
import { canUpdateDelivery, localizeWeight } from "helpers";
import { useCurrentUser } from "hooks";
import { IContainer, IDelivery, IOrder, IPackage } from "interfaces";
import { Colors } from "theme";
import { DeliveryStatus } from "../../constants";
import { CreateContainer } from "./create";
import { EditContainer } from "./edit";

interface Props {
  delivery: IDelivery;
  refetchDelivery: () => void;
  containers?: IContainer[];
}

export const ContainerList: FC<Props> = ({ delivery, refetchDelivery, containers }) => {
  const t = useTranslate();
  const { isAdmin } = useCurrentUser();
  const { mutate: deleteContainer } = useDelete();

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<IContainer, HttpError>({
    action: "create",
    resource: "containers",
    redirect: false,
    onMutationSuccess: () => {
      createFormProps.form.resetFields();
      refetchDelivery();
    },
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<IContainer, HttpError>({
    action: "edit",
    resource: "containers",
    redirect: false,
    onMutationSuccess: () => {
      createFormProps.form.resetFields();
      refetchDelivery();
    },
  });

  const genExtra = (container: IContainer) => (
    <>
      <Button
        style={{ marginRight: 12 }}
        onClick={() => editShow(container.id)}
        icon={<EditIcon />}
        disabled={delivery.delivery_status_id !== DeliveryStatus.Packaging}
      />
      <Popconfirm
        key="delete"
        okText={t("buttons.delete")}
        cancelText={t("buttons.cancel")}
        okType="danger"
        title={t("buttons.confirm")}
        onConfirm={() =>
          deleteContainer(
            { resource: "containers", id: container.id },
            { onSuccess: () => refetchDelivery() }
          )
        }
        disabled={delivery.delivery_status_id !== DeliveryStatus.Packaging || !!container.packages?.length}
      >
        <Button
          icon={<DeleteIcon />}
          disabled={delivery.delivery_status_id !== DeliveryStatus.Packaging || !!container.packages?.length}
        />
      </Popconfirm>
    </>
  );

  const totalWeightOfContainer = (container: IContainer) => {
    if (container?.packages?.length) {
      const ordersWeight = (container?.packages ?? []).reduce((total: number, pkg: IPackage) => {
        if (pkg.orders?.length) {
          pkg.orders?.map((order: IOrder) => (total += order.weight));
        }
        return total;
      }, 0);
      return ordersWeight + (container.weight ?? 0);
    } else {
      return container.weight ?? 0;
    }
  };

  const renderTitle = (container: IContainer) => (
    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
      <div style={{ verticalAlign: "middle" }}>{container.container_nr}</div>
      <ScaleWhiteIcon fill={Colors.Emerald} style={{ marginInline: "5px" }} />
      <h4 style={{ marginBottom: 0, fontWeight: 600 }}>{`${t("deliveries.containerWeight")}: ${localizeWeight(
        totalWeightOfContainer(container)
      )}`}</h4>
    </div>
  );

  return (
    <>
      <AntdList
        pagination={false}
        size="small"
        header={
          canUpdateDelivery(delivery.delivery_status_id) &&
          isAdmin && (
            <Button
              resource="containers"
              block
              icon={<AddIcon fill="white" style={{ marginRight: "7px" }} />}
              onClick={() => createShow()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: Colors.NavyBlueSecondary,
                color: Colors.White,
              }}
            >
              {t("containers.titles.create")}
            </Button>
          )
        }
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "70vh",
          height: "100%",
        }}
      >
        {!containers?.length ? (
          <div
            style={{
              minHeight: "50vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AppEmpty
              description={
                <span style={{ fontSize: "18px", fontWeight: "500" }}>{t("containers.empty")}</span>
              }
            />
          </div>
        ) : (
          containers
            ?.sort((a, b) =>
              b.container_nr.localeCompare(a.container_nr, undefined, { numeric: true, sensitivity: "base" })
            )
            .map(container => (
              <Card
                key={container.id}
                title={renderTitle(container)}
                type="inner"
                headStyle={{
                  backgroundColor: Colors.NavyBlueSecondary,
                  color: Colors.White,
                  fontSize: "16px",
                }}
                style={{ marginBottom: 16 }}
                extra={isAdmin && genExtra(container)}
              >
                <PackageList
                  container={container}
                  deliveryStatus={delivery.delivery_status_id}
                  refetchDelivery={refetchDelivery}
                />
              </Card>
            ))
        )}
      </AntdList>
      <CreateContainer
        drawerProps={createDrawerProps}
        formProps={{
          ...createFormProps,
          initialValues: {
            ...createFormProps.initialValues,
            delivery_id: delivery.id,
          },
        }}
        saveButtonProps={createSaveButtonProps}
      />
      <EditContainer
        drawerProps={editDrawerProps}
        formProps={{
          ...editFormProps,
          initialValues: {
            ...editFormProps.initialValues,
            delivery_id: delivery.id,
          },
        }}
        saveButtonProps={editSaveButtonProps}
      />
    </>
  );
};
