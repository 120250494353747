import { FC, useMemo } from "react";
import { CrudFilters, getDefaultFilter, useTranslate } from "@pankod/refine-core";
import { Button, Col, DatePicker, Form, FormProps, Input, Row, Select } from "@pankod/refine-antd";

import dayjs from "dayjs";
import { IShopFilterVariables } from "interfaces";
import { Colors } from "theme";

export const createOnSearchFilter = (params: IShopFilterVariables) => {
  const filters: CrudFilters = [];
  const { name, description, is_active, created_at } = params;

  filters.push({
    field: "name",
    operator: "contains",
    value: name,
  });
  filters.push({
    field: "description",
    operator: "contains",
    value: description,
  });
  filters.push({
    field: "is_active",
    operator: "eq",
    value: is_active,
  });
  filters.push(
    {
      field: "created_at",
      operator: "gte",
      value: created_at ? created_at[0].startOf("day").toISOString() : undefined,
    },
    {
      field: "created_at",
      operator: "lte",
      value: created_at ? created_at[1].endOf("day").toISOString() : undefined,
    }
  );
  return filters;
};

interface Props {
  formProps: FormProps;
  filters: CrudFilters;
}

export const ShopFilter: FC<Props> = props => {
  const { formProps, filters } = props;
  const t = useTranslate();
  const { RangePicker } = DatePicker;

  const created_at = useMemo(() => {
    const start = getDefaultFilter("created_at", filters, "gte");
    const end = getDefaultFilter("created_at", filters, "lte");

    const startFrom = dayjs(start);
    const endAt = dayjs(end);

    if (start && end) {
      return [startFrom, endAt];
    }
    return undefined;
  }, [filters]);

  const handleReset = () => formProps.form?.resetFields();

  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        name: getDefaultFilter("name", filters),
        description: getDefaultFilter("description", filters),
        created_at,
      }}
    >
      <Row gutter={[10, 0]} align="bottom" style={{ maxWidth: "344px" }}>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("shops.filter.name.label")} name="name">
            <Input placeholder={t("shops.filter.name.placeholder")} allowClear />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("shops.filter.description.label")} name="description">
            <Input placeholder={t("shops.filter.description.placeholder")} allowClear />
          </Form.Item>
        </Col>

        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("shops.filter.createdAt.label")} name="created_at">
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col xs={24} xl={24} md={8}>
          <Form.Item label={t("shops.filter.isActive.label")} name="is_active">
            <Select
              allowClear
              placeholder={t("shops.filter.isActive.placeholder")}
              options={[
                {
                  label: t("shops.filter.isActive.true"),
                  value: "true",
                },
                {
                  label: t("shops.filter.isActive.false"),
                  value: "false",
                },
              ]}
            />
          </Form.Item>
        </Col>

        {/* <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              {t("shops.filter.submit")}
            </Button>
          </Form.Item>
        </Col> */}
      </Row>
      <Row
        justify="end"
        align="bottom"
        style={{ width: "100%", borderTop: `1px solid ${Colors.MintHover}`, paddingTop: "20px" }}
      >
        <Col>
          <Button style={{ marginRight: "17px" }} onClick={handleReset}>
            {t("buttons.reset")}
          </Button>
        </Col>
        <Col>
          <Form.Item noStyle>
            <Button htmlType="submit" type="primary">
              {t("buttons.apply")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
