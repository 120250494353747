import { FC } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { useList, useOne } from "@pankod/refine-core";

import { OrderStorageLabel } from "./orderStorageLabel";
import { IOrder, IOrderStatusHistory } from "interfaces";
import { OrderStatus } from "../../constants";

interface Props {
  orderId: number;
}

export const OrderPdfLayout: FC<Props> = props => {
  const { orderId } = props;

  const orderQuery = useOne<IOrder>({
    resource: "orders",
    id: orderId,
    queryOptions: { enabled: !!orderId },
    metaData: { select: "*, shops(name), branches(branch_nr)" },
  });
  const order = orderQuery.data?.data;

  const { data: orderStatusHistoryData, isFetching } = useList<IOrderStatusHistory>({
    resource: "order_history",
    queryOptions: { enabled: !!orderId },
    config: {
      filters: [
        {
          field: "order_id",
          operator: "eq",
          value: orderId,
        },
        {
          field: "new_order_status_id",
          operator: "in",
          value: [OrderStatus.InStock, OrderStatus.HeldInStock],
        },
      ],
    },
  });
  const orderHistory = orderStatusHistoryData?.data;

  if (!order || !orderHistory?.length || isFetching) {
    return null;
  }

  return (
    <PDFViewer
      style={{
        paddingTop: 32,
        width: "100%",
        height: "70vh",
        border: "none",
      }}
    >
      <OrderStorageLabel order={order} orderHistory={orderHistory[0]} />
    </PDFViewer>
  );
};
