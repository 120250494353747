import { FC, useState, useEffect } from "react";
import {
  ButtonProps,
  Drawer,
  DrawerProps,
  Create,
  FormProps,
  Grid,
  Form,
  Input,
  Select,
  useSelect,
  Divider,
  Checkbox,
} from "@pankod/refine-antd";
import { useCreateMany, useTranslate } from "@pankod/refine-core";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import { supabaseClient } from "config";
import { IBranchShops, IShop } from "interfaces";

interface InviteBranchForm {
  name: string;
  email: string;
  shops: number[];
}

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  close: () => void;
}

export const CreateBranch: FC<Props> = props => {
  const { drawerProps, formProps, saveButtonProps, close } = props;
  const { mutate } = useCreateMany<IBranchShops>();
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldSelectAllShops, setShouldSelectAllShops] = useState<boolean>(true);

  const { selectProps: shopSelectProps } = useSelect<IShop>({
    resource: "shops",
    optionLabel: "name",
    optionValue: "id",
    hasPagination: false,
  });

  const onFinish = async (data: InviteBranchForm) => {
    setIsLoading(true);
    const { data: user, error } = await supabaseClient.functions.invoke("branches", {
      body: JSON.stringify({ email: data.email, name: data.name }),
    });

    setIsLoading(false);
    if (!!user.error) {
      formProps?.form?.setFields([{ name: "email", errors: [t(`branches.errors.${user.error}`)] }]);
    } else if (!!error) {
      formProps?.form?.setFields([{ name: "email", errors: [t(`branches.errors.${error.message}`)] }]);
    } else {
      if (data.shops?.length) {
        const branchId = user.newUser.id;
        mutate(
          {
            resource: "branch_shops",
            values: data.shops.map(shopId => ({
              branch_id: branchId,
              shop_id: shopId,
            })),
            successNotification: false,
          },
          {
            onSuccess: () => {
              close();
              formProps?.form?.resetFields();
            },
          }
        );
      } else {
        close();
        formProps?.form?.resetFields();
      }
    }
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      formProps.form?.setFieldsValue({ shops: (shopSelectProps?.options ?? []).map(option => option.value) });
    } else {
      formProps.form?.setFieldsValue({ shops: [] });
    }

    setShouldSelectAllShops(isChecked);
  };

  useEffect(() => {
    if (shopSelectProps?.options?.length && shouldSelectAllShops && drawerProps.open) {
      formProps.form?.setFieldsValue({ shops: (shopSelectProps?.options ?? []).map(option => option.value) });
    }
  }, [drawerProps.open, formProps.form, shopSelectProps?.options, shouldSelectAllShops]);

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t("branches.addUser")}
      destroyOnClose
      forceRender
    >
      <Create
        resource="branches"
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        title={false}
        isLoading={isLoading}
        saveButtonProps={saveButtonProps}
      >
        <Form {...formProps} layout="vertical" onFinish={onFinish}>
          <Form.Item label={t("branches.fields.name")} name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t("branches.fields.email")}
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item label={t("branches.fields.shopAccess.label")} name="shops" rules={[{ required: true }]}>
            <Select
              {...shopSelectProps}
              placeholder={t("branches.fields.shopAccess.placeholder")}
              mode="multiple"
              dropdownRender={menu => (
                <>
                  <Checkbox checked={shouldSelectAllShops} onChange={handleCheckboxChange}>
                    {t("common.selectAll")}
                  </Checkbox>
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
            />
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
