import { FC, useCallback, useState, memo } from "react";
import { HttpError, useDelete, useModal, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { Button, Card, Modal, Popconfirm, useDrawerForm, useTable } from "@pankod/refine-antd";

import { OrderTable } from "components/orders";
import { IBranch, IContainer, IOrder, IPackage } from "interfaces";
import { DeliveryStatus, OrderStatus, PdfType } from "../../constants";
import { PackagePdfLayout } from "documents/package";
import { AddOrdersModal } from "./addOrders";
import { Colors } from "theme";
import { AddIcon, DocumentIcon, EditIcon, DeleteIcon, BranchesIcon, ClientsIcon } from "assets";
import { useCurrentUser } from "hooks";
import { canUpdateDelivery } from "helpers";
import { EditPackage } from "./edit";

interface Props {
  deliveryStatus: number;
  pkg: IPackage;
  refetchDelivery: () => void;
  container: IContainer;
}

export const Package: FC<Props> = memo(props => {
  const { deliveryStatus, pkg, refetchDelivery, container } = props;
  const t = useTranslate();
  const { isAdmin } = useCurrentUser();
  const { mutate: deletePackage } = useDelete();
  const { mutate: updateMutation } = useUpdate();

  const [packageForPrint, setPackageForPrint] = useState<IPackage>();
  const [pdfTypeForPrint, setPdfTypeForPrint] = useState<PdfType>();
  const [packageId, setPackageId] = useState<number>();

  const { show: showPdfModal, visible: visiblePdfModal, close: closePdfModal } = useModal();
  const { show: showOrderModal, visible: visibleOrderModal, close: closeOrderModal } = useModal();

  const { tableProps, sorter, tableQueryResult } = useTable<IOrder, HttpError>({
    resource: "orders",
    hasPagination: false,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "package_id",
        operator: "eq",
        value: pkg.id,
      },
    ],
  });

  const { data: branch } = useOne<IBranch>({
    resource: "branches",
    id: pkg.branch_id,
    queryOptions: { enabled: !!pkg.branch_id },
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<IPackage>({
    action: "edit",
    resource: "packages",
    redirect: false,
    onMutationSuccess: () => refetchDelivery(),
  });

  const genExtra = (pkg: IPackage) => (
    <div style={{ display: "flex" }}>
      <Button
        icon={<DocumentIcon style={{ marginRight: 4 }} />}
        onClick={event => {
          event.stopPropagation();
          setPdfTypeForPrint(PdfType.CN23);
          setPackageForPrint(pkg);
          showPdfModal();
        }}
        disabled={!tableProps.dataSource?.length}
        style={{ display: "flex", alignItems: "center" }}
      >
        CN23
      </Button>
      &nbsp;
      <Button
        icon={<DocumentIcon style={{ marginRight: 4 }} />}
        onClick={event => {
          event.stopPropagation();
          setPdfTypeForPrint(PdfType.CP71);
          setPackageForPrint(pkg);
          showPdfModal();
        }}
        disabled={!tableProps.dataSource?.length}
        style={{ display: "flex", alignItems: "center" }}
      >
        CP71
      </Button>
      &nbsp;
      <Button
        icon={<EditIcon />}
        onClick={() => editShow(pkg.id)}
        disabled={deliveryStatus !== DeliveryStatus.Packaging || !!pkg.orders?.length}
      />
      &nbsp;
      <Popconfirm
        key="delete"
        okText={t("buttons.delete")}
        cancelText={t("buttons.cancel")}
        okType="danger"
        title={t("buttons.confirm")}
        onConfirm={() =>
          deletePackage({ resource: "packages", id: pkg.id }, { onSuccess: () => refetchDelivery() })
        }
        disabled={deliveryStatus !== DeliveryStatus.Packaging || !!tableQueryResult.data?.data.length}
      >
        <Button
          icon={<DeleteIcon />}
          disabled={deliveryStatus !== DeliveryStatus.Packaging || !!tableQueryResult.data?.data.length}
        />
      </Popconfirm>
    </div>
  );

  const Title = (
    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
      <div style={{ verticalAlign: "middle" }}>{pkg.package_nr}</div>
      <BranchesIcon style={{ marginInline: 5 }} width="14" height="14" />
      <div style={{ verticalAlign: "middle" }}>{branch?.data.name}</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ClientsIcon style={{ marginInline: 5 }} width="14" height="14" />
        <div style={{ verticalAlign: "middle" }}>{pkg.clients?.name}</div>
      </div>
    </div>
  );

  const renderRemoveOrderAction = useCallback(
    (record: IOrder) => (
      <Button
        icon={<DeleteIcon />}
        onClick={() => {
          updateMutation(
            {
              resource: "orders",
              id: record.id,
              values: {
                package_id: null,
                order_status_id: OrderStatus.InStock,
              },
              successNotification: false,
            },
            { onSuccess: () => refetchDelivery() }
          );
        }}
        disabled={deliveryStatus !== DeliveryStatus.Packaging}
      />
    ),
    [deliveryStatus, refetchDelivery, updateMutation]
  );

  return (
    <>
      <Card
        title={Title}
        key={pkg.id}
        type="inner"
        headStyle={{
          backgroundColor: Colors.MintFocus,
          borderBottomColor: Colors.NavyBlueSecondary,
          borderBottomWidth: 0.5,
          color: Colors.White,
          fontSize: "16px",
        }}
        extra={isAdmin && genExtra(pkg)}
        style={{ marginBottom: 16, borderColor: Colors.NavyBlueSecondary, borderWidth: 0.5 }}
      >
        <OrderTable
          selectorId="whiteTable"
          tableProps={tableProps}
          sorter={sorter}
          columnConfig={{
            hiddenStatus: true,
            hiddenUpdatedAt: true,
            hiddenStoragePlace: true,
            hiddenArrivalPrice: true,
            hiddenCustomsPrice: true,
            hiddenWeight: true,
            hiddenCountry: true,
            hiddenOrderedPrice: true,
          }}
          enableActions={isAdmin}
          customAction={renderRemoveOrderAction}
        />

        {isAdmin && canUpdateDelivery(deliveryStatus) && (
          <>
            <br />
            <Button
              block
              icon={<AddIcon fill="black" style={{ marginRight: "7px" }} />}
              onClick={() => {
                setPackageId(pkg.id);
                showOrderModal();
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: Colors.MintDefault,
              }}
            >
              {t("orders.titles.add")}
            </Button>
          </>
        )}
      </Card>
      {visiblePdfModal && !!packageForPrint && (
        <Modal open={visiblePdfModal} onCancel={closePdfModal} width="80%" footer={null}>
          <PackagePdfLayout
            pkg={packageForPrint}
            pdfType={pdfTypeForPrint}
            branch={branch?.data!}
            container={container}
          />
        </Modal>
      )}
      {packageId && visibleOrderModal && (
        <AddOrdersModal
          visible={visibleOrderModal}
          close={closeOrderModal}
          show={showOrderModal}
          pkg={pkg}
          refetchDelivery={refetchDelivery}
        />
      )}
      <EditPackage
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
    </>
  );
});
