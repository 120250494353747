export enum Colors {
  Emerald = "#126A5A",
  EmeraldFocus = "#0E5E4F",
  NavyBlue = "#001628",
  NavyBlueSecondary = "#4C6275",
  MintMain = "#DFEBE9",
  MintDefault = "#EDF1EF",
  MintHover = "#C1CCCB",
  MintFocus = "#A4B6B4",
  White = "#FFFFFF",
}
