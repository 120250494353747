import { FC } from "react";
import { List, useDrawerForm } from "@pankod/refine-antd";
import { HttpError, useTranslate } from "@pankod/refine-core";

import { ClientsTable } from "./table";
import { CreateClient } from "./create";
import { EditClient } from "./edit";
import { IClient } from "interfaces";
import { AppCreateButton } from "components/appCreateButton";

interface Props {
  selectorId?: string;
  branchId: string;
}

export const ClientsList: FC<Props> = props => {
  const { branchId, selectorId } = props;
  const t = useTranslate();

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<IClient, HttpError, IClient>({
    action: "create",
    resource: "clients",
    redirect: false,
    onMutationSuccess: () => createFormProps.form.resetFields(),
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<IClient, HttpError, IClient>({
    action: "edit",
    resource: "clients",
    redirect: false,
  });

  return (
    <>
      <List
        breadcrumb={false}
        title={false}
        headerButtons={() => (
          <AppCreateButton onClick={() => createShow()}>{t("clients.addClient")}</AppCreateButton>
        )}
      >
        <ClientsTable branchId={branchId} onRecordEdit={editShow} selectorId={selectorId} />
      </List>
      <CreateClient
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        initialValues={{ branch_id: branchId, is_active: true }}
      />
      <EditClient
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
    </>
  );
};
