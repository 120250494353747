import { FC, Key, useState } from "react";
import { Modal, useTable } from "@pankod/refine-antd";
import { HttpError, useModalReturnType, useTranslate, useUpdateMany } from "@pankod/refine-core";

import { OrderStatus } from "../../constants";
import { OrderTable } from "components/orders";
import { IOrder, IPackage } from "interfaces";

interface Props extends useModalReturnType {
  pkg: IPackage;
  refetchDelivery: () => void;
}

export const AddOrdersModal: FC<Props> = ({ visible, close, pkg, refetchDelivery }) => {
  const t = useTranslate();
  const { mutate: updateMutation } = useUpdateMany();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<IOrder[]>([]);

  const { tableProps, sorter } = useTable<IOrder, HttpError>({
    resource: "orders",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "package_id",
        operator: "null",
        value: 1, // Workaround to apply "null" operator.
      },
      {
        field: "order_status_id",
        operator: "eq",
        value: OrderStatus.InStock,
      },
      {
        field: "client_id",
        operator: "eq",
        value: pkg.client_id,
      },
    ],
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: Key[], selectedRows: IOrder[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const handleOk = () => {
    if (selectedRows.length) {
      updateMutation(
        {
          resource: "orders",
          ids: selectedRows.map(order => order.id),
          values: {
            package_id: pkg.id,
            order_status_id: OrderStatus.BeingPacked,
          },
        },
        {
          onSuccess: () => {
            refetchDelivery();
            close();
          },
        }
      );
    } else {
      close();
    }
  };

  return (
    <Modal open={visible} onCancel={close} width="70%" title={t("orders.titles.add")} onOk={handleOk}>
      <OrderTable
        selectorId="whiteTable"
        tableProps={tableProps}
        sorter={sorter}
        rowSelection={rowSelection}
        columnConfig={{
          hiddenStatus: true,
          hiddenUpdatedAt: true,
          hiddenStoragePlace: true,
          hiddenArrivalPrice: true,
          hiddenCustomsPrice: true,
          hiddenWeight: true,
          hiddenCountry: true,
          hiddenOrderedPrice: true,
        }}
      />
    </Modal>
  );
};
