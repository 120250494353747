import { FC, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import {
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Row,
  Space,
  Typography,
  Icons,
} from "@pankod/refine-antd";
import dayjs from "dayjs";

import { Colors } from "theme";
import { IOrder } from "interfaces";
import { OrderStatus } from "../../constants";

interface Props {
  modalProps: ModalProps;
  formProps: FormProps;
  initialValues: Partial<IOrder>;
  order: IOrder;
}

export const ConfirmArrivalModal: FC<Props> = props => {
  const { modalProps, formProps, initialValues, order } = props;
  const t = useTranslate();
  const [hasPriceDifference, setHasPriceDifference] = useState<boolean>(false);

  const handleOnFieldsChange = (changedFields: any) => {
    changedFields.forEach((field: any) => {
      if (field.name.includes("arrival_price")) {
        const hasDifference = Number(field.value) > order.ordered_price;
        setHasPriceDifference(hasDifference);
      }
    });
  };

  const handleOnFinish = (values: any) => {
    const { order_status_id, ...rest } = values;
    let request = values;

    if (hasPriceDifference) {
      request = { ...rest, order_status_id: OrderStatus.HeldInStock };
    }

    formProps.onFinish!(request);
  };

  return (
    <Modal {...modalProps} width="50%" title={<h2>{t("arrival.receiveOrder")}</h2>} destroyOnClose>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
          ...initialValues,
          shop_invoice_date: dayjs(initialValues.shop_invoice_date).toISOString(),
          arrival_price: order.ordered_price,
          customs_price: order.customs_price ?? order.arrival_price ?? order.ordered_price,
        }}
        onFieldsChange={changedFields => handleOnFieldsChange(changedFields)}
        onFinish={handleOnFinish}
      >
        <Row gutter={[16, 16]}>
          <Col xl={12} lg={24} xs={24}>
            <Space direction="vertical" style={styles.cardContent}>
              <div style={styles.flexRow}>
                <Typography.Text strong style={{ wordBreak: "keep-all" }}>{`${t(
                  "orders.fields.name"
                )}:`}</Typography.Text>
                <Typography.Text style={{ paddingLeft: "11px" }}>{order.name}</Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Text strong style={{ wordBreak: "keep-all" }}>{`${t(
                  "orders.fields.articleNumber"
                )}:`}</Typography.Text>
                <Typography.Text style={{ paddingLeft: "11px" }}>{order.article_number}</Typography.Text>
              </div>
              <div style={styles.flexRow}>
                <Typography.Text strong>{`${t(
                  "arrival.additionalInfo.invoiceNumber.label"
                )}:`}</Typography.Text>
                <Form.Item name="shop_invoice_nr" noStyle>
                  <Input style={{ maxWidth: "100px" }} bordered={false} />
                </Form.Item>
              </div>
              <div style={styles.flexRow}>
                <Typography.Text strong>{`${t(
                  "arrival.additionalInfo.invoiceDate.label"
                )}:`}</Typography.Text>
                <Form.Item name="shop_invoice_date" noStyle>
                  <Input style={{ maxWidth: "100px" }} bordered={false} />
                </Form.Item>
              </div>
            </Space>
            {hasPriceDifference && (
              <div style={styles.hint}>
                <Icons.ExclamationCircleOutlined style={styles.icon} />
                <Typography.Text>{t("arrival.priceDifference")}</Typography.Text>
              </div>
            )}
            {order.under_control && (
              <div style={styles.hint}>
                <Icons.ExclamationCircleOutlined style={styles.icon} />
                <Typography.Text>{t("arrival.controlledOrder")}</Typography.Text>
              </div>
            )}
          </Col>
          <Col xl={12} lg={24} xs={24}>
            <Form.Item label={t("orders.fields.shortName")} name="short_name">
              <Input />
            </Form.Item>
            <Form.Item label={t("invoices.fields.price")} name="arrival_price" rules={[{ required: true }]}>
              <Input type="number" addonBefore="€" min={0} />
            </Form.Item>
            <Form.Item
              label={t("orders.fields.customsPrice")}
              name="customs_price"
              rules={[{ required: true }]}
            >
              <Input type="number" addonBefore="€" min={0} />
            </Form.Item>
            <Form.Item
              label={t("arrival.additionalInfo.weight.label")}
              name="weight"
              rules={[{ required: true }]}
            >
              <Input
                type="number"
                min={0}
                addonBefore={t("arrival.additionalInfo.weight.suffix")}
                placeholder={t("arrival.additionalInfo.weight.placeholder")}
              />
            </Form.Item>
            <Form.Item label={t("arrival.additionalInfo.storagePlace.label")} name="storage_place">
              <Input placeholder={t("arrival.additionalInfo.storagePlace.placeholder")} />
            </Form.Item>
            <Form.Item name="order_status_id" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const styles = {
  cardContent: {
    border: `1px solid ${Colors.Emerald}`,
    borderRadius: "10px",
    backgroundColor: Colors.MintDefault,
    padding: "10px",
    marginTop: "10px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row" as any,
    alignItems: "baseline",
  },
  hint: {
    paddingRight: "10px",
    marginTop: "10px",
  },
  icon: {
    color: "#D39230",
    marginRight: "5px",
  },
};
