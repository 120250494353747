import { FC, useState } from "react";
import { Card, Col, Divider, Form, Modal, Row, Select } from "@pankod/refine-antd";
import { useList, useModalReturnType, useTranslate, useUpdate } from "@pankod/refine-core";

import { IContainer, IDelivery, IOrder, IPackage } from "interfaces";

interface ModalReturnType extends useModalReturnType {
  selectedRows: IOrder[];
}

export const AddOrderToDeliveryModal: FC<ModalReturnType> = ({ visible, close, selectedRows }) => {
  const t = useTranslate();
  const [selectedDeliveryId, setSelectedDeliveryId] = useState<number | undefined>();

  const [selectedContainerId, setSelectedContainerId] = useState<number | undefined>();

  const [selectedPackageId, setSelectedPackageId] = useState<number | undefined>();

  const { data: deliveryData, isFetching: isFetchingDeliveryData } = useList<IDelivery>({
    resource: "deliveries",
  });

  const { data: containerData, isFetching: isFetchingContainerData } = useList<IContainer>({
    resource: "containers",
    queryOptions: {
      enabled: !!selectedDeliveryId,
    },
    config: {
      filters: [
        {
          field: "delivery_id",
          operator: "eq",
          value: selectedDeliveryId,
        },
      ],
    },
  });

  const { data: packageData, isFetching: isFetchingPackageData } = useList<IPackage>({
    resource: "packages",
    queryOptions: {
      enabled: !!selectedContainerId,
    },
    config: {
      filters: [
        {
          field: "container_id",
          operator: "eq",
          value: selectedContainerId,
        },
      ],
    },
  });

  const { mutate } = useUpdate();

  const onChangeDelivery = (deliveryId: number) => {
    setSelectedDeliveryId(deliveryId);
    setSelectedContainerId(undefined);
  };

  const onChangeContainer = (containerId: number) => {
    setSelectedContainerId(containerId);
    setSelectedPackageId(undefined);
  };

  const onChangePackage = (packageId: number) => {
    setSelectedPackageId(packageId);
  };

  const handleOk = () => {
    if (!!selectedContainerId && !!selectedDeliveryId && !!selectedPackageId) {
      mutate({
        resource: "containers",
        id: selectedContainerId,
        values: {
          delivery_id: selectedDeliveryId,
        },
      });

      mutate({
        resource: "packages",
        id: selectedPackageId,
        values: {
          container_id: selectedContainerId,
        },
      });

      selectedRows.forEach(order =>
        mutate({
          resource: "orders",
          id: order.id,
          values: {
            package_id: selectedPackageId,
          },
        })
      );
    }
    close();
  };

  return (
    <Modal
      open={visible}
      onCancel={close}
      width="50%"
      title={t("deliveries.addToDelivery.addOrdersToDelivery")}
      onOk={handleOk}
    >
      <Row gutter={[64, 0]} wrap>
        <Col xs={24} lg={10}>
          <Card title={t("deliveries.addToDelivery.selectedOrders")} type="inner">
            {selectedRows.map((row: IOrder) => (
              <>
                {row.name}
                <Divider />
              </>
            ))}
          </Card>
        </Col>
        <Col xs={24} lg={14}>
          <Form layout="vertical">
            <Form.Item label={t("deliveries.delivery")} name="delivery">
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label).toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                }
                placeholder={t("deliveries.addToDelivery.selectDelivery")}
                options={deliveryData?.data.map(delivery => ({
                  label: delivery.delivery_nr,
                  value: delivery.id,
                }))}
                onChange={onChangeDelivery}
                loading={isFetchingDeliveryData}
              />
            </Form.Item>

            <Form.Item label={t("containers.container")} name="container">
              <Select
                disabled={!selectedDeliveryId}
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label).toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                }
                placeholder={t("deliveries.addToDelivery.selectContainer")}
                options={containerData?.data.map(container => ({
                  label: container.container_nr,
                  value: container.id,
                }))}
                onChange={onChangeContainer}
                loading={isFetchingContainerData}
              />
            </Form.Item>
            <Form.Item label={t("packages.package")} name="package">
              <Select
                disabled={!selectedContainerId}
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label).toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                }
                placeholder={t("deliveries.addToDelivery.selectPackage")}
                options={packageData?.data.map(pkg => ({
                  label: pkg.package_nr,
                  value: pkg.id,
                }))}
                onChange={onChangePackage}
                loading={isFetchingPackageData}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
