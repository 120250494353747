import { FC } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import { IBranch, IContainer, IPackage, ITenant } from "interfaces";
import {
  calcContainerWeightInKilograms,
  calcOrdersTotalPriceWithDiscount,
  calcOrdersTotalWeight,
} from "../utils";
import { localizeCurrency } from "helpers";

interface Props {
  branch: IBranch;
  tenant: ITenant;
  pkg: IPackage;
  container: IContainer | undefined;
}

export const CP71: FC<Props> = props => {
  const { branch, tenant, pkg, container } = props;
  const { orders, clients } = pkg;

  const getOrdersTotalWeight = () => {
    if (container?.packages?.length === 1) {
      return calcContainerWeightInKilograms(container);
    } else {
      return calcOrdersTotalWeight(orders!);
    }
  };

  return (
    <Document title="CP71">
      <Page size="A5" orientation="landscape" style={styles.page}>
        <View style={styles.flexRow}>
          <View style={{ flex: 1 }}>
            <Text style={styles.boldText}>(Administration des postes)</Text>
            <View style={styles.table}>
              <View style={styles.row}>
                <View style={[styles.cell, { flex: 1 }]}>
                  <Text>De</Text>
                  <Text>Из</Text>
                </View>
                <View style={[styles.cell, { flex: 6 }]}>
                  <Text style={styles.boldText}>Nom et adresse de l`exediteur</Text>
                  <Text style={styles.boldText}>Фамилия и адрес отправителя</Text>
                </View>
                <View style={[styles.cell, { flex: 6 }]}>
                  <Text style={styles.boldText}>Reference del`expediteur (si elle existe)</Text>
                  <Text style={styles.boldText}>Ссылка отправителя (если она имеется)</Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 6, minHeight: 40 }]}>
                  <Text>{tenant.name}</Text>
                  <Text>{tenant.address}</Text>
                  <Text>{`${tenant.zipcode} ${tenant.city}`}</Text>
                  <Text>{`tel. ${tenant.phone}`}</Text>
                </View>
                <View style={[styles.cell, { flex: 6 }]} />
              </View>

              <View style={styles.row}>
                <View style={[styles.cell, { flex: 1 }]}>
                  <Text>A</Text>
                  <Text>B</Text>
                </View>
                <View style={[styles.cell, { flex: 12 }]}>
                  <Text style={styles.boldText}>
                    Nom et adresse du destinataire, y covhris lle pays dedestantion
                  </Text>
                  <Text style={styles.boldText}>Фамилия и адрес получателя, исключая страну назначения</Text>
                </View>
              </View>
              <View style={[styles.row, { borderBottomWidth: 0, minHeight: 40 }]}>
                <View style={[styles.cell, { flex: 1 }]} />
                <View style={[styles.cell, { flex: 12 }]}>
                  <Text>{clients?.name ?? ""}</Text>
                  <Text>{clients?.address ?? ""}</Text>
                  <Text>{`${clients?.zipcode ?? ""} ${clients?.city ?? ""}`}</Text>
                  <Text> {`tel. ${clients?.phone ?? ""}`}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <View style={[styles.flexRow, { paddingLeft: 3 }]}>
              <View>
                <Text style={styles.title}>BULLETIN D`EXPEDITION CP 71</Text>
                <Text style={styles.title}>СОПРОВОДИТЕЛЬНЫЙ АДРЕС</Text>
              </View>
              <View>
                <Text style={styles.boldText}>(ancien CP 2)</Text>
                <Text style={styles.boldText}>(прежний CP 2)</Text>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={{ marginTop: 5, paddingLeft: 3 }}>
                <Text style={styles.boldText}>№ du/des colis (code a barres s`il existe)</Text>
                <Text style={styles.boldText}>№ отправления/посылки (возможно штриховой код)</Text>
              </View>
              <View style={styles.packageInfo}>
                <Text>{`Paket: ${pkg.package_nr}`}</Text>
              </View>
              <View style={styles.table}>
                <View style={styles.row}>
                  <View style={[styles.cell, { flex: 4 }]}>
                    <Text style={styles.boldText}>Valeur declaree - en lettres</Text>
                    <Text style={styles.boldText}>Объявленная ценность - прописью</Text>
                  </View>
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text style={styles.boldText}>en chiffres</Text>
                    <Text style={styles.boldText}>цифрами</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={[styles.cell, { flex: 4, minHeight: 15 }]} />
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text style={styles.boldText}>
                      {localizeCurrency(calcOrdersTotalPriceWithDiscount(orders!, branch.id))}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={[styles.cell, { flex: 4 }]}>
                    <Text style={styles.boldText}>Montant du remboursement - en lettres</Text>
                    <Text style={styles.boldText}>Сумма возмещения - прописью</Text>
                  </View>
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text style={styles.boldText}>en chiffres</Text>
                    <Text style={styles.boldText}>цифрами</Text>
                  </View>
                </View>
                <View style={[styles.row, { borderBottomWidth: 0 }]}>
                  <View style={[styles.cell, { flex: 4, minHeight: 15 }]} />
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text style={styles.boldText} />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.flexRow}>
          <View style={[styles.table, { flex: 1 }]}>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.boldText}>Tremble de la douane</Text>
                <Text style={styles.boldText}>Штемпель таможни</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.boldText}>Bureau d`echange</Text>
                <Text style={styles.boldText}>Учереждение обиена</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1, height: 50 }]} />
              <View style={[styles.cell, { flex: 1, height: 50 }]} />
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.boldText}>Droit de douane</Text>
                <Text style={styles.boldText}>Таможенных сбор</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]} />
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.boldText}>Categorie de colis</Text>
                <Text style={styles.boldText}>Категория посылки</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]} />
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text>Instructions de l`expediteur en cas de non-livraison</Text>
                <Text>Инструкция отправителя в случае невыдачи</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1, flexDirection: "row" }]}>
                <View style={styles.checkbox} />
                <View>
                  <Text>Avis de non-livrason a l`expediteur</Text>
                  <Text>Уведомить о невыдачи отправителя</Text>
                </View>
              </View>
              <View style={[styles.cell, { flex: 1, flexDirection: "row" }]}>
                <View style={styles.checkbox} />
                <View>
                  <Text>Renvoyer immedialement a lexpediteur</Text>
                  <Text>Возвратить сразу же отправителю</Text>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1, flexDirection: "row" }]}>
                <View style={styles.checkbox} />
                <View style={[styles.flexRow, { flex: 1 }]}>
                  <View style={{ flex: 1 }}>
                    <Text>Renvoyer a l`expediteur apress</Text>
                    <Text>Возвратить отправителю</Text>
                    <Text>После</Text>
                  </View>
                  <View>
                    <Text>Jours</Text>
                    <Text>Дней</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.cell, { flex: 1, flexDirection: "row" }]}>
                <View style={styles.checkbox} />
                <View>
                  <Text>Traiter comme abendonne</Text>
                  <Text>Рассмотривать как отправление</Text>
                  <Text>От которого отказался адресат</Text>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1, flexDirection: "row" }]}>
                <View style={styles.checkbox} />
                <View style={[styles.flexRow, { flex: 1 }]}>
                  <View>
                    <Text>Reexpediteur au destinatarle</Text>
                    <Text>a L`adresse ci-dessous</Text>
                    <Text>Дослать отправителю по</Text>
                    <Text>Адресу ниже</Text>
                  </View>
                  <View>
                    <Text>Renover/</Text>
                    <Text>Reexpedier</Text>
                    <Text>Возвраты/</Text>
                    <Text>Дослать/</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.cell, { flex: 1, flexDirection: "row" }]}>
                <View style={styles.checkbox} />
                <View>
                  <Text>per vie de surface/</Text>
                  <Text>S.A.L</Text>
                  <Text>Наземным путём/</Text>
                  <Text>S.A.L</Text>
                </View>

                <View style={styles.checkbox} />
                <View>
                  <Text>Per avion</Text>
                  <Text>авиа</Text>
                </View>
              </View>
            </View>
            <View style={[styles.row, { flex: 1 }]}>
              <View style={styles.cell}>
                <Text>Adresse</Text>
                <Text>Адрес</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]} />
            </View>
          </View>
          <View style={[styles.table, { flex: 1 }]}>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1 }]}>
                <Text style={styles.boldText}>(Apposer les etiquettes officielles, le cas echeant)</Text>
                <Text style={styles.boldText}>(Приклеить официальные ярлыки, если необходимо)</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { flex: 1, height: 50 }]} />
            </View>
            <View style={styles.flexRow}>
              <View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text>Nombre de</Text>
                    <Text>Количество</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>colis</Text>
                    <Text>полысок</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text>Certificats et factares</Text>
                    <Text>Подтверждений и счетов</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={[styles.cell, { flex: 1, height: 18.5 }]} />
                </View>
                <View style={styles.row}>
                  <View>
                    <Text>Valeur declaree en DTS</Text>
                    <Text>Объявленная ценность в СПЗ</Text>
                  </View>
                  <View style={[styles.cell, { flex: 1 }]} />
                </View>
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text>Poids brut total (kg.)</Text>
                    <Text>Общий вес брутто (кг.)</Text>
                  </View>
                  <View style={[styles.cell, { paddingRight: 25 }]}>
                    <Text>Taxes</Text>
                    <Text>Тариф</Text>
                  </View>
                  <View style={[styles.cell, styles.centeredView, { flex: 1 }]}>
                    <Text style={styles.boldText}>{getOrdersTotalWeight()}</Text>
                  </View>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <View style={styles.row}>
                  <View style={[styles.cell, { flex: 1 }]}>
                    <Text>Bureau d`crignie / Date de decol</Text>
                    <Text>Учреждение подачи / Дата подачи</Text>
                  </View>
                </View>
                <View style={[styles.row, { flex: 1 }]}>
                  <View style={[styles.cell, { flex: 1 }]} />
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { fontSize: 6 }]}>
                <Text>Declaration du</Text>
                <Text>destinataire</Text>
                <Text>Расписка</Text>
                <Text>Адресата</Text>
              </View>
              <View style={[styles.cell, { flex: 1, fontSize: 6 }]}>
                <Text>J`aleculecollsdecrlutce</Text>
                <Text>Я получил посылку, описание которой даётся в настоящем сопросодительном адресе</Text>
                <Text>Date et signature du destinataire</Text>
                <Text>Дата и подпись получателя</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.cell, { fontSize: 6 }]}>
                <Text>
                  Je certifie que cet envol ne contient aucun objet dangereux interdit par is reglemention
                  pastie. J`acceple`
                </Text>
                <Text>
                  Aussi de payer let frais decoulant de l`execution des instructions donnees ci-contre en cas
                  de non-livrasion
                </Text>
                <Text>
                  Подтверждаю, это отправление не содержит никаких опасных предметов, запрещённых почтовой
                  регламентацией
                </Text>
                <Text>
                  Обязуюсь также оплатить расходы, связанные с выполнением инструкций данных напротив в случае
                  невыдачи
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell}>
                <Text>Date et signature de l`expediteur</Text>
                <Text>Дата и подпись отправителя</Text>
              </View>
              <View style={[styles.cell, { flex: 1 }]} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 7,
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  title: {
    fontSize: 10,
    fontWeight: "bold",
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  table: {
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: "black",
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderColor: "black",
  },
  boldText: {
    fontWeight: "bold",
  },
  cell: {
    padding: 1,
    borderRightWidth: 0.5,
    borderColor: "black",
  },
  packageInfo: {
    fontSize: 11,
    fontWeight: "bold",
    marginVertical: 5,
    paddingLeft: 3,
  },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
  },
  checkbox: {
    width: 13,
    height: 11,
    borderWidth: 1,
    borderColor: "black",
    marginRight: 2,
  },
});
