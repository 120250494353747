import { FC } from "react";
import { GetManyResponse, useTranslate } from "@pankod/refine-core";
import { Tag } from "@pankod/refine-antd";

import { IOrderStatus, IPaymentMethod } from "interfaces";

export const AppTag: FC<{
  queryResult: GetManyResponse<IOrderStatus | IPaymentMethod> | undefined;
  translationPrefix: string;
  value?: number;
}> = ({ queryResult, translationPrefix, value }) => {
  const t = useTranslate();

  const status: IOrderStatus | IPaymentMethod | undefined = queryResult?.data.find(p => p.id === value);

  return <Tag color={status?.color}>{t(`${translationPrefix}.${status?.value}`)}</Tag>;
};
