import { FC, useEffect } from "react";
import { ButtonProps, Drawer, DrawerProps, Create, FormProps, Grid, Button } from "@pankod/refine-antd";
import { useTranslate, useCreateMany, CreateManyResponse, BaseRecord } from "@pankod/refine-core";
import dayjs from "dayjs";

import { OrderStatus } from "../../constants";
import { OrderForm } from "./createEditForm";
import { IOrder } from "interfaces";

interface Props {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  close: () => void;
  isUnableToCreateOrder: boolean;
}

export const CreateOrder: FC<Props> = props => {
  const { drawerProps, formProps, saveButtonProps, close, isUnableToCreateOrder } = props;
  const breakpoint = Grid.useBreakpoint();
  const t = useTranslate();
  const { mutate } = useCreateMany();

  const createOrder = (request: IOrder[]) => {
    mutate(
      {
        resource: "orders",
        values: request,
      },
      {
        onSuccess: data => {
          createOrderHistory(data);
        },
        onSettled: () => {
          formProps.form?.resetFields();
          close();
        },
      }
    );
  };

  const createOrderHistory = (response: CreateManyResponse<BaseRecord>) => {
    if (response.data[0].under_control) {
      // Order was put under control.
      mutate(
        {
          resource: "order_history",
          values: response.data.map(order => {
            const { order_status_id, id, branch_id } = order;
            return {
              order_id: id,
              label: "orderUnderControl",
              new_order_status_id: order_status_id,
              created_at: new Date(),
              branch_id,
            };
          }),
          successNotification: false,
          errorNotification: false,
        },
        {
          onSettled: () => {
            formProps.form?.resetFields();
            close();
          },
        }
      );
    }
  };

  /*
   * A small trick here is to modify created_at for each multiple order.
   * It should help with default table sorter: { field: "created_at", order: "desc" }.
   */
  const createMultipleOrders = (formData: IOrder) => {
    let request: IOrder[] = [];
    for (let i = 0; i < formData.amount; i++) {
      request = [...request, { ...formData, amount: 1, created_at: dayjs().add(i, "second").toISOString() }];
    }
    return request;
  };

  const handleSaveAsUnpublished = async () => {
    await formProps.form?.validateFields();

    const formData = formProps.form?.getFieldsValue();
    if (formData.amount > 1) {
      const orders = createMultipleOrders(formData);
      const request = orders.map(order => ({ ...order, order_status_id: OrderStatus.Unpublished }));
      createOrder(request);
    } else {
      createOrder([{ ...formData, order_status_id: OrderStatus.Unpublished }]);
    }
  };

  const handleSaveAndPublish = async () => {
    await formProps.form?.validateFields();
    const formData = formProps.form?.getFieldsValue() as IOrder;

    if (formData.amount > 1) {
      const request = createMultipleOrders(formData);
      createOrder(request);
    } else {
      createOrder([formData]);
    }
  };

  useEffect(() => {
    if (drawerProps.open) {
      formProps.form?.setFieldValue("order_status_id", OrderStatus.Received);
    }
  }, [drawerProps.open, formProps.form]);

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      title={t("orders.titles.create")}
      destroyOnClose
      forceRender
    >
      <Create
        resource="orders"
        headerProps={{ extra: null }}
        goBack={null}
        breadcrumb={false}
        title={false}
        saveButtonProps={{
          ...saveButtonProps,
          children: t("buttons.publish"),
          onClick: handleSaveAndPublish,
          disabled: isUnableToCreateOrder,
        }}
        footerButtons={({ defaultButtons }) => (
          <>
            <Button type="default" onClick={handleSaveAsUnpublished}>
              {t("buttons.save")}
            </Button>
            {defaultButtons}
          </>
        )}
      >
        <OrderForm
          formProps={formProps}
          isEdit={false}
          isVisible={!!drawerProps.open}
          shouldShowWarning={isUnableToCreateOrder}
        />
      </Create>
    </Drawer>
  );
};
