import { FC, useEffect, useState } from "react";
import { useTranslate, useList, HttpError, useNotification } from "@pankod/refine-core";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Radio,
  Row,
  Upload,
  UploadProps,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import type { UploadChangeParam } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";

import { avatarPath, useAvatar, useCurrentUser, useUploadFile, useRemoveFile } from "hooks";
import { DeleteIcon, UploadIcon } from "assets";
import { Colors } from "theme";
import { AppPhoneInput } from "components/appPhoneInput";
import supabaseClient from "config/supabaseClient";

interface Props {
  formProps: FormProps<{}>;
  isReadOnly: boolean;
  shouldShowFormAction?: boolean;
  refetch?: () => void;
}

export const UserForm: FC<Props> = props => {
  const { formProps, isReadOnly, shouldShowFormAction, refetch } = props;
  const t = useTranslate();
  const { open } = useNotification();
  const { upload } = useUploadFile();
  const { remove } = useRemoveFile();
  const { tenantId, isAdmin } = useCurrentUser();
  const { avatar: currentAvatar } = useAvatar(formProps.initialValues?.id);

  const [avatar, setAvatar] = useState<string>();

  const handleAvatarChange: UploadProps["onChange"] = async (info: UploadChangeParam<UploadFile>) => {
    await upload(avatarPath(formProps.initialValues?.id, tenantId), info.file as any, true);
    setAvatar(URL.createObjectURL(info.file as any));
  };

  const handleDeleteClick = () => {
    remove(avatarPath(formProps.initialValues?.id, tenantId));
    setAvatar(undefined);
  };

  useEffect(() => {
    if (currentAvatar) {
      setAvatar(currentAvatar);
    }
  }, [currentAvatar]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendEmail = async () => {
    try {
      await supabaseClient.functions.invoke("resend-invite", {
        body: JSON.stringify({ email: formProps.initialValues?.email }),
      });
      open?.({
        type: "success",
        message: t("branches.notification.resend_email_success"),
      });
    } catch (e) {
      open?.({
        type: "error",
        message: t("branches.notification.resend_email_error"),
      });
    }
  };

  return (
    <div style={{ marginInline: 100 }}>
      <Row
        gutter={[16, 16]}
        wrap
        style={{ borderBottom: `1px solid ${Colors.MintHover}`, paddingBottom: 20 }}
      >
        {avatar && (
          <Col xxl={2} xl={3} lg={3} md={2} sm={2} xs={2}>
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              src={avatar}
              alt={formProps.initialValues?.name}
            />
          </Col>
        )}
        <Col
          xxl={3}
          xl={6}
          lg={avatar ? 8 : 6}
          md={avatar ? 9 : 8}
          sm={13}
          xs={14}
          style={{ alignSelf: "center" }}
        >
          <Upload
            listType="picture"
            maxCount={1}
            accept="image/*"
            beforeUpload={() => false}
            onChange={handleAvatarChange}
            showUploadList={false}
          >
            <Button
              type="primary"
              icon={<UploadIcon style={{ marginRight: "7px" }} />}
              style={{ display: "flex", alignItems: "center" }}
            >
              {t("profile.upload")}
            </Button>
          </Upload>
        </Col>
        <Col
          xxl={1}
          xl={5}
          lg={avatar ? 8 : 6}
          md={avatar ? 7 : 5}
          sm={9}
          xs={8}
          style={{ alignSelf: "center" }}
        >
          <Button
            type="default"
            icon={<DeleteIcon style={{ marginRight: "7px" }} />}
            style={{ display: "flex", alignItems: "center" }}
            onClick={handleDeleteClick}
          >
            {t("buttons.delete")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Form
          {...formProps}
          initialValues={{
            isActive: true,
            ...formProps.initialValues,
            birthday: formProps.initialValues?.birthday ? dayjs(formProps.initialValues.birthday) : undefined,
          }}
          layout="vertical"
          style={{ width: "100%", paddingTop: 20 }}
        >
          <Row gutter={[32, 32]} wrap>
            <Col flex={1} lg={12} xl={12}>
              <Form.Item label={t("branches.fields.name")} name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.email")} name="email" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              {!isReadOnly && isAdmin && (
                <Form.Item>
                  <Button onClick={resendEmail} type="default" style={{ justifySelf: "end" }}>
                    {t("buttons.resend_invite")}
                  </Button>
                </Form.Item>
              )}
              <Form.Item label={t("clients.phone")} name="phone" rules={[{ required: true }]}>
                <AppPhoneInput />
              </Form.Item>
              <Form.Item
                name="passport_nr"
                label={t("branches.fields.passportNr.label")}
                rules={[{ required: true }]}
              >
                <Input placeholder={t("branches.fields.passportNr.placeholder")} />
              </Form.Item>
              {!isReadOnly && (
                <Form.Item
                  label={t("branches.fields.balanceVerificationPeriod")}
                  name="balance_verification_period_in_weeks"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              )}
              {/* // TODO: TBD in US #1139 */}
              {/* {!isReadOnly && (
                <Form.Item label={t("shops.fields.isActive")} name="is_active" rules={[{ required: true }]}>
                  <Radio.Group>
                    <Radio value={true}>{t("shops.status.enabled")}</Radio>
                    <Radio value={false}>{t("shops.status.disabled")}</Radio>
                  </Radio.Group>
                </Form.Item>
              )} */}
            </Col>
            <Col flex={1} lg={12} xl={12}>
              <Form.Item name="birthday" label={t("branches.fields.birthday")}>
                <DatePicker placeholder={t("branches.fields.birthday")} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label={t("branches.fields.address")} name="address">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.city")} name="city">
                <Input />
              </Form.Item>
              <Form.Item label={t("branches.fields.zipcode")} name="zipcode">
                <Input />
              </Form.Item>
              {shouldShowFormAction && (
                <Form.Item noStyle>
                  <Button htmlType="submit" type="primary" style={{ justifySelf: "end" }}>
                    {t("buttons.save")}
                  </Button>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row gutter={[32, 32]} wrap>
            <Col flex={1} lg={12} xl={12}>
              <Form.Item
                label={t("branches.fields.shippingCostPerKg")}
                name="shipping_cost_per_kg"
                style={{ borderTop: `1px solid ${Colors.MintHover}`, paddingTop: 20, marginTop: 20 }}
              >
                <Input type="number" addonBefore="€" min={0} disabled={isReadOnly} />
              </Form.Item>
              <Form.Item label={t("branches.fields.currentBalance")} name="current_balance">
                <Input type="number" addonBefore="€" disabled />
              </Form.Item>
              <Form.Item label={t("branches.fields.credit")} name="credit">
                <Input type="number" addonBefore="€" disabled={isReadOnly} />
              </Form.Item>
            </Col>
            <Col flex={1} lg={12} xl={12} />
          </Row>
        </Form>
      </Row>
    </div>
  );
};
